import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SpacingBox from '@src/components/SpacingBox'
import Header from '../Header'
import { useCurrentUser } from '@utils/useCurrentUser'
import { useGlobalSnackbar } from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { useRouter } from '@src/routes'
import { setApprovalDelegateNotification } from '@utils/approvalDelegate'
import dynamic from 'next/dynamic'
import SkipLink from '../SkipLink'
import GlobalAlertBar from '../GlobalAlertBar'
import {
  initAlertBarMessage,
  alertBarMessageType,
  useAlertBarOnRouteChange,
  useGlobalAlertBar,
} from '../GlobalAlertBar/globalAlertBarContext'

const SitewideMessages = dynamic(
  () => import('@src/components/SitewideMessages'),
  { ssr: false }
)
const Footer = dynamic(() => import('../Footer'), { ssr: false })

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 1,
  },
  body: {
    flex: '1 0 auto',
  },
  footer: {
    flexShrink: 0,
  },
  feedbackLink: {
    cursor: 'pointer',
  },
  wrapper: {
    maxWidth: 1320,
    margin: '0 auto',
  },
})

export enum LayoutSpacingOptions {
  NONE = 'none',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

const LAYOUT_SPACING_VALUES = {
  [LayoutSpacingOptions.NONE]: 0,
  [LayoutSpacingOptions.SM]: {
    pt: { xs: 4, md: 6 },
    pb: { xs: 7, md: 9 },
  },
  [LayoutSpacingOptions.MD]: {
    pt: { xs: 6, md: 10 },
    pb: { xs: 14, md: 18 },
  },
}

interface DefaultLayoutProps {
  className?: string
  style?: React.CSSProperties
  spacing?: LayoutSpacingOptions
}

const DefaultLayout: React.SFC<DefaultLayoutProps> = ({
  spacing = LayoutSpacingOptions.NONE,
  children,
  className,
  style,
}) => {
  const classes = useStyles()
  const { userIsLoggedIn, currentUser } = useCurrentUser()
  const { setSnackbar, setGlobalSnackbarState } = useGlobalSnackbar()
  const { resetAlertBar } = useGlobalAlertBar()

  const [alertBar, setAlertBar] =
    useState<alertBarMessageType>(initAlertBarMessage)
  const router = useRouter()

  useEffect(
    () =>
      setApprovalDelegateNotification(
        router,
        setSnackbar,
        setGlobalSnackbarState,
        currentUser
        // eslint-disable-next-line react-hooks/exhaustive-deps
      ),
    [userIsLoggedIn]
  )
  useAlertBarOnRouteChange(alertBar, setAlertBar)
  return (
    <div className={classes.root}>
      <SkipLink />
      <SitewideMessages />
      <Header />
      {alertBar.open && (
        <GlobalAlertBar
          severity={alertBar.severity}
          handleClose={() => resetAlertBar(setAlertBar)}
        >
          {alertBar.message}
          <div id="registration-survey" />
        </GlobalAlertBar>
      )}

      <SpacingBox {...LAYOUT_SPACING_VALUES[spacing]}>
        {(spacingProps) => (
          <main
            {...spacingProps}
            className={clsx(classes.body, className, spacingProps.className)}
            style={style}
            id="main-content"
          >
            {children}
          </main>
        )}
      </SpacingBox>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  )
}

export default DefaultLayout
