import React from 'react'
import { FormattedMessage } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import { Theme, Link } from '@material-ui/core'
import messages from '@utils/messages'
import HandleMarkup from '@src/components/HandleMarkup'
import { Linq } from '@src/components/Linq'

const useStyles = makeStyles((theme: Theme) => ({
  supplementary: {
    fontSize: theme.typography.pxToRem(12),
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.dark,
    cursor: 'pointer',
  },
}))

interface Supplementary {
  messageKey?: Maybe<string>
  messageValue?: Maybe<string>
  messageVariable1?: Maybe<string>
}

interface Props {
  supplementary: Supplementary
}

const SupplementaryMessage: React.FC<Props> = ({ supplementary }) => {
  const classes = useStyles()
  const { messageKey, messageValue, messageVariable1 } = supplementary ?? {}
  const noMessage = messageKey && !messageValue && !messages[messageKey]
  // if messageValue contains a placeholder, replace it with the messageVariable1
  const formattedMessage = messageValue?.replace('%0', `${messageVariable1}`)

  if (noMessage) return null

  const renderMessage = (supplementary) => {
    switch (supplementary.messageKey) {
      case 'PT_TESTING_STUDY':
        return (
          <FormattedMessage
            {...messages[supplementary.messageKey]}
            values={{
              link: (
                <Link
                  href="https://supelco-pt.com"
                  target="_blank"
                  className={classes.link}
                >
                  <FormattedMessage {...messages.PT_TESTING_STUDY_LINK} />
                </Link>
              ),
            }}
          />
        )
      case 'REDI_DRI_RP':
        return (
          <FormattedMessage
            {...messages.REDI_DRI_RP}
            values={{
              brand: (
                <i>
                  <FormattedMessage {...messages.REAGENTPLUS} />
                </i>
              ),
            }}
          />
        )
      case 'ZOOMAB_TARGET':
        return (
          <FormattedMessage
            {...messages[supplementary.messageKey]}
            values={{
              messageVariable1: (
                <Linq href={supplementary?.messageVariable2 ?? ''}>
                  <HandleMarkup value={supplementary?.messageVariable1 ?? ''} />
                </Linq>
              ),
            }}
          >
            {(text) => <div className={classes.supplementary}>{text}</div>}
          </FormattedMessage>
        )
      default:
        return (
          <FormattedMessage
            {...messages[supplementary.messageKey]}
            values={{ messageVariable1: supplementary.messageVariable1 }}
          >
            {(text) => <div className={classes.supplementary}>{text}</div>}
          </FormattedMessage>
        )
    }
  }

  return (
    <div>
      {supplementary?.messageKey && messages[supplementary.messageKey] ? (
        renderMessage(supplementary)
      ) : (
        // if messageKey is not defined in the messages file render the messageValue
        <FormattedMessage
          id="DEFAULT_SUPPLEMENTARY_MESSAGE"
          defaultMessage="{messageValue}"
          values={{ messageValue: formattedMessage }}
        >
          {(text) => <div className={classes.supplementary}>{text}</div>}
        </FormattedMessage>
      )}
    </div>
  )
}

export default SupplementaryMessage
