import {
  GTMEventCategory,
  GTMEventTypes,
  EventValues,
} from '@sial/common-utils'
import { extractData } from '@utils/errorHandler'
import { ApolloError } from 'apollo-boost'
import {
  sendEvent,
  regexForStripBrackets,
  regexForStripHTML,
  regexForStripSymbols,
  regexForStripEncodingUnicode,
} from '@utils/analytics/coreAnalytics'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ValidMaterialPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import {
  AddToCartPagesEnum,
  SharedListEventAction,
} from '@utils/analytics/enums'
import { ProductCardType } from '../searchUtils'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { LegacyUAFields } from '@utils/analytics/types'
import { sendErrorEvent } from '@utils/analytics'

//Product pricing and availability has it's own smaller messages file located near the bulk of its components.
import messages from '@src/components/ProductPriceAvailability/messages'
import { ExcludedProductTypes } from '@src/aem-content/aem-types'

export const PricingAndAvailabilityPanelType: Record<
  Exclude<ProductCardType, ExcludedProductTypes>,
  string
> = {
  [ProductCardType.BuyItAgain]: 'buy it again',
  [ProductCardType.CustomersAlsoViewed]: 'customers also viewed',
  [ProductCardType.FrequentlyPurchased]: 'frequently purchased',
  [ProductCardType.Recently]: 'recently viewed products',
  [ProductCardType.Recommended]: 'recommended products',
  [ProductCardType.OrderTrackingRecommendedProducts]:
    'order tracking recommended products',
  [ProductCardType.Featured]: 'featured products',
  [ProductCardType.Related]: 'related products',
  [ProductCardType.CartRecommendedProducts]: 'cart_recommended_products',
  [ProductCardType.CompareSimilarItems]: 'compare similar items',
  [ProductCardType.BestSellers]: '', // TODO: Best Sellers Analytics TBD
  [ProductCardType.ProductHeroCard]: 'product hero card',
  [ProductCardType.PopularProducts]: 'popular products',
  [ProductCardType.NewArrivals]: 'new arrivals',
  [ProductCardType.BuyAgainHomepage]: 'buy again',
}

export enum PricingAndAvailabilityAction {
  HeaderSearchProductIdQuickView = 'product ID quick view - header search',
  HeaderSearchProductVariantQuickView = 'product variant quick view - header search',
}

export const getPricingAndAvailabilityEventAction = (
  listAction: SharedListEventAction,
  carouselType?: ProductCardType
): string => {
  return carouselType
    ? `${listAction} - ${PricingAndAvailabilityPanelType[carouselType]}`
    : listAction
}

interface PricingErrors {
  [productNumber: string]: DiscontinuedMaterialPricingDetailFragment
}

export const sendBeginRequestEvent = (
  {
    product,
    materialNumber,
    cta,
  }: {
    product: Partial<PdpFieldsFragment>
    materialNumber: string | undefined
    cta:
      | {
          __typename?: 'Form' | undefined
          label: string
          type: string
          url: string
        }
      | undefined
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const event =
    cta?.type === 'more information'
      ? 'more_info'
      : cta?.type.split(' ').join('_')
  const basePayload = {
    event: `begin_${event}_request`,
    action: undefined,
    detail: undefined,
    section: 'pricing card',
    component: 'right rail',
    element_text: cta?.label.toLowerCase(),
    element_type: 'link',
    link_url: cta?.url,
    core_event: 'yes',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    product_id: product.productNumber?.toLowerCase(),
    product_brand: product.brand?.key.toLowerCase(),
    product_variant: materialNumber?.toLowerCase(),
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendPricingAvailabilityInteractionEvent = (
  {
    action,
    detail,
    section,
    component,
    elementType,
    elementText,
    material,
    gaType,
  }: {
    action: string
    detail?: string
    section?: string
    component: string
    elementType?: string
    elementText?: string
    material?: Partial<ValidMaterialPricingDetailFragment>
    gaType?: AddToCartPagesEnum
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'pricing_availability_interaction',
    action,
    detail,
    gaType,
    section: section?.toLowerCase() || undefined,
    component,
    element_type: elementType,
    element_text: elementText,
    link_url: undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    product_id: material?.product?.toLowerCase(),
    product_variant: material?.materialNumber?.toLowerCase() || undefined,
    product_brand: material?.brand?.toLowerCase(),
    product_name: material?.materialDescription
      ?.replace(regexForStripHTML, '')
      .replace(regexForStripSymbols, '')
      .replace(regexForStripEncodingUnicode, '')
      .toLowerCase(),
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

type GA4PricingAndAvailabilityEventPayload = {
  clickType: string
  clickAction: string
  productId: string | undefined
  productVariant?: string | undefined
  productName: string | undefined | null
  productBrand: string | undefined
  component: string
  elementType?: string
  linkText?: string
  linkUrl?: string
}

export const sendPricingAndAvailabilityEvent = (
  actionTitle: string,
  gaEventLabel: string,
  ga4Payload?: GA4PricingAndAvailabilityEventPayload,
  ga4Only: boolean = false
): void => {
  const basePayload = {
    event: 'ga4Event',
    event_name: 'pricing_availability',
    click_type: `pricing_availability : ${ga4Payload?.clickType.toLowerCase()}`,
    click_action: ga4Payload?.clickAction.toLowerCase(),
    product_id: ga4Payload?.productId?.toLowerCase() || undefined,
    product_variant: ga4Payload?.productVariant?.toLowerCase() || undefined,
    product_name:
      ga4Payload?.productName
        ?.replace(regexForStripHTML, '')
        .replace(regexForStripSymbols, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase() || undefined,
    product_brand: ga4Payload?.productBrand?.toLowerCase() || undefined,
    component: ga4Payload?.component,
    element_type: ga4Payload?.elementType || undefined,
    link_text: ga4Payload?.linkText?.toLowerCase() || undefined,
    link_url: ga4Payload?.linkUrl || undefined,
  }

  const legacyUAFields = {
    eventCategory: GTMEventCategory.PricingAndAvailability.toLowerCase(),
    eventAction: actionTitle.toLowerCase(),
    eventLabel: gaEventLabel,
    eventInteractionType: 0,
  }

  /*
    GA4-251 - bug where these legacy fields are carrying over data into GA4 only events
    These fields should be null for events not tracked in Universal Analytics
    Merging clearLegacyFields object below in buildPayload when ga4Only is true
  */
  const clearLegacyFields = {
    event_category: null,
    event_action: null,
    event_label: null,
  }

  /*
      If a GA4 only P&A event construct event data without the legacy fields, and submit it.
      Otherwise submit all of the data through a normal send event.

      This satisfies a bug fix for https://stljirap.sial.com/browse/GA4-177

      This special P&A analytics function should be audited and refactored at scale later.
      Especially since legacy implementations will be drastically effected by the UA sunset on June 30th, 2024.
  */
  const buildPayload = ga4Only
    ? { payload: { ...basePayload, ...clearLegacyFields } }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }

  sendEvent(buildPayload)
}

export const sendPricingAndAvailabilityErrorEvent = (
  productNumber: string,
  error: ApolloError | undefined,
  messageCode?: string,
  productBrand?: string,
  productName?: string
): void => {
  const errorObject = error ? extractData(error).errors[0] : undefined
  const errorMessage =
    errorObject?.code && messages[errorObject.code]
      ? messages[errorObject.code]
      : messageCode
        ? messages[messageCode]
        : messages.PRICING_AND_AVAILABILITY_UNAVAILABLE
  const msg = `${productNumber} - ${errorMessage?.defaultMessage}`

  const basePayload = {
    event: 'ga4Event',
    event_name: 'exception',
    description:
      errorMessage?.defaultMessage
        .replace(regexForStripBrackets, ' ')
        .toLowerCase() || undefined,
    error_category: 'p&a errors',
    product_id: productNumber.toLowerCase(),
    product_name:
      productName
        ?.replace(regexForStripHTML, '')
        .replace(regexForStripSymbols, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase() || undefined,
    product_brand: productBrand?.toLowerCase() || undefined,
  }

  const legacyUAFields = {
    eventCategory: GTMEventCategory.Errors,
    eventAction: 'P&A errors',
    eventLabel: msg?.replace(regexForStripBrackets, ' ') || EventValues.Empty,
    eventInteractionType: 1,
  }

  const buildPayload =
    productBrand === undefined
      ? {
          eventType: GTMEventTypes.AnalyticsEvent,
          payload: legacyUAFields,
        }
      : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendQuickOrderPandAErrorEvent = (
  pricingErrors: PricingErrors
): void => {
  const productNumbers = Object.keys(pricingErrors) || []

  if (!productNumbers?.length) return

  const eventLabel = productNumbers.map((productNumber) => {
    const errorCode =
      pricingErrors[productNumber]?.errorMsg ===
      'PRICING_AND_AVAILABILITY_UNAVAILABLE'
        ? 'THIS_PRODUCT_NUMBER_DOES_NOT_EXIST'
        : pricingErrors[productNumber]?.errorMsg || EventValues.Empty

    const errorMessage = messages[errorCode]
      ? `${productNumber} - ${messages[errorCode].defaultMessage}`
      : 'invalid product number'
    return errorMessage.replace(regexForStripBrackets, ' ') || EventValues.Empty
  })

  sendErrorEvent(
    {
      description: eventLabel?.join(' | ') || undefined,
      errorCategory: 'other errors',
    },
    {
      eventCategory: GTMEventCategory.Errors,
      eventAction: 'P&A errors',
      eventLabel: eventLabel?.join(' | ') || EventValues.Empty,
      eventInteractionType: 1,
    }
  )
}
