import React from 'react'
import { OptimizelyProvider, createInstance } from '@optimizely/react-sdk'
import { useCookies } from '@src/utils/cookies'
import { useUserSession } from '@utils/useUserSession'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { isServer } from '@src/utils/isServer'

const SIALOptimizelyProvider: React.FC<{
  datafile: any
  children: React.ReactNode
}> = ({ datafile, children }) => {
  const [cookies] = useCookies(['GUID'])
  const { userIsLoggedIn } = useCurrentUser({})
  const { userSession } = useUserSession()
  const optimizelyInstance = createInstance({
    datafile,
  })
  const guidCookie = cookies['GUID'] || 'default_user'

  if (!datafile) return <>{children}</>

  return (
    <OptimizelyProvider
      isServerSide={isServer()}
      optimizely={optimizelyInstance}
      user={{
        id: guidCookie,
        attributes: {
          userIsLoggedIn: userIsLoggedIn,
          locale: `${userSession.language}_${userSession.country}`,
          country: userSession.country,
          language: userSession.language,
        },
      }}
    >
      {children}
    </OptimizelyProvider>
  )
}

export default SIALOptimizelyProvider
