import React, { useState, ReactNode } from 'react'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme, ButtonBase } from '@material-ui/core'
import CaretDownIcon from '@icons/CaretDownIcon'
import { useRouter } from '@src/routes'
import { sendCSRFiltersEvent } from '@utils/analytics'
import facetMessages from '@utils/MessageSets/defineFacetMessages'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    facetGroupContainer: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(6),
      },
    },
    facetTitleButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textAlign: 'left',
      paddingBottom: theme.spacing(2),
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(1),
      },
    },
    facetTitle: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightMedium,
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(14),
      },
    },
    facetExpandIcon: {
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.primary.main,
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      [theme.breakpoints.up('md')]: {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    facetIconExpanded: {
      transform: 'rotate(180deg)',
    },
    facetBody: {
      marginTop: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
  })
})

interface CSRFacetGroupProps {
  loading: boolean
  title: string
  children: ReactNode
  defaultExpanded?: boolean
  enTitleForAnalytics?: string
}

const CSRFacetGroup: React.FC<CSRFacetGroupProps> = ({
  loading,
  title,
  children,
  defaultExpanded = false,
  enTitleForAnalytics,
}) => {
  const classes = useStyles()
  const router = useRouter()
  const [expanded, setExpanded] = useState(defaultExpanded)

  const facetsAndSearchTerm = () => {
    const previousSelectedFacetsArray = router.query.facets
      ? router.query.facets.split(',').map((eachFacet) => {
          const [title, value] = eachFacet.split(':')
          const formatTitle =
            facetMessages[title.toUpperCase()].defaultMessage || title
          return `${formatTitle}: ${value}`
        })
      : []
    const searchTerm = router.query.term || router.query.searched
    const keyword = searchTerm ? `keyword: ${searchTerm}` : ''

    if (keyword && previousSelectedFacetsArray.length) {
      return `${keyword} | ${previousSelectedFacetsArray.join(' | ')}`
    } else if (keyword && !previousSelectedFacetsArray.length) {
      return `${keyword}`
    } else if (!keyword && previousSelectedFacetsArray.length) {
      return previousSelectedFacetsArray.join(' | ')
    } else {
      return undefined
    }
  }
  return (
    <div className={classes.facetGroupContainer}>
      <div>
        <ButtonBase
          onClick={() => {
            setExpanded(!expanded)
            sendCSRFiltersEvent({
              event: 'filter_interaction',
              action: !!expanded ? 'collpase filter' : 'expand filter',
              component: 'left rail',
              elementType: 'accordion',
              coreEvent: 'no',
              filterCategory: enTitleForAnalytics,
              filterExisting: facetsAndSearchTerm(),
            })
          }}
          disabled={loading}
          className={classes.facetTitleButton}
        >
          <div className={classes.facetTitle}>{title}</div>
          <CaretDownIcon
            className={clsx(classes.facetExpandIcon, {
              [classes.facetIconExpanded]: expanded,
            })}
          />
        </ButtonBase>
      </div>
      {expanded ? <div className={classes.facetBody}>{children}</div> : null}
    </div>
  )
}

export default CSRFacetGroup
