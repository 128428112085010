import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { useCurrentUser } from '@utils/useCurrentUser'
import { useUserSession } from '@utils/useUserSession'

const useStyles = makeStyles(() => ({
  root: {
    fontSize: 0, // removes excess space
  },
  link: {
    display: 'block',
  },
  // image aspect-ratio should be 16:1 but this is purposely not enforced to allow some flexibility
  image: {
    maxWidth: '100%',
  },
}))

enum LogoFile {
  MK = 'oc-partners-mk.gif',
  MS = 'oc-partners-ms.gif',
}

const CustomerLogo = () => {
  const classes = useStyles()
  const { currentUser } = useCurrentUser()
  const { userSession } = useUserSession()
  const imagePath =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.b2bChannelInformation &&
    currentUser.b2bChannelInformation.logoDirectory
      ?.toLowerCase()
      .replace(/[&/\\#, +@#&_!;[^/\]()$~%.'":*?<>{}]/g, '-')
  const country = userSession.country

  let file: string
  switch (country) {
    case 'US':
    case 'CA':
      file = LogoFile.MS
      break
    default:
      file = LogoFile.MK
  }

  // CORE-366: this is temporary until we get a link URL from the back end
  const imageUrl =
    imagePath === 'mitus'
      ? '/products/chemistry-and-biochemicals/greener-alternative-products'
      : undefined
  const imageTag = (
    <img
      src={`/deepweb/assets/sigmaaldrich/marketing/b2b/logos/${imagePath}/${file}`}
      alt=""
      className={classes.image}
    />
  )

  return (
    <div className={classes.root}>
      {imageUrl ? (
        <a className={classes.link} href={imageUrl}>
          {imageTag}
        </a>
      ) : (
        imageTag
      )}
    </div>
  )
}

export default CustomerLogo
