import React, { FC } from 'react'
import {
  MappedComponentProperties,
  MapTo,
} from '@adobe/aem-react-editable-components'
import { Chat, ChatChannel } from '@src/components/Chat'
import { useIntl } from 'react-intl'
import { useRouter } from '@src/routes'

interface GenericPlaceholderProps extends MappedComponentProperties {
  placeholder?: string
}

const GenericPlaceholderAdapter: FC<GenericPlaceholderProps> = ({
  placeholder,
}) => {
  const { formatMessage } = useIntl()
  const router = useRouter()

  const isShowChat =
    placeholder === 'live_chat' &&
    (router.query.country === 'AR' || router.query.country === 'CL')

  return (
    <>
      {isShowChat && (
        <Chat
          text={formatMessage({
            id: 'COMMERCE_CHAT_CUSTOMER_CARE',
            defaultMessage: 'Chat with Customer Care',
          })}
          channel={ChatChannel.CustomerService}
          isAemPageChat
        />
      )}
    </>
  )
}

export default MapTo('cms-commons/components/content/genericPlaceholder')(
  GenericPlaceholderAdapter
)
