import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Checkbox, Collapse, Grid, Link as MuiLink } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import HandleMarkup from '@src/components/HandleMarkup'
import { Link } from '@src/components/Link'
import messages from '@src/utils/messages'
import { productDetailRoute } from '@src/routes'
import {
  AlternateMaterialDetailFragment,
  ReplacementProductDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import DownloadIcon from '@icons/DownloadIcon'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ProductPriceAvailability from '@src/components/ProductPriceAvailability'
import { MaterialsInput } from '../QuickOrder'
import SDSLink from '@src/components/SDSLink'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '& > .MuiGrid-item': {
      padding: theme.spacing(1),
    },
  },
  productPriceAvailability: {
    '& table': {
      '& thead th': {
        padding: theme.spacing(1),
        fontSize: theme.typography.pxToRem(12),
        fontWeight: theme.typography.fontWeightBold,
      },
      '& tbody td': {
        padding: theme.spacing(1),
        verticalAlign: 'middle',
        '&:nth-child(1) strong': {
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
    },
  },
  alignRight: {
    textAlign: 'right',
    '& svg': {
      position: 'relative',
      top: 5,
    },
  },
  container: {
    backgroundColor: theme.palette.background.grey,
  },
}))

interface ReplacementProductProps {
  replacementProduct: ReplacementProductDetailFragment
  alternateMaterials: Maybe<AlternateMaterialDetailFragment>[]
  showCompare: boolean
  selectedForCompare: boolean
  setSelectedForCompare: (selected: boolean) => void
  addToList?: (replacementMaterials: MaterialsInput[]) => void
}

const ReplacementProduct: React.FC<ReplacementProductProps> = ({
  replacementProduct,
  alternateMaterials,
  showCompare,
  selectedForCompare,
  setSelectedForCompare,
  addToList,
}) => {
  const classes = useStyles()

  const [pricingExpanded, setPricingExpanded] = React.useState<boolean>(false)

  return (
    <Grid
      container
      className={clsx(classes.root, selectedForCompare && classes.container)}
    >
      {showCompare && (
        <Grid item xs={1}>
          <Checkbox
            checked={selectedForCompare}
            onChange={(e) => setSelectedForCompare(e.target.checked)}
          />
        </Grid>
      )}
      <Grid item xs={2}>
        <Link
          {...productDetailRoute.index(
            replacementProduct.brand.key,
            replacementProduct.productNumber
          )}
          passHref
        >
          <MuiLink>{replacementProduct.productNumber}</MuiLink>
        </Link>
      </Grid>
      <Grid item xs={showCompare ? 6 : 7}>
        <HandleMarkup value={replacementProduct.description} />
      </Grid>
      <Grid item xs={1}>
        <SDSLink
          sdsLanguages={replacementProduct.sdsLanguages}
          productNumber={replacementProduct.productNumber}
          brandKey={replacementProduct.brand.key}
          trigger={<DownloadIcon />}
        />
      </Grid>
      <Grid item xs={2} className={classes.alignRight}>
        {!pricingExpanded ? (
          <a
            href="#"
            onClick={() => {
              setPricingExpanded(true)
            }}
          >
            <FormattedMessage {...messages.EXPAND} />
            <ExpandMoreIcon />
          </a>
        ) : (
          <a
            href="#"
            onClick={() => {
              setPricingExpanded(false)
            }}
          >
            <FormattedMessage {...messages.HIDE} />
            <ExpandLessIcon />
          </a>
        )}
      </Grid>
      {pricingExpanded && (
        <Grid item xs={12} className={classes.productPriceAvailability}>
          <Collapse in={pricingExpanded}>
            <ProductPriceAvailability
              productNumber={replacementProduct.productNumber}
              materialIds={alternateMaterials.map((x) => x!.number)}
              brandKey={replacementProduct.brand.key}
              addToList={addToList}
            />
          </Collapse>
        </Grid>
      )}
    </Grid>
  )
}

export default ReplacementProduct
