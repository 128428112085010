import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import FreeFreightIcon from '@src/icons/FreeFreightIcon'
import ShipsTodayIcon from '@src/icons/ShipsTodayIcon'
import { ShippingTypes } from '@src/utils/searchUtils'
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    display: 'inline-flex',
    fontSize: theme.typography.pxToRem(28),
    marginLeft: theme.spacing(2),
  },
}))
interface ShippingFacetProps {
  value: string
  count: number
}

const ShippingFacet: React.FC<ShippingFacetProps> = ({ value, count }) => {
  const classes = useStyles()

  const icon =
    value === ShippingTypes.SameDay ? (
      <ShipsTodayIcon color="secondary" fontSize="inherit" />
    ) : (
      <FreeFreightIcon />
    )

  return (
    <>
      <FormattedMessage
        id={value.toUpperCase().replace(' ', '_')}
        defaultMessage={value}
      />
      {` (${count})`}
      <span className={classes.icon}>{icon}</span>
    </>
  )
}

export default ShippingFacet
