import React, { useState, useEffect, useRef } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { InputBaseComponentProps } from '@material-ui/core/InputBase'
import LiquidInput from '../LiquidInput'
import { LiquidInputProps } from '../LiquidInput/LiquidInput'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      flex: 1, // Ensure IE11 shrinks the input appropriately
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'center',
    },
    button: {
      color: theme.palette.primary.main,
      padding: theme.spacing(3),
      borderRadius: theme.shape.borderRadius,
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
      '&[disabled]': {
        color: theme.palette.grey[500],
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
      },
    },
    adornmentStart: { marginRight: 0 },
    adornmentEnd: { marginLeft: 0 },
    icon: {
      fontSize: 20,
      [theme.breakpoints.up('sm')]: {
        fontSize: 16,
      },
    },
  })
)

interface LiquidQuantityInputProps extends Omit<LiquidInputProps, 'onChange'> {
  value?: string | number
  onChange?: (v: any) => void
  delay?: boolean
  showZero?: boolean
  max?: number
  min?: number
  inputProps?: InputBaseComponentProps
  readOnly?: boolean
  associatedComponent?: string
}

const LiquidQuantityInput: React.FC<LiquidQuantityInputProps> = ({
  value,
  onChange,
  delay = false,
  showZero = false,
  disabled,
  max = 9999,
  min = 0,
  inputProps,
  readOnly,
  associatedComponent,
  ...otherProps
}) => {
  const classes = useStyles()
  const [stateValue, setStateValue] = useState(
    value ? value : showZero ? 0 : ''
  )
  const stateValueRef = useRef(stateValue)
  stateValueRef.current = stateValue

  let timer: any
  const timerRef = useRef(timer)

  // use delay to determine control
  const isControlled = value !== undefined && !delay
  const emptyValue = showZero ? 0 : ''
  const tempValue: any = isControlled
    ? value
      ? value
      : emptyValue
    : stateValue

  const update = isControlled
    ? (v: any) => onChange && onChange(v)
    : (v: any) => {
        if (delay) {
          // Clear previous timeout when continuing to update quantity
          clearTimeout(timerRef.current)

          timer = setTimeout(() => {
            onChange && onChange(stateValueRef.current)
          }, 1000)
          // Persist value of timer until the next timeout gets created
          timerRef.current = timer
        }
        setStateValue(v)
      }

  useEffect(() => clearTimeout(timerRef.current), [])

  const decrement = () => {
    let newValue: string | number
    if (!tempValue) {
      newValue = emptyValue
    } else if (tempValue <= min) {
      newValue = min
    } else {
      newValue = parseInt(tempValue) - 1
    }
    return update(newValue === 0 ? emptyValue : newValue)
  }

  const increment = () => {
    if (!tempValue) {
      return update(1)
    } else if (tempValue >= max) {
      return update(max)
    } else {
      return update(parseInt(tempValue) + 1)
    }
  }

  const handleChange = ({
    target: { value: rawValue },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(rawValue)

    if (Number.isNaN(value)) {
      return update(tempValue)
    } else if (value > max) {
      return update(tempValue)
    } else if (value < min) {
      return update(tempValue)
    } else if (value < 0) {
      return update(emptyValue)
    } else {
      return update(value)
    }
  }

  const handleKeyDown = ({ keyCode }: React.KeyboardEvent) => {
    if (keyCode === 38) {
      return increment()
    } else if (keyCode === 40) {
      return decrement()
    }
  }

  return (
    <LiquidInput
      value={tempValue}
      inputClass={classes.input}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      aria-label="Quantity-Root"
      readOnly={readOnly}
      startAdornment={
        <InputAdornment
          position="start"
          classes={{ positionStart: classes.adornmentStart }}
        >
          <IconButton
            classes={{ root: classes.button }}
            onClick={decrement}
            disabled={disabled || tempValue === min}
            aria-label="p&a-quantity-decrement"
            data-testid={
              associatedComponent
                ? `${associatedComponent}-decrement`
                : 'decrement'
            }
            id={
              associatedComponent
                ? `${associatedComponent}-decrement`
                : 'decrement'
            }
            tabIndex={-1}
          >
            <RemoveIcon className={classes.icon} />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment
          position="end"
          classes={{ positionEnd: classes.adornmentEnd }}
        >
          <IconButton
            classes={{ root: classes.button }}
            onClick={increment}
            disabled={disabled || tempValue === max || max === 0}
            aria-label="p&a-quantity-increment"
            data-testid={
              associatedComponent
                ? `${associatedComponent}-increment`
                : 'increment'
            }
            id={
              associatedComponent
                ? `${associatedComponent}-increment`
                : 'increment'
            }
            tabIndex={-1}
          >
            <AddIcon className={classes.icon} />
          </IconButton>
        </InputAdornment>
      }
      disabled={disabled}
      inputProps={{
        autoComplete: 'off',
        'aria-label': 'p&a-quantity-input',
        ...inputProps,
      }}
      {...otherProps}
    />
  )
}

export default LiquidQuantityInput
