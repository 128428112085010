import { MaterialsInput } from '@src/components/QuickOrder'
import { useGetMaterials } from '@src/routes/QuickOrder/useGetMaterials'
import { CatalogType, CartRequestAction } from '@src/types/graphql-types'
import { useCurrentUser } from '@utils/useCurrentUser'
import { QUICK_CART } from '@src/queries/QuickCartQuery'
import { ALL_CARTS } from '@src/queries/AllCartsQuery'
import { MultiplePricingAndAvailabilityQuery } from '@src/queries/MultiplePricingAndAvailabilityQuery.generated'
import { useAddToCart } from '@src/utils/useCart'

export const useAddMaterialsToCart = () => {
  const user = useCurrentUser()
  const { isBlueErpIntegrationEnabled } = user
  const { getMaterialsFromPricing, getMaterialsWithMarketPlaceData } =
    useGetMaterials()
  const [
    addToCart,
    { loading: addToCartLoading, data: addToCartData, error: addToCartError },
  ] = useAddToCart()

  const [
    addMarketplaceToCart,
    {
      loading: addMarketplaceToCartLoading,
      data: addMarketplaceToCartData,
      error: addMarketplaceToCartError,
    },
  ] = useAddToCart()

  const isLoading = addToCartLoading || addMarketplaceToCartLoading
  const cartError = addToCartError || addMarketplaceToCartError

  const addMaterialsToCart = async (
    materials: MaterialsInput[],
    validationMaterials: MultiplePricingAndAvailabilityQuery | undefined
  ) => {
    const materialsFromPricing = getMaterialsFromPricing(validationMaterials)

    const materialsWithMarketplaceData = getMaterialsWithMarketPlaceData(
      materials,
      materialsFromPricing
    )

    const materialsToAdd = materialsWithMarketplaceData
      .filter((material) => material.catalogType !== CatalogType.Marketplace)
      .map(({ ...materialProps }) => {
        delete materialProps.catalogType
        return materialProps
      })

    const marketplaceMaterialsToAdd = materialsWithMarketplaceData
      .filter((material) => material.catalogType === CatalogType.Marketplace)
      .map(({ ...materialProps }) => {
        delete materialProps.catalogType
        return materialProps
      })

    try {
      if (!!materialsToAdd.length) {
        await addToCart({
          variables: {
            input: {
              items: materialsToAdd,
            },
          },
          refetchQueries: [
            !isBlueErpIntegrationEnabled
              ? {
                  query: QUICK_CART,
                  variables: { id: CartRequestAction.Mini },
                }
              : {
                  query: ALL_CARTS,
                },
          ],
          awaitRefetchQueries: true,
        })
      }
      if (!!marketplaceMaterialsToAdd.length) {
        await addMarketplaceToCart({
          variables: {
            input: {
              items: marketplaceMaterialsToAdd,
              marketplace: true,
            },
          },
          refetchQueries: [
            !isBlueErpIntegrationEnabled
              ? {
                  query: QUICK_CART,
                  variables: { id: CartRequestAction.Marketplace },
                }
              : {
                  query: ALL_CARTS,
                },
          ],
          awaitRefetchQueries: true,
        })
      }
      return null
    } catch (error) {
      return error
    }
  }

  return {
    addMaterialsToCart,
    isLoading,
    addToCartData,
    addMarketplaceToCartData,
    cartError,
  }
}
