/* eslint-disable sonarjs/no-identical-functions */
import {
  Analytics,
  GTMEventTypes,
  GTMVirtualPageViewPayload,
  GTMEventCategory,
  PromoObject,
  GA4EcommercePayload,
  GA4EcommercePayloadItem,
  EventValues,
} from '@sial/common-utils'

import {
  isAnalyticsEnabled,
  showGTMTag,
  isTealium,
  sendEvent,
  legacySendEvent,
  getCurrencyCode,
  sendLaunchEvent,
  regexForStripEncodingUnicode,
  regexForStripHTML,
  regexForStripSpecialChars,
  regexForStripSymbols,
  regexForStripHTMLWithContent,
  DEFAULT_CURRENCY_CODE,
  CART_DATA_STORAGE_KEY,
  clubbingUpProducts,
  priceFormatter,
} from '@utils/analytics/coreAnalytics'

import { buildGaPromoObjects } from '@utils/analytics/analyticsUtils'

import {
  GTMEcommerceValues,
  GTMEventProps,
  SearchAnalyticsEventParams,
  RecommendedProduct,
  GTMVirtualPageViewPayloadWithPersistentLogin,
  BarcodeScanEvent,
  BuyItAgainEvent,
  BuyNowEvent,
  ClipboardCopyEvent,
  HeaderSearchEvent,
  GA4SearchEventPayload,
  SharedListsEvent,
  GA4SharedListsEventPayload,
  GA4PDPClickPayload,
  DownloadFileEvent,
  Menu,
  GA4CheckAvailabilityEventPayload,
  GA4ProductDetailsEventPayload,
  QuickOrderCategoryOverride,
  GA4QuickOrderTrackingPayload,
  OrderStatusInfoEvent,
  RequestQuoteEvent,
  ProductAnalytics,
  GA4DownloadFilePayload,
  EventActions,
  GA4ViewSearchResultsPayload,
  GA4CommonDetailClickEvent,
  ViewSampleCOAEvent,
  LegacyUAFields,
  GA4CommonDetailProductIdEvent,
  GA4SharedListEventPayload,
  GA4CSRFiltersEvent,
  GA4ErrorEvent,
  GA4SearchInteractionPayload,
  GA4QuickOrderInteractionPayload,
  ProductDetails,
} from '@utils/analytics/types'

import {
  HeaderMenu,
  EventKeys,
  ExtraEventValues,
  GAEventTypes,
  SearchPageEnum,
  AdvancedSearchTypeEnum,
  BuyInKItEventActionEnum,
  BuyItNowEventActionEnum,
  EmailLinkVerificationEnum,
  EmailLinkVerificationActionEnum,
  ProductDetailsEventAction,
  ProductDetailsEventCategory,
  SafetyAndRegulatoryEventCategory,
  AddToCartPagesEnum,
  DocumentLibraryTab,
} from '@utils/analytics/enums'

import {
  CartItemFragment,
  CartTotalsFragment,
} from '@src/queries/CartQuery.generated'

import {
  DidYouMeanTerm,
  Facet,
  FacetInput,
  PromotionalBundle,
} from '@src/types/graphql-types'

import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { DynamicProductFragment } from '@src/fragments/DynamicProduct.generated'
import { OrderDetailsFragment } from '@src/queries/OrderDetailsQuery.generated'
import { PdpFieldsFragment } from '@src/queries/PDPQuery.generated'
import { useCookies } from '@src/utils/cookies'
import messages, { Message } from '@utils/messages'
import {
  ProductCardType,
  ProductSearchType,
  SearchFocusType,
  GeneSearchType,
} from '@utils/searchUtils'
import decodeTitle from '@utils/decodeTitle'
import { GuestShoppingType, useCurrentUser } from '@utils/useCurrentUser'
import { pickBy, mapValues, isArray } from 'lodash'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { useEffect, useLayoutEffect } from 'react'
import { sessionStorage } from 'react-storage'
import Cookies from 'universal-cookie'
import {
  getPageTealiumSetting,
  sendUtagView,
  sendInternalPromotionsEventForAnalytic,
  sendRecommendedProductsClickEventForAnalytic,
  sendOrderConfirmationB2BTrackForAnalytic,
  productDetailPageViewTrack,
  sendSuccessfulLoginEventForTealium,
} from './tealiumAnalytics'
import loginStorage from '@src/utils/loginStorage'
import { PricingAndAvailabilityPanelType } from './analytics/pricingAndAvailability'
import { parseRegionalUrl } from './regional'
import { Url } from 'next/dist/shared/lib/router/router'
import { DocLibCertificateTypes } from '@src/routes/DocumentLibrary/NoDocumentAvailablePopover'

const {
  publicRuntimeConfig: { uTagEnv, gtmTagId },
} = getConfig()

export const LAST_REFERRER_URL = 'LAST_REFERRER_URL'
export const CURRENT_PAGE_URL = 'CURRENT_PAGE_URL'
export const gtmDataLayerName = 'dataLayer'

export const PAGEVIEW_TIMEOUT = 2000
export const ONLOAD_EVENT_TIMEOUT = PAGEVIEW_TIMEOUT + 1000
export const DOCUMENT_REFERRER = 'DOCUMENT_REFERRER'
const PAGE_REFRESH = 'PAGE_REFRESH'

export { uTagEnv, gtmTagId }

const PAGE_VIEW_SENT = 'PAGE_VIEW_SENT'

/**
 *  GTMEvent component
 *
 *  @param props - GTMEventProps
 *
 *  This is the basic Google Tag Manager component for sending custom events.
 *  They payload is sent as a prop and is integrated with the event type.
 *  For the AnalyticsEvent type of event, you would use the following:
 *
 *    import { GTMEvent, GTMEventTypes } from '@src/utils/analytics'
 *
 *    <GTMEvent
 *      eventType={GTMEventTypes.AnalyticsEvent} // enum value is 'analyticsEvent'
 *      payload={{
 *        eventCategory:'TEST CATEGORY',
 *        eventAction:'TEST ACTION',
 *        eventLabel:'TEST LABEL',
 *      }}
 *    />
 *
 *  This translates (roughly) to the following:
 *
 *    <script type="text/javascript">
 *      dataLayer = dataLayer || [];
 *      dataLayer.push({
 *        'event':'analyticsEvent', //hardcoded
 *        'eventCategory':'{{event category}}',
 *        'eventAction':'{{event action}}',
 *        'eventLabel':'{{event label}}',
 *        'eventInteractionType':0
 *      });
 *    </script>
 *
 **/
export const GTMEvent: React.FC<GTMEventProps> = (props) => {
  useEffect(() => {
    Analytics.sendGTMEvent(props)
  })
  return <></>
}

export const sendNavigationInteractionEvent = (
  {
    action,
    detail,
    section,
    component,
    elementType,
    elementText,
    linkUrl,
  }: {
    action: string
    detail?: string | null
    section?: string | undefined
    component: string
    elementType: string
    elementText?: string | Maybe<string>
    linkUrl?: Url | Maybe<string>
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'navigation_interaction',
    action,
    detail:
      detail?.toLowerCase().replace(regexForStripHTMLWithContent, '') ||
      undefined,
    section,
    component,
    element_type: elementType,
    element_text:
      elementText?.toLowerCase().replace(regexForStripHTMLWithContent, '') ||
      undefined,
    link_url: linkUrl || undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

const getMetaKeywords = (): string => {
  if (typeof document === 'undefined') return EventValues.Empty

  const element = document?.querySelector('meta[name="keywords"]')
  const content = element && element?.getAttribute('content')
  return content
    ? content?.length
      ? content?.split(',').join(' |')
      : EventValues.NotAvailable
    : EventValues.NotAvailable
}

export const sendLoginEvent = ({ me, isOffersPromotionsEmail }): void => {
  if (!showGTMTag) {
    return
  }

  const loginType =
    me?.profileType === 'B2B'
      ? 'b2b login'
      : loginStorage.get()?.rememberMe
        ? 'persistent login'
        : 'non-persistent login'
  const memberId = showGTMTag ? null : me?.memberId || ''
  const signedUpForNewsLetter = isOffersPromotionsEmail
    ? EventValues.Yes
    : EventValues.No

  //GA4 login
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: GTMEventCategory.Login.toLowerCase(),
      login_participant_id: me?.id,
      login_reauthentication: '',
      login_type: loginType,
      method: 'password',
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.SuccessfulLogin,
      eventAction: me?.id,
      eventLabel: loginType,
      memberId,
      newsLetterSignup: signedUpForNewsLetter,
    },
  })
}

export const isHomePage = (routeAsPath: string): boolean =>
  (routeAsPath || '')?.split('/')?.length === 3

export const hasURLSearchParams = (): number => window.location.search?.length

export const sendScrollTrackingEvent = (scrolPercentage?: string): void => {
  const routePath = window?.location?.pathname || EventValues.Empty
  const stripCountryLanguagePath = isHomePage(routePath)
    ? '/'
    : `/${routePath.split('/').slice(3).join('/')}`

  //GA4
  const scrollPercentageRemoved = scrolPercentage?.slice(
    0,
    scrolPercentage.length - 1
  )
  sendEvent({
    eventType: GAEventTypes.ScrollEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'scroll',
      percent_scrolled: scrollPercentageRemoved,
      //Legacy UA properties
      eventCategory: 'Scroll depth',
      eventAction: stripCountryLanguagePath || EventValues.Empty,
      eventLabel: scrolPercentage,
      eventInteractionType: 1,
    },
  })
}

const PAGE_LOAD = 'PAGE_LOAD'

export const onLoadScrollEvent = (): void => {
  const visibleScreenPercentage =
    (window.innerHeight * 100) / document.body.scrollHeight

  if (visibleScreenPercentage >= 100) {
    sendScrollTrackingEvent('100%')
  } else if (visibleScreenPercentage >= 75) {
    sendScrollTrackingEvent('75%')
  } else if (visibleScreenPercentage >= 50) {
    sendScrollTrackingEvent('50%')
  } else if (visibleScreenPercentage >= 25) {
    sendScrollTrackingEvent('25%')
  }
}

export const setPageLoadComplete = (isLoad: boolean): void => {
  onLoadScrollEvent()
  return sessionStorage.setItem(PAGE_LOAD, isLoad)
}

const isValidPath = (searchTerm?: string): boolean => {
  const currentPagePath = (window.location.pathname || '')
    .split('/')
    .slice(3)
    .join('/')
  const paths = [
    `search/${searchTerm}`,
    'search/structure-search',
    'product/compare',
  ]
  return paths.includes(currentPagePath) ? false : true
}

const pageLoad = (searchTerm?: string): void => {
  const isLoad = isValidPath(searchTerm)
  return sessionStorage.setItem(PAGE_LOAD, isLoad)
}

export const isPageLoad = (): string | boolean =>
  sessionStorage.getItem(PAGE_LOAD) || false

export const resetRecommendedProductTracking = (): (() => void) => {
  //clear the sessionStorage on page load.
  sessionStorage.removeItem(EventKeys.VISITED_RECOMMENDED_PRODUCTS)
  sessionStorage.removeItem(EventKeys.VISIBLE_RECOMMENDED_PRODUCTS)
  return () => {
    //clear the sessionStorage on component unmount.
    sessionStorage.removeItem(EventKeys.RECOMMENDED_PRODUCTS)
    sessionStorage.removeItem(EventKeys.CUSTOMER_VIEWED_PRODUCTS)
    sessionStorage.removeItem(EventKeys.RECENTLY_VIEWED_PRODUCTS)
    sessionStorage.removeItem(EventKeys.VISITED_RECOMMENDED_PRODUCTS)
    sessionStorage.removeItem(EventKeys.VISIBLE_RECOMMENDED_PRODUCTS)
  }
}

const GTMAnalyticsScrollTracking = () => {
  const router = useRouter()

  useEffect(() => {
    if (typeof window === 'undefined' || typeof document === 'undefined') return

    resetRecommendedProductTracking()
    const twentyFivePercentListener = (): void => {
      const visibleScreenPercentage =
        (window.innerHeight * 100) / document.body.scrollHeight
      const currentRatio =
        (window.pageYOffset /
          (document.body.scrollHeight - window.innerHeight)) *
        100
      if (isPageLoad() && currentRatio >= 25 && visibleScreenPercentage <= 25) {
        document.removeEventListener('scroll', twentyFivePercentListener)
        sendScrollTrackingEvent('25%')
      }
    }
    document.addEventListener('scroll', twentyFivePercentListener)

    const fiftyPercentListener = (): void => {
      const visibleScreenPercentage =
        (window.innerHeight * 100) / document.body.scrollHeight
      const currentRatio =
        (window.pageYOffset /
          (document.body.scrollHeight - window.innerHeight)) *
        100
      if (isPageLoad() && currentRatio >= 50 && visibleScreenPercentage <= 50) {
        document.removeEventListener('scroll', fiftyPercentListener)
        sendScrollTrackingEvent('50%')
      }
    }
    document.addEventListener('scroll', fiftyPercentListener)

    const seventyFivePercentListener = (): void => {
      const visibleScreenPercentage =
        (window.innerHeight * 100) / document.body.scrollHeight
      const currentRatio =
        (window.pageYOffset /
          (document.body.scrollHeight - window.innerHeight)) *
        100
      if (isPageLoad() && currentRatio >= 75 && visibleScreenPercentage <= 75) {
        document.removeEventListener('scroll', seventyFivePercentListener)
        sendScrollTrackingEvent('75%')
      }
    }
    document.addEventListener('scroll', seventyFivePercentListener)

    const hunderedPercentListener = (): void => {
      const visibleScreenPercentage =
        (window.innerHeight * 100) / document.body.scrollHeight
      const currentRatio =
        (window.pageYOffset /
          (document.body.scrollHeight - window.innerHeight)) *
        100
      if (
        isPageLoad() &&
        currentRatio >= 100 &&
        visibleScreenPercentage <= 100
      ) {
        document.removeEventListener('scroll', hunderedPercentListener)
        sendScrollTrackingEvent('100%')
      }
    }
    document.addEventListener('scroll', hunderedPercentListener)

    // Initial sending the event how much screen visible without scrolling
    if (isValidPath(router?.query?.searchTerm || '')) {
      onLoadScrollEvent()
    }
    return () => {
      document.removeEventListener('scroll', twentyFivePercentListener)
      document.removeEventListener('scroll', fiftyPercentListener)
      document.removeEventListener('scroll', seventyFivePercentListener)
      document.removeEventListener('scroll', hunderedPercentListener)
    }
  }, [router.asPath])
  return <></>
}

export const setLastReferrerUrl = (): void =>
  sessionStorage.setItem(LAST_REFERRER_URL, window.location.href)

/**
 *  GTMAnalyticsPageView component
 *
 *  This records a virtual page view for the visited page when GTM is enabled.
 */
const GTMAnalyticsPageView = () => {
  const router = useRouter()
  const {
    userIsLoggedIn,
    memberId,
    userId,
    roleNames,
    emproveUserType,
    currentUserLoading,
    isB2BUser,
    currentUser,
    isDealer,
    isDTAQFTBUser,
    isPublicOrderingCustomer,
    guestShoppingType,
    isDTAQZuCustomer,
    primaryBusinessActivity,
    organizationType,
    organizationWebsite,
    organizationPosition,
  } = useCurrentUser()

  const [cookies] = useCookies(['GUID', 'language', 'country', '_ga'])

  const primaryBusinessActivityString =
    primaryBusinessActivity &&
    Object.keys(
      pickBy(primaryBusinessActivity, (value) => value === true)
    ).join(' | ')

  const soldTos =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.soldToPartners.map((partner) => partner.soldTo).join(' | ')

  const shipTos =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.soldToPartners.map((partner) => partner.shipTo).join(' | ')

  const billTos =
    currentUser?.__typename === 'LoggedInUser' &&
    currentUser.soldToPartners.map((partner) => partner.billTo).join(' | ')

  const participantId = (userIsLoggedIn && userId) || ''
  sessionStorage.setItem('PARTICIPANT_ID', participantId)

  const redirectReferrer: string =
    sessionStorage.getItem(DOCUMENT_REFERRER) || ''

  const directCustomerProcurement = isB2BUser
  const directCustomerEShop =
    !isB2BUser && isPublicOrderingCustomer && !isDealer
  const dealerCartEndCustomer =
    !isB2BUser &&
    guestShoppingType === GuestShoppingType.DTAQ &&
    isDTAQZuCustomer

  useLayoutEffect(() => {
    sessionStorage.setItem(PAGE_REFRESH, true)
  }, [])

  useLayoutEffect(() => {
    /**
    function to return payload for virtualPageview event
    **/
    const isPageRefreshed = sessionStorage.getItem(PAGE_REFRESH)
    const pathnameAndSearch = window.location.pathname + window.location.search
    const getVirtualPageviewPayload =
      (): GTMVirtualPageViewPayloadWithPersistentLogin => {
        const payload: GTMVirtualPageViewPayload = {
          VirtualPagePath:
            isHomePage(pathnameAndSearch) && !hasURLSearchParams()
              ? `${pathnameAndSearch}/`
              : pathnameAndSearch,
          VirtualPageTitle: document.title,
          VirtualPageParameters: window.location.search,
          VirtualPageURL:
            isHomePage(router.asPath) && !hasURLSearchParams()
              ? `${window.location.href}/`
              : window.location.href,
          Language: cookies.language,
          Country: cookies.country,
          OriginalReferrer: isPageRefreshed
            ? redirectReferrer ||
              sessionStorage.getItem(LAST_REFERRER_URL) ||
              ''
            : sessionStorage.getItem(LAST_REFERRER_URL) ||
              redirectReferrer ||
              '',
          GUID: cookies?.GUID?.split('|')[0],
          LoggedIn: userIsLoggedIn ? EventValues.Yes : EventValues.No,
          ContentPageType: getMetaKeywords(),
          ProductPageCategory: '', // TBD - only for PDP's
          persistentLogin: userId
            ? isB2BUser
              ? 'b2b'
              : loginStorage.get()?.rememberMe
                ? 'yes'
                : 'no'
            : 'not logged in',
          ...(userIsLoggedIn
            ? {
                ParticipantId: userId,
                MemberId: memberId || EventValues.NotAvailable,
                SoldToNumber: soldTos || EventValues.NotAvailable,
                BillToNumber: billTos || EventValues.NotAvailable,
                ShipToNumber: shipTos || EventValues.NotAvailable,
                Roles:
                  roleNames?.replace(/-/g, '|') || EventValues.NotAvailable,
                EmproveUserType: emproveUserType,
                OrganizationType: organizationType || EventValues.NotAvailable,
                OrganizationPosition:
                  organizationPosition || EventValues.NotAvailable,
                OrganizationWebsite:
                  organizationWebsite || EventValues.NotAvailable,
                PrimaryBusinessActivity:
                  primaryBusinessActivityString || EventValues.NotAvailable,
                UserType:
                  (isB2BUser ? 'B2B' : 'B2C') || EventValues.NotAvailable,
                ...(isPageRefreshed
                  ? {
                      JapanUserType: directCustomerProcurement
                        ? 'Direct Customer Procurement'
                        : isDTAQFTBUser
                          ? 'FTB'
                          : directCustomerEShop
                            ? 'Direct Customer eShop'
                            : dealerCartEndCustomer
                              ? 'Dealer Cart End Customer'
                              : isDealer
                                ? 'Dealer'
                                : EventValues.NotAvailable,
                    }
                  : {}),
              }
            : {}),
        } as GTMVirtualPageViewPayloadWithPersistentLogin
        return payload
      }

    sessionStorage.setItem(PAGE_VIEW_SENT, false)
    pageLoad(router?.query?.searchTerm || '')
    const timeout = setTimeout(() => {
      !isPageRefreshed &&
        Analytics.virtualGTMPageView({
          ...getVirtualPageviewPayload(),
        } as GTMVirtualPageViewPayloadWithPersistentLogin)

      !isPageRefreshed &&
        sendLaunchEvent({
          ...getVirtualPageviewPayload(),
          event: 'Page View',
        })

      const isOffersPromotionsEmail =
        currentUser?.__typename === 'LoggedInUser'
          ? currentUser.participantCommunicationSetting.isOffersPromotionsEmail
          : ''
      if (
        isB2BUser &&
        !sessionStorage.getItem<boolean>(EventKeys.IS_B2B_LOGGED_IN_CALLED)
      ) {
        sendLoginEvent({
          me: currentUser,
          isOffersPromotionsEmail,
        })
        sessionStorage.setItem<boolean>(EventKeys.IS_B2B_LOGGED_IN_CALLED, true)
      }
      if (
        isPageRefreshed &&
        isAnalyticsEnabled &&
        showGTMTag &&
        typeof window !== 'undefined' &&
        !(window.ga && window.ga.create)
      ) {
        Analytics.TagManager.dataLayer({
          dataLayer: {
            ...getVirtualPageviewPayload(),
          },
        })
        sendLaunchEvent({
          ...getVirtualPageviewPayload(),
          event: 'Page View',
        })
        Analytics.initializeGoogleTagManager(gtmTagId)
      }
      sessionStorage.setItem(PAGE_VIEW_SENT, true)
      sessionStorage.setItem(CURRENT_PAGE_URL, window.location.href)
      Analytics.dequeueEvents()
      setLastReferrerUrl()
      sessionStorage.setItem(PAGE_REFRESH, false)
      // HACK: 2 seconds is the amount of time we can reasonably assume a page is fully loaded to simulate a DOM Ready event.
    }, PAGEVIEW_TIMEOUT)

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [currentUserLoading, router.asPath])

  useEffect(() => {
    // Client-side routing doesn't change the referrer and we can't set the referer manually
    // so we need some kind of storage solution to track the value.
    const handleSessionStorage = (url: string): void => {
      //no need to set referrer if the path is same.
      if (router.asPath !== url.split('?')[0]) {
        const currentPath =
          sessionStorage.getItem<string>(CURRENT_PAGE_URL) || EventValues.Empty
        sessionStorage.setItem<string>(LAST_REFERRER_URL, currentPath)
      }
    }

    router.events.on('routeChangeStart', handleSessionStorage)

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleSessionStorage)
    }
  }, [router.events])

  return <GTMAnalyticsScrollTracking />
}

/**
 * virtualTealiumPageView function
 *
 * @param url - currently this takes window.location.pathname, though could be window.location.href (full url)
 */
export function virtualTealiumPageView(
  path: string,
  retry: boolean,
  userIsLoggedIn: boolean,
  userErpType?: string
): void {
  if (isAnalyticsEnabled) {
    if (typeof window !== 'undefined' && window.utag && window.utag.view) {
      const { tealiumEvent, siteSection, skipGlobalAnalytic } =
        getPageTealiumSetting(path)
      if (!skipGlobalAnalytic) {
        sendUtagView({ tealiumEvent, siteSection, userIsLoggedIn, userErpType })
      }
    } else if (retry) {
      throw new Error("utag hasn't been loaded yet.")
    }
  }
}

/**
 * TealiumAnalyticsPageView component
 *
 * This fires a virtual page view to Tealium (China-only) whenever the given page is visited.
 */
export const TealiumAnalyticsPageView = () => {
  const router = useRouter()
  const {
    currentUserLoading,
    userIsLoggedIn,
    roleNames,
    currentUser,
    emproveUserType,
    isB2BUser,
    userId,
    primaryBusinessActivity,
    organizationType,
    organizationWebsite,
    organizationPosition,
    userErpType,
  } = useCurrentUser()

  const [cookies] = useCookies(['_ga'])
  const participantId = (userIsLoggedIn && userId) || ''
  sessionStorage.setItem('PARTICIPANT_ID', participantId)
  const isOffersPromotionsEmail =
    currentUser?.__typename === 'LoggedInUser'
      ? currentUser?.participantCommunicationSetting?.isOffersPromotionsEmail
      : ''

  useEffect(() => {
    // Client-side routing doesn't change the referrer and we can't set the referer manually
    // so we need some kind of storage solution to track the value.
    const handleWindowLocation = (): void => {
      sessionStorage.setItem(LAST_REFERRER_URL, window.location.href)
    }

    router.events.on('routeChangeStart', handleWindowLocation)

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleWindowLocation)
    }
  }, [router.events])

  useLayoutEffect(() => {
    let timeout
    try {
      virtualTealiumPageView(router.asPath, true, userIsLoggedIn, userErpType)
    } catch (e) {
      timeout = setTimeout(() => {
        virtualTealiumPageView(
          router.asPath,
          false,
          userIsLoggedIn,
          userErpType
        )
      }, 2000)
      // Hack: Introduce a retry mechnism to cover sometimes the utag has not loaded for the first page
      // it will have a retry in 2s later
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [router.asPath])

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (
        isB2BUser &&
        !sessionStorage.getItem<boolean>(EventKeys.IS_B2B_LOGGED_IN_CALLED)
      ) {
        sendSuccessfulLoginEventForTealium({
          me: currentUser,
          gaId: `b2b-login > ${cookies._ga}`,
          isOffersPromotionsEmail,
          roleNames,
          currentUser,
          emproveUserType,
          organizationType,
          organizationPosition,
          organizationWebsite,
          primaryBusinessActivity,
          isB2BUser,
        })
        sessionStorage.setItem<boolean>(EventKeys.IS_B2B_LOGGED_IN_CALLED, true)
      }
      // track wechat login
      if (sessionStorage.getItem<boolean>(EventKeys.IS_WECHAT_LOGGED_IN)) {
        sendSuccessfulLoginEventForTealium({
          me: currentUser,
          gaId: `wechat-login > ${cookies._ga}`,
          isOffersPromotionsEmail,
          roleNames,
          currentUser,
          emproveUserType,
          organizationType,
          organizationPosition,
          organizationWebsite,
          primaryBusinessActivity,
          isB2BUser,
        })
        sessionStorage.setItem<boolean>(EventKeys.IS_WECHAT_LOGGED_IN, false)
      }
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [currentUserLoading, router.asPath])
  return <></>
}

/**
 *  AnalyticsPageView component
 *
 *  This fires a virtual page view whenever the given page is visited.
 */
export const AnalyticsPageView = () => {
  // If not client side or analytics are entirely disabled don't load any page view scripts.
  if (
    !isAnalyticsEnabled ||
    typeof window === 'undefined' ||
    typeof document === 'undefined'
  ) {
    return null
  }

  return (
    <>
      {showGTMTag && <GTMAnalyticsPageView />}
      {isTealium && <TealiumAnalyticsPageView />}
    </>
  )
}

export const sendLoginPageEvent = ({ gaId, actionTitle }): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.LoginPage,
      eventAction: actionTitle,
      eventLabel: gaId,
      eventInteractionType: 0,
    },
  })
}

/**
 * sendSearchAnalyticsEvent
 *
 * This sends an event to Google for search-related events.
 */
export const sendSearchAnalyticsEvent = (
  {
    searchType,
    href,
    item,
    searchTerm,
    facetValue,
  }: SearchAnalyticsEventParams,
  focusType?: string
): void => {
  let eventLabel: string
  let autoSuggestType: string
  let autoSuggestTerm: string
  switch (searchType) {
    case ProductSearchType.ProductName:
      eventLabel = `${searchTerm} - ${EventValues.ProductName} : ${item}`
      autoSuggestType = EventValues.ProductName.toLowerCase()
      autoSuggestTerm = item || EventValues.Empty
      break
    case ProductSearchType.ProductNumber:
      eventLabel = `${searchTerm} - ${EventValues.ProductNumber} : ${item}`
      autoSuggestType = EventValues.ProductNumber.toLowerCase()
      autoSuggestTerm = item || EventValues.Empty
      break
    case ProductSearchType.Product:
      // For Categories type auto suggestion adding prefix 'in' between search term and auto suggest item
      eventLabel = `${searchTerm} - ${
        EventValues.Categories
      } : ${searchTerm} in ${facetValue || EventValues.Empty}`
      autoSuggestType = EventValues.Categories.toLowerCase()
      autoSuggestTerm = `${item} in ${
        facetValue?.toLowerCase() || EventValues.Empty
      }`
      break
    case ProductSearchType.CasNumber:
      eventLabel = `${searchTerm} - ${EventValues.CASNumber} : ${item}`
      autoSuggestType = EventValues.CASNumber.toLowerCase()
      autoSuggestTerm = item || EventValues.Empty
      break
    default:
      eventLabel = EventValues.Empty
      autoSuggestType = EventValues.Empty
      autoSuggestTerm = EventValues.Empty
  }

  const isStructureSearch = href?.match(/structure-search/)

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'search',
      search_term: searchTerm || undefined,
      search_auto_suggest: autoSuggestType,
      search_auto_suggest_term: autoSuggestTerm,
      search_type: focusType,
      search_component: 'global search',
      search_error_message: undefined,
      //Legacy UA event data
      eventCategory: GTMEventCategory.Header,
      eventAction: href
        ? isStructureSearch
          ? EventValues.StructureSearch
          : EventValues.AdvancedSearch
        : EventValues.AutoSuggest,
      eventLabel: searchType ? eventLabel : EventValues.Empty,
      eventInteractionType: 0,
    },
  })
}

export const sendBarcodeScanEvent = ({
  action,
  label,
}: BarcodeScanEvent): void => {
  const eventInteractionType = label === 'bad scan' ? 1 : 0
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'barcode scan',
      eventAction: action,
      eventLabel: label,
      eventInteractionType,
    },
  })
}

export const sendBuyItAgainEvent = ({
  action,
  label,
}: BuyItAgainEvent): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'buy it again',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendBuyItAgainSearchEvent = (
  searchTerm: string,
  userId: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'buy it again',
      eventAction: `search products by sku - ${searchTerm}`,
      eventLabel: userId,
      eventInteractionType: 0,
    },
  })
}

export const sendBuyNowEvent = ({ action, label }: BuyNowEvent): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'buy it now',
      eventAction: action,
      eventLabel: label.join(' | '),
      eventInteractionType: 0,
    },
  })
}

export const sendClipboardCopyEvent = ({
  action,
  label,
}: ClipboardCopyEvent): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'clipboard event',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendHeaderSearchEvent = (
  { action, label }: HeaderSearchEvent,
  ga4payload?: GA4SearchEventPayload
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'search',
      search_term: ga4payload?.searchTerm || 'no_user_input',
      search_auto_suggest: ga4payload?.searchAutoSuggest,
      search_auto_suggest_term:
        ga4payload?.searchAutoSuggestTerm?.toLowerCase(),
      search_type: ga4payload?.searchType,
      search_component: ga4payload?.searchComponent,
      search_error_message: ga4payload?.searchErrorMessage,
      //legacy UA
      eventCategory: 'header search',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendSharedListEvent = (
  ga4Payload: GA4SharedListEventPayload,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4Payload?.event || 'list_interaction',
    action: ga4Payload?.action,
    detail: ga4Payload?.detail,
    section: ga4Payload?.section,
    component: ga4Payload?.component,
    element_type: ga4Payload?.elementType,
    element_text: ga4Payload?.elementText?.toLowerCase(),
    link_url: ga4Payload?.linkUrl,
    core_event: ga4Payload?.coreEvent,
    user_detail: ga4Payload?.userDetail,
    event_group: ga4Payload?.eventGroup,
    event_subgroup: ga4Payload?.eventSubgroup,
    product_id: ga4Payload?.productId?.toLowerCase() || undefined,
    product_brand: ga4Payload?.productBrand?.toLowerCase() || undefined,
    product_variant: ga4Payload?.productVariant?.toLowerCase() || undefined,
    product_name:
      ga4Payload?.productName
        ?.replace(regexForStripHTML, '')
        .replace(regexForStripSymbols, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase() || undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

// leaving this until the rest of the list events
// are updated to the new GA4 data structure
export const sendSharedListsEvent = (
  event: SharedListsEvent,
  ga4payload?: GA4SharedListsEventPayload
): void => {
  const { action, label } = mapValues(event, (value = '') =>
    (isArray(value) ? value.join(' > ') : value).toLowerCase()
  )

  // Combined UA/GA4 Event Send
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'list_clicks',
      click_type: ga4payload?.clickType,
      click_action: ga4payload?.clickAction,
      click_details: isArray(ga4payload?.clickDetails)
        ? ga4payload?.clickDetails.join(' > ')
        : ga4payload?.clickDetails || undefined,
      component: ga4payload?.component,
      element_type: ga4payload?.elementType,
      link_text: ga4payload?.linkText,
      link_url: ga4payload?.linkUrl || undefined,
      //Legacy UA/GA3 Params
      eventCategory: 'lists page',
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendSharedListQuoteRequestEvent = (
  payload: GA4SharedListEventPayload
): void => {
  sendEvent({
    payload: {
      event: payload?.event,
      action: payload?.action || undefined,
      detail: payload?.detail,
      section: payload?.section,
      component: payload?.component,
      element_type: payload?.elementType,
      element_text: payload?.elementText,
      link_url: payload?.linkUrl || undefined,
      core_event: payload?.coreEvent || 'no',
      user_detail: payload?.userDetail || undefined,
      event_group: payload?.eventGroup || undefined,
      event_subgroup: payload?.eventSubgroup || undefined,
    },
  })
}

export const sendSharedListsDownloadEvent = (
  eventLabel: string | undefined = ''
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'downloads',
      eventAction: 'list pdf',
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendLoginErrorEvent = ({ message }): void =>
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'exception',
      description: message.toLowerCase() || undefined,
      error_category: 'login errors',
      product_id: undefined,
      product_name: undefined,
      product_brand: undefined,
      //legacy UA values
      eventCategory: GTMEventCategory.Errors,
      eventAction: 'Login errors',
      eventLabel: message,
      eventInteractionType: 0,
    },
  })

export const sendOrderInteractionEvent = (
  {
    action,
    detail,
    section,
    orderType,
    component,
    elementType,
    elementText,
    linkUrl,
  }: {
    action: string
    detail?: string
    section?: string
    orderType?: string
    component?: string
    elementType?: string
    elementText?: string
    linkUrl?: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'order_interaction',
    action: action.toLowerCase(),
    detail: detail?.toLowerCase() || undefined,
    section: section?.toLowerCase() || 'order confirmation',
    order_type: orderType,
    component: component || 'body',
    element_type: elementType || 'link',
    element_text: elementText?.toLowerCase() || undefined,
    link_url: linkUrl,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendMilliplexUserInteractionEvent = (
  actionTitle: string,
  species?: any,
  researchAreas?: any
): void => {
  const researchAreasValue =
    researchAreas.length > 0 ? researchAreas?.join(' - ') : 'NA'
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.MilliplexAnalyteKitFinder,
      eventAction: actionTitle,
      eventLabel:
        'species: ' + species + ' | research areas: ' + researchAreasValue,
      eventInteractionType: 0,
    },
  })
}

export const sendSeeAllEvent = (
  substanceName?: string,
  productLength?: number
): void => {
  const productName =
    (substanceName || '')
      .replace(regexForStripEncodingUnicode, '')
      .replace(regexForStripHTML, '') || ''
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.SearchResultPage,
      eventAction: 'See all',
      eventLabel: `${productName} (${productLength})`,
      eventInteractionType: 0,
    },
  })
}

export const sendCommonDetailClickEvent = (
  ga4Payload: GA4CommonDetailClickEvent,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4Payload.event,
    action: ga4Payload.action,
    detail: ga4Payload.detail?.toLowerCase(),
    section: ga4Payload.section,
    component: ga4Payload.component,
    element_text: ga4Payload.elementText?.toLowerCase(),
    element_type: ga4Payload.elementType,
    link_url: ga4Payload.linkUrl,
    core_event: ga4Payload.coreEvent,
    user_detail: ga4Payload.userDetail,
    event_group: ga4Payload.eventGroup,
    event_subgroup: ga4Payload.eventSubGroup,
    product_id: ga4Payload.productId?.toLowerCase(),
    product_name: ga4Payload.productName?.toLowerCase(),
    product_brand: ga4Payload.productBrand?.toLowerCase(),
    product_variant: ga4Payload.productVariant,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendChatOpenEvent = (action: string, label: string): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ChatOpen,
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendHeaderAccountMenuEvents = (
  menu: string,
  linkUrl?: string,
  ga4Only = false
): void => {
  const basePayload = {
    event: 'navigation_interaction',
    action: 'header click',
    detail: `hello user > ${menu
      .replace(regexForStripSymbols, '')
      .toLowerCase()}`,
    section: undefined,
    component: 'header',
    element_type: 'link',
    element_text: menu.replace(regexForStripSymbols, '').toLowerCase(),
    link_url: linkUrl || undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const legacyUAFields = {
    eventCategory: GTMEventCategory.Header,
    eventAction: EventValues.Profile,
    eventLabel: menu || EventValues.NotAvailable,
    eventInteractionType: 0,
  }

  const buildPayload = ga4Only
    ? { payload: basePayload }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }

  sendEvent(buildPayload)
}

type HeaderMenuEvent = {
  menu: Message
  linkUrl?: string
  navMenu?: string
  navTreeOne?: string
}

export const sendHeaderMenuEvents = ({
  menu,
  linkUrl,
  navMenu = menu?.defaultMessage,
  navTreeOne,
}: HeaderMenuEvent): void => {
  const eventAction =
    menu.id === HeaderMenu.Login || menu.id === HeaderMenu.SignUp
      ? EventValues.LoginSignUp
      : menu && menu.defaultMessage
        ? menu.defaultMessage
        : EventValues.NotAvailable

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'navigation_interaction',
      action: 'header click',
      detail: navTreeOne
        ? `${navMenu.toLowerCase()} > ${navTreeOne.toLowerCase()}`
        : navMenu?.toLowerCase(),
      section: undefined,
      component: 'header',
      element_type: 'link',
      element_text:
        navTreeOne?.toLowerCase() || navMenu?.toLowerCase() || undefined,
      link_url: linkUrl || undefined,
      core_event: 'no',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      //Legacy UA
      eventCategory: GTMEventCategory.Header,
      eventAction,
      eventLabel:
        menu && menu.defaultMessage
          ? menu.defaultMessage
          : EventValues.NotAvailable,
      eventInteractionType: 0,
    },
  })
}

const matchKeyToDisplayName = (
  selectedFacetKey: string,
  facets?: Facet[]
): string => {
  if (!facets) return ''
  const facetMatch = facets.find((facet) => facet.key === selectedFacetKey)
  return facetMatch?.key
    ? messages[facetMatch.key.toUpperCase()]
      ? messages[facetMatch.key.toUpperCase()]?.defaultMessage
      : facetMatch.key.toUpperCase()
    : ''
}

export const sendSearchFacetEvent = (
  searchTerm: string,
  facetkey: string,
  selectedOption: string,
  isSelect: boolean,
  selectedFacets?: FacetInput[],
  facets?: Facet[]
): void => {
  let eventLabel = ''
  const searchKeyTerm = `${EventValues.Keyword}:${searchTerm}`

  if (isSelect) {
    const selectedFacetValues = selectedFacets?.map((selectedFacet) => {
      const facetDisplayName = matchKeyToDisplayName(selectedFacet.key, facets)
      return `${facetDisplayName} : ${selectedFacet?.options?.join('|')}`
    })

    // Google Analytics requires eventLabel as, if facet added
    // 'EventLabel' : '{{the added facet along with the facet category.}} > {{search terms and facets, each separated by "|"}}'

    eventLabel = `${matchKeyToDisplayName(
      facetkey,
      facets
    )}:${selectedOption} > ${[
      searchKeyTerm,
      ...(selectedFacetValues || ''),
    ].join(' | ')}`
  } else {
    const selectedFacetValues = selectedFacets
      ?.map((selectedFacet) => {
        const facetDisplayName = matchKeyToDisplayName(
          selectedFacet.key,
          facets
        )
        const facetMatches =
          selectedFacet.key === facetkey && selectedFacet?.options?.length === 1
        return facetMatches
          ? ''
          : `${facetDisplayName} : ${selectedFacet?.options
              ?.filter((option) => option !== selectedOption)
              .join('|')} `
      })
      .filter((values) => values)

    // Google Analytics requires eventLabel as, if facet removed
    // 'EventLabel': '{{the removed facet along with the facet category.}}>{{remaining search terms and facets, each separated by "|"}}'

    eventLabel = `${matchKeyToDisplayName(
      facetkey,
      facets
    )}:${selectedOption} > ${[
      searchKeyTerm,
      ...(selectedFacetValues || ''),
    ].join(' | ')}`
  }

  legacySendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.SearchResultPage,
      eventAction: isSelect ? EventValues.FacetAdded : EventValues.FacetRemoved,
      eventLabel: eventLabel
        .replace(regexForStripHTML, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase()
        .trim(),
      eventInteractionType: 0,
    },
  })
}

export const sendRemoveSearchTermEvent = (
  searchTerm: string,
  selectedFacets: FacetInput[],
  facets?: Facet[]
): void => {
  const searchKeyTerm = `${EventValues.Keyword}:${searchTerm}`
  const selectedFacetValues = selectedFacets?.map((selectedFacet) => {
    const facetDisplayName = matchKeyToDisplayName(selectedFacet.key, facets)
    return `${facetDisplayName} : ${selectedFacet?.options?.join('|')}`
  })

  // Google Analytics requires eventLabel as, if Search Keyword removed
  // 'EventLabel': '{{the removed search keyword}}|{{remaining facets, each separated by ">"}}'

  const eventLabel = selectedFacetValues.length
    ? `${searchKeyTerm} > ${(selectedFacetValues || []).join(' | ')}`
    : `${searchKeyTerm}`
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.SearchResultPage,
      eventAction: EventValues.FacetRemoved,
      eventLabel:
        eventLabel
          .replace(regexForStripHTML, '')
          .replace(regexForStripEncodingUnicode, '') || '',
      eventInteractionType: 0,
    },
  })
}

export const sendAffiliationPageEvent = (
  action: string,
  label: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.AffiliationPage,
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendProductNotFoundErrorEvent = (): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.Errors,
      eventAction: EventValues.ProductNotFound,
      eventLabel: gaCookie,
      eventInteractionType: 1,
    },
  })
}

export const sendSRPActionEvent = (eventAction, eventLabel): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory:
        eventAction === EventValues.RecentSearches ||
        eventAction === EventValues.SearchScope
          ? GTMEventCategory.Header
          : GTMEventCategory.SearchResultPage,
      eventAction,
      eventLabel: eventLabel || gaCookie,
      eventInteractionType: 0,
    },
  })
}

export const sendPDPProductInfoClickedEvent = (
  eventAction: string,
  eventLabel: string,
  eventInteractionType?: number,
  ga4Payload?: GA4PDPClickPayload
): void => {
  // GA4
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'product_detail_page_click',
      click_type: ga4Payload?.clickType,
      click_action: ga4Payload?.clickAction,
      click_details: ga4Payload?.clickDetails,
      product_id: ga4Payload?.productId,
      product_name: ga4Payload?.productName,
      product_brand: ga4Payload?.productBrand,
      component: ga4Payload?.component,
      element_type: ga4Payload?.elementType,
      link_text: ga4Payload?.linkText,
      link_url: ga4Payload?.linkUrl || undefined,
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.ProductDetailPage,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventInteractionType: eventInteractionType ? eventInteractionType : 0,
    },
  })
}

export const sendSearchedTermEvent = (
  eventLabel,
  ga4payload?: GA4SearchEventPayload
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'search',
      search_term: ga4payload?.searchTerm || 'no_user_input',
      search_auto_suggest: ga4payload?.searchAutoSuggest,
      search_auto_suggest_term: ga4payload?.searchAutoSuggestTerm,
      search_type: ga4payload?.searchType,
      search_component: ga4payload?.searchComponent,
      search_error_message: ga4payload?.searchErrorMessage,
      //Legacy UA Event Data
      eventCategory: GTMEventCategory.Header,
      eventAction: EventValues.Search,
      eventLabel: eventLabel || '',
      eventInteractionType: 0,
    },
  })
}

export const getAdvancedSearchTypeValue = (
  type: string | undefined
): string | undefined => {
  // eslint-disable-next-line sonarjs/max-switch-cases
  switch (type) {
    case ProductSearchType.Product:
      return AdvancedSearchTypeEnum.Product
    case ProductSearchType.ProductExact:
      return AdvancedSearchTypeEnum.ProductExact
    case ProductSearchType.ProductNumber:
      return AdvancedSearchTypeEnum.ProductNumber
    case ProductSearchType.ProductName:
      return AdvancedSearchTypeEnum.ProductName
    case ProductSearchType.CasNumber:
      return AdvancedSearchTypeEnum.CasNumber
    case ProductSearchType.MolecularFormula:
      return AdvancedSearchTypeEnum.MolecularFormula
    case ProductSearchType.SubstanceId:
      return AdvancedSearchTypeEnum.SubstanceId
    case ProductSearchType.FemaNumber:
      return AdvancedSearchTypeEnum.FemaNumber
    case ProductSearchType.ColorIndex:
      return AdvancedSearchTypeEnum.ColorIndex
    case ProductSearchType.EgecNumber:
      return AdvancedSearchTypeEnum.EgecNumber
    case ProductSearchType.EcNumber:
      return AdvancedSearchTypeEnum.EcNumber
    case ProductSearchType.GeneProduct:
      return AdvancedSearchTypeEnum.GeneProduct
    case ProductSearchType.MdlNumber:
      return AdvancedSearchTypeEnum.MdlNumber
    case ProductSearchType.PubMed:
      return AdvancedSearchTypeEnum.PubMed
    case ProductSearchType.Protocol:
      return AdvancedSearchTypeEnum.Protocol
    case GeneSearchType.Gene:
      return AdvancedSearchTypeEnum.Gene
    case GeneSearchType.GeneSymbol:
      return AdvancedSearchTypeEnum.GeneSymbol
    case GeneSearchType.GeneId:
      return AdvancedSearchTypeEnum.GeneId
    case GeneSearchType.TrcNumber:
      return AdvancedSearchTypeEnum.TrcNumber
    case GeneSearchType.RefSeq:
      return AdvancedSearchTypeEnum.RefSeq
    case GeneSearchType.GeneDesc:
      return AdvancedSearchTypeEnum.GeneDesc
    default:
      return type
  }
}

export const sendAdvancedSearchedTermEvent = (
  ga4payload?: GA4SearchEventPayload
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'search',
      search_term: ga4payload?.searchTerm || 'no_user_input',
      search_auto_suggest: ga4payload?.searchAutoSuggest,
      search_auto_suggest_term: ga4payload?.searchAutoSuggestTerm,
      search_type: getAdvancedSearchTypeValue(ga4payload?.searchType),
      search_component: ga4payload?.searchComponent,
      search_error_message: ga4payload?.searchErrorMessage,
      //Legacy UA Event Data
      eventCategory: '',
      eventAction: '',
      eventLabel: '',
      eventInteractionType: 0,
    },
  })
}

export const sendSearchInteractionEvent = (
  payload: GA4SearchInteractionPayload
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'search_interaction',
      action: payload.action,
      detail: payload.detail ?? undefined,
      section: payload.section ?? undefined,
      component: payload.component,
      element_type: payload.elementType,
      element_text: payload.elementText,
      link_url: payload.linkUrl,
      core_event: 'no',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
    },
  })
}

export const sendStructureSearchEvent = (
  ga4payload?: GA4SearchEventPayload
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'search',
      search_term: ga4payload?.searchTerm || 'no_user_input',
      search_auto_suggest: ga4payload?.searchAutoSuggest,
      search_auto_suggest_term: ga4payload?.searchAutoSuggestTerm,
      search_type: getAdvancedSearchTypeValue(ga4payload?.searchType),
      search_component: ga4payload?.searchComponent,
      search_error_message: ga4payload?.searchErrorMessage,
      //Legacy UA Event Data
      eventCategory: '',
      eventAction: '',
      eventLabel: '',
      eventInteractionType: 0,
    },
  })
}

export const sendEmptyRecommendationEvent = (eventAction): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.EmptyRecommendation,
      eventAction,
      eventLabel: gaCookie,
      eventInteractionType: 1,
    },
  })
}

export const sendSearchWithinEvent = (eventLabel): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.ProductCategoryPage,
      eventAction: EventValues.SearchWithin,
      eventLabel: eventLabel || '',
      eventInteractionType: 0,
    },
  })
}

export const sendFacetChangeEvent = (
  eventLabel,
  isFacetOrKeywordRemoval
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.ProductCategoryPage,
      eventAction: isFacetOrKeywordRemoval
        ? EventValues.CategoryPageFacetRemoved
        : EventValues.CategoryPageFacetAdded,
      eventLabel: eventLabel || '',
    },
  })
}

export const sendCSRFiltersEvent = (
  ga4Payload: GA4CSRFiltersEvent,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4Payload.event,
    action: ga4Payload.action?.toLowerCase(),
    detail: ga4Payload.detail?.toLowerCase(),
    section: ga4Payload.section,
    component: ga4Payload.component,
    element_text: ga4Payload.elementText?.toLowerCase(),
    element_type: ga4Payload.elementType,
    link_url: ga4Payload.linkUrl,
    core_event: ga4Payload.coreEvent,
    user_detail: ga4Payload.userDetail,
    event_group: ga4Payload.eventGroup,
    event_subgroup: ga4Payload.eventSubGroup,
    filter_name: ga4Payload.filterName?.toLowerCase(),
    filter_name_count: ga4Payload.filterNameCount,
    filter_category: ga4Payload.filterCategory?.toLowerCase(),
    filter_existing: ga4Payload.filterExisting?.trim().toLowerCase(),
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendConfirmItemModalEvent = (participantId): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.CheckoutPage,
      eventAction: EventValues.ConfirmItemQuantityPopUp,
      eventLabel: participantId,
      eventInteractionType: 0,
    },
  })
}

export const sendSearchDidYouMeanEvent = (
  searchTerm: string,
  terms: Maybe<DidYouMeanTerm>[],
  focus?: string,
  type?: string,
  page?: string,
  qType?: string
): (() => void) => {
  const timeout = setTimeout(() => {
    try {
      // type isn't always sent in for legacy
      // but need it for ga4 - using 2nd type param
      const ga4Type = type ? type : qType
      // DidYouMeanTerms compoent slicing into 3
      const didYouMeanTerms = terms ? terms.slice(0, 3) : []
      const eventAction =
        didYouMeanTerms.length > 0
          ? EventValues.DidYouMean
          : EventValues.NoResults
      let eventLabel = ''

      if (isTealium) {
        // Tealium requires eventLabel as
        // "event_label" : "{{search term}} > {{search type}} > {{did you mean term}}",
        eventLabel = didYouMeanTerms.length
          ? [
              searchTerm,
              type,
              didYouMeanTerms.map((e) => e?.term).join('|'),
            ].join(' > ')
          : [searchTerm, type].join(' > ')
      }

      if (showGTMTag) {
        switch (focus) {
          case SearchFocusType.Products:
            eventLabel = didYouMeanTerms.length
              ? [
                  searchTerm,
                  EventValues.Products,
                  didYouMeanTerms
                    .map((didYouMeanTerm) => didYouMeanTerm?.term)
                    .join('|'),
                ].join(' - ')
              : [searchTerm, EventValues.Products].join(' > ')
            setPageLoadComplete(true)
            break
          case SearchFocusType.BuildingBlocks:
            eventLabel = didYouMeanTerms.length
              ? [
                  searchTerm,
                  EventValues.BuildingBlocksExplorer,
                  didYouMeanTerms
                    .map((didYouMeanTerm) => didYouMeanTerm?.term)
                    .join('|'),
                ].join(' - ')
              : [searchTerm, EventValues.BuildingBlocksExplorer].join(' > ')
            setPageLoadComplete(true)
            break
          case SearchFocusType.Genes:
            eventLabel = [searchTerm, EventValues.Genes].join(' > ')
            break
          case SearchFocusType.Papers:
            eventLabel = [searchTerm, EventValues.Papers].join(' > ')
            break
          case SearchFocusType.TechnicalDocuments:
            eventLabel = [searchTerm, EventValues.TechnicalDocuments].join(
              ' > '
            )
            break
          case SearchFocusType.SiteContent:
            eventLabel = [searchTerm, EventValues.SiteContent].join(' > ')
            break
          default:
            eventLabel = EventValues.Empty
        }
      }

      // UA Legacy Event For Search Results Not Found
      legacySendEvent({
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: {
          //Legacy UA Event Data
          eventCategory: GTMEventCategory.SearchResultPage,
          eventAction,
          eventLabel,
          eventInteractionType: 1,
        },
      })

      // GA4 No Results Found Event
      legacySendEvent({
        payload: {
          event: 'view_search_results',
          q_focus: focus,
          q_page: page || '1',
          q_type: ga4Type || undefined,
          search_result_status:
            eventAction === EventValues.NoResults
              ? 'no results page'
              : eventAction.toLowerCase(),
          search_term: searchTerm,
          did_you_mean_terms: didYouMeanTerms.length
            ? didYouMeanTerms
                .map((didYouMeanTerm) => didYouMeanTerm?.term)
                .join('|')
            : undefined,
          //Legacy UA Event Data
          eventCategory: GTMEventCategory.SearchResultPage,
          eventAction,
          eventLabel,
          eventInteractionType: 1,
        },
      })
    } catch (error) {
      console.error('No result / Did you mean - Event', error)
    }
  }, ONLOAD_EVENT_TIMEOUT)
  return () => {
    clearTimeout(timeout)
  }
}

export const sendBasicRegistrationEvent = (
  userId?: string,
  isOffersAndPromotionsEmail?: boolean
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'sign_up',
      newsletter_subscription: isOffersAndPromotionsEmail
        ? EventValues.Yes
        : EventValues.No,
      method: 'website',
      eventCategory: GTMEventCategory.Registration,
      eventAction: userId,
      eventLabel: isOffersAndPromotionsEmail
        ? EventValues.EnrolledForNewsletter
        : EventValues.NotEnrolledForNewsletter,
      newsLetterSignup: isOffersAndPromotionsEmail
        ? EventValues.Yes
        : EventValues.No,
      eventInteractionType: 0,
    },
  })
}

export const sendHeaderTopMenuEvents = (
  actionTitle?: string,
  gaCookie?: string
): void => {
  const logoClickGA4Payload = {
    event: 'navigation_interaction',
    action: 'header click',
    detail: 'home',
    section: undefined,
    component: 'header',
    element_type: 'link',
    element_text: 'milliporesigma',
    link_url: '/',
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const legacyUAFields = {
    eventCategory: GTMEventCategory.Header,
    eventAction: actionTitle,
    eventLabel: gaCookie,
    eventInteractionType: 0,
  }

  const buildPayload =
    actionTitle === EventValues.Logo
      ? {
          eventType: GTMEventTypes.AnalyticsEvent,
          payload: { ...logoClickGA4Payload, ...legacyUAFields },
        }
      : {
          eventType: GTMEventTypes.AnalyticsEvent,
          payload: { ...legacyUAFields },
        }

  sendEvent(buildPayload)
}

export const sendMarketplaceClickEvent = (
  eventCategory: string,
  eventAction: string,
  orderNumber?: string
): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory,
      eventAction,
      eventLabel: orderNumber || gaCookie,
      eventInteractionType: 0,
    },
  })
}

export const sendMobileMegaNavEvents = (
  activeNavItem: string,
  itemLabel: string,
  linkUrl: string
): void => {
  let eventAction: string, eventLabel: string
  const trackingUrl = parseRegionalUrl(linkUrl).pathAfterBasename

  if (activeNavItem === EventValues.Account.toUpperCase()) {
    eventAction = EventValues.Profile
    eventLabel = messages[itemLabel]
      ? messages[itemLabel]?.defaultMessage
      : itemLabel
  } else {
    eventAction = EventValues.Links
    eventLabel = trackingUrl || ''
  }

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.Header,
      eventAction,
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendSessionErrorEvent = (OnloadEvent?: boolean): (() => void) => {
  const timeout = setTimeout(
    () => {
      const eventLabel = messages.USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED
        ? messages.USER_NOT_AUTHORIZED_OR_TOKEN_EXPIRED?.defaultMessage
        : EventValues.SessionErrorMessage
      sendEvent({
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: {
          event: 'ga4Event',
          event_name: 'exception',
          description: eventLabel.toLowerCase(),
          error_category: EventValues.SessionErrors.toLowerCase(),
          product_id: undefined,
          product_name: undefined,
          product_brand: undefined,
          //Legacy UA
          eventCategory: GTMEventCategory.Errors,
          eventAction: EventValues.SessionErrors,
          eventLabel,
          eventInteractionType: 1,
        },
      })
    },
    OnloadEvent ? ONLOAD_EVENT_TIMEOUT : 0
  )
  return () => {
    clearTimeout(timeout)
  }
}

export const setGlobalObject = (
  items: CartItemFragment[] | undefined,
  totals?: CartTotalsFragment | null,
  order?: OrderDetailsFragment,
  page?: string
): void => {
  try {
    type GlobalObjectType = {
      tax: number | null
      shipping: number | null
      revenue: string
      currencyCode: string
      orderNumber: string | null | undefined
      productName: string[]
      productPrice: number[]
      productId: string[]
      productBrand: string[]
      productVariant: string[]
      productQuantity: string[]
    }

    let currencyCode = items?.length
      ? items[0]?.currency || DEFAULT_CURRENCY_CODE
      : DEFAULT_CURRENCY_CODE
    const orderItems = order?.items.map((item) => {
      currencyCode = item?.currency || DEFAULT_CURRENCY_CODE
      return {
        ...item,
        pricing: {
          price:
            priceFormatter(item?.pricePerUnit) ||
            priceFormatter(item?.listPrice),
        },
      }
    })

    // If order is given use its sales tax, otherwise go to totals.
    // Both are fed to price formatted which it totals tax isn't defined you'll get the correct null response.
    const taxAmount = order ? order?.salesTax : totals?.tax
    const shippingAmount = order ? order?.transHandlingCharge : totals?.shipping

    const globalObject: GlobalObjectType = {
      tax: priceFormatter(taxAmount),
      shipping: priceFormatter(shippingAmount),
      revenue: order
        ? order.total.toString()
        : totals
          ? totals?.total.toString()
          : '0.00',
      orderNumber: order ? order.orderNumber : EventValues.Empty,
      currencyCode: getCurrencyCode(currencyCode?.toString()),
      productName: [],
      productPrice: [],
      productId: [],
      productBrand: [],
      productVariant: [],
      productQuantity: [],
    }

    const products = clubbingUpProducts(order ? orderItems : items, page) || []
    products.forEach((item) => {
      globalObject.productName.push(item.name)
      globalObject.productPrice.push(priceFormatter(item?.price) || 0.0)
      globalObject.productId.push(item?.id)
      globalObject.productBrand.push(item?.brand)
      globalObject.productVariant.push(item?.variant)
      globalObject.productQuantity.push(item?.quantity.toString())
    })
    sessionStorage.setItem<GlobalObjectType>('PRODUCTS_OBJECT', globalObject)
  } catch (error) {
    console.error('ERROR - Saving Global Object: ', error)
  }
}

export const sendProductDetailEvent = (
  products?: ValidMaterialPricingDetailFragment[],
  productName?: string,
  productNumber?: string,
  brandKey?: string,
  isMarketplace?: boolean,
  erpType?: string[],
  userType?: Object
): (() => void) => {
  const currency = products?.length
    ? products[0]?.currency
    : DEFAULT_CURRENCY_CODE
  const materialDescription = products?.length
    ? products[0]?.materialDescription?.length
      ? products[0]?.materialDescription
      : productName
    : productName

  const productMaterialName =
    (materialDescription || EventValues.Empty)
      .toLowerCase()
      .replace(regexForStripEncodingUnicode, '')
      .replace(regexForStripHTML, '')
      .replace(regexForStripSymbols, '') || ''

  const timeout = setTimeout(() => {
    if (showGTMTag) {
      // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
      //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
      legacySendEvent({
        eventType: GTMEventTypes.ProductDetailView,
        payload: {
          eventCategory: GTMEventCategory.ProductDetailView,
          eventAction: brandKey || EventValues.Empty,
          eventLabel: productNumber || EventValues.Empty,
          eventInteractionType: 1,
          ecommerce: {
            currencyCode: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
            detail: {
              actionField: {
                list: GTMEventCategory.ProductDetailView.toLowerCase(),
              },
              products: [
                {
                  id: (productNumber || EventValues.Empty).toLowerCase(),
                  name: productMaterialName,
                  brand: (brandKey || EventValues.Empty).toLowerCase(),
                  dimension91: isMarketplace ? 'marketplace' : 'standard',
                },
              ],
            },
          },
        },
      })

      // GA4 - View item details
      legacySendEvent({
        payload: {
          event: 'view_item',
          ecommerce: {
            currency: getCurrencyCode(currency || DEFAULT_CURRENCY_CODE),
            items: [
              {
                item_id: (productNumber || EventValues.Empty).toLowerCase(),
                item_name: productMaterialName,
                item_brand: (brandKey || EventValues.Empty).toLowerCase(),
                item_type: isMarketplace ? 'marketplace' : 'standard',
              },
            ],
          },
        },
      })
    }

    if (isTealium) {
      productDetailPageViewTrack(
        brandKey,
        productNumber,
        productMaterialName,
        erpType,
        userType
      )
    }
  }, ONLOAD_EVENT_TIMEOUT)

  return () => {
    clearTimeout(timeout)
  }
}

export const sendViewSearchResultsEvent = (
  ga4payload?: GA4ViewSearchResultsPayload
): void => {
  sendEvent({
    payload: {
      event: 'view_search_results',
      q_focus: ga4payload?.qFocus,
      q_page: ga4payload?.qPage,
      q_type: ga4payload?.qType,
      search_result_status: ga4payload?.searchResultStatus,
      search_term: ga4payload?.searchTerm,
      did_you_mean_terms: ga4payload?.didYouMeanTerms,
    },
  })
}

export const determinePAForAddtoCart = (path): AddToCartPagesEnum => {
  switch (path) {
    case SearchFocusType.BuildingBlocks:
      return AddToCartPagesEnum.BuildingBlockSearchResults
    case SearchFocusType.Products:
      return AddToCartPagesEnum.SearchResultPage
    case SearchFocusType.StructureSearch:
      return AddToCartPagesEnum.StructureSearchResults
    default:
      return AddToCartPagesEnum.Others
  }
}

export const getSearchFocusEventValue = (focus): string => {
  switch (focus) {
    case SearchFocusType.BuildingBlocks:
      return SearchPageEnum.BuildingBlocks
    case SearchFocusType.TechnicalDocuments:
      return SearchPageEnum.TechnicalDocuments
    case SearchFocusType.StructureSearch:
      return SearchPageEnum.StructureSearch
    case SearchFocusType.SiteContent:
      return SearchPageEnum.SiteContent
    default:
      return focus
  }
}

export const sendEmproveDownloadsEvent = (
  document,
  linkUrl?,
  isPaidSubscription?
): void => {
  const { brand, displayType, product, fileName } = document
  const [file, version, fileExtension] = fileName.split('.')
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'file_download',
      file_extension: fileExtension,
      file_name: `${file}.${version}`.toLowerCase(),
      file_category: `emprove - ${isPaidSubscription ? 'paid' : 'free'}`,
      product_id: product.toLowerCase(),
      error_description: undefined,
      component: 'body',
      element_type: 'link',
      link_text: displayType.toLowerCase(),
      link_url: linkUrl || undefined,
      //Legacy UA event data
      eventCategory: EventValues.Emprove,
      eventAction: EventValues.Downloads,
      eventLabel: brand
        ? `${displayType || EventValues.Empty} > ${
            product || EventValues.Empty
          } (${brand})`
        : `${displayType || EventValues.Empty} > ${
            product || EventValues.Empty
          }`,
      eventInteractionType: 0,
    },
  })
}

export const sendOrderConfirmationEventForB2B = ({
  callback,
}: {
  callback: () => any
}): void => {
  const data: GTMEcommerceValues | null =
    sessionStorage.getItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY)
  try {
    if (!data) throw new Error()

    if (isTealium) {
      sendOrderConfirmationB2BTrackForAnalytic({ data, callback })
    }

    if (showGTMTag) {
      // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
      //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
      legacySendEvent({
        eventType: GTMEventTypes.PurchaseConfirmation,
        payload: {
          eventCategory: GTMEventCategory.OrderConfirmation.toLowerCase(),
          eventAction: data.eventAction || EventValues.Empty,
          eventLabel: data.eventLabel || EventValues.Empty,
          purchaseSoldToNumber: data.purchaseSoldToNumber || EventValues.Empty,
          purchaseBillToNumber: data.purchaseBillToNumber || EventValues.Empty,
          purchaseShipToNumber: data.purchaseShipToNumber || EventValues.Empty,
          purchaseParticipantId:
            data.purchaseParticipantId || EventValues.Empty,
          eventInteractionType: 1,
          ecommerce: data.ecommerce,
          paymentMethod: 'b2b',
        },
      })
      callback()
    }
  } catch (error) {
    console.error('ERROR - B2B Order Confirmation Event ', error)
  }
}

export const sendPurchaseConfirmationEventForB2B = (): void => {
  const payload: GTMEcommerceValues | null =
    sessionStorage.getItem<GTMEcommerceValues>(CART_DATA_STORAGE_KEY)
  const subTotal = payload?.ecommerce?.purchase?.products.reduce(
    (acc, item) => (Number(item.price) * item.quantity || 0) + acc,
    0
  )
  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    payload: {
      event: 'purchase',
      order_type: 'b2b-standard',
      deep_order_id: EventValues.Empty,
      order_participant_id: payload?.purchaseParticipantId || EventValues.Empty,
      order_sold_to: payload?.purchaseSoldToNumber || EventValues.Empty,
      order_bill_to: payload?.purchaseBillToNumber || EventValues.Empty,
      order_ship_to: payload?.purchaseShipToNumber || EventValues.Empty,
      shipping_tier:
        payload?.ecommerce?.purchase?.actionField?.shippingMethod ||
        EventValues.Empty,
      payment_type: 'b2b',
      ecommerce: {
        transaction_id: payload?.eventAction || EventValues.Empty,
        value: subTotal || 1,
        tax: priceFormatter(payload?.ecommerce?.purchase?.actionField?.tax),
        shipping: priceFormatter(
          payload?.ecommerce?.purchase?.actionField?.shipping
        ),
        coupon: payload?.ecommerce?.purchase?.actionField?.coupon,
        currency: payload?.ecommerce?.currencyCode,
        items: payload?.ecommerce?.purchase?.products?.map((product) => ({
          item_id: product.id,
          item_name: product.name,
          coupon: product.coupon || EventValues.Empty,
          discount: product.discount ?? 0,
          item_brand: product.brand,
          item_variant: product.variant,
          price: priceFormatter(product?.price),
          item_type: product.dimension91,
          quantity: product.quantity ?? 1,
        })),
      },
    },
  })
}

export const sendErrorPageEvent = (
  statusCode?: number,
  description?: string
): (() => void) => {
  const timeout = setTimeout(() => {
    const cookies = new Cookies()
    const gaCookie = cookies.get('_ga') || EventValues.Empty
    const errorStatusCode = statusCode ? `${statusCode}-` : ''
    const errorDescription = description
      ? `${errorStatusCode}${description?.toLowerCase()}`
      : undefined

    const basePayload = {
      event: 'exception',
      description: errorDescription,
      error_category: 'page errors',
      product_id: undefined,
      product_name: undefined,
      product_brand: undefined,
    }

    const legacyUAFields = {
      eventCategory: GTMEventCategory.Errors,
      eventAction: `${EventValues.OopsError} - ${statusCode} error`,
      eventLabel: gaCookie,
      eventInteractionType: 1,
    }

    const buildPayload =
      !statusCode && description
        ? { payload: basePayload }
        : {
            eventType: GTMEventTypes.AnalyticsEvent,
            payload: { ...basePayload, ...legacyUAFields },
          }

    sendEvent(buildPayload)
  }, ONLOAD_EVENT_TIMEOUT)
  return () => {
    clearTimeout(timeout)
  }
}

export const sendRecommendedProductsClickEvent = (
  type,
  product,
  index
): void => {
  const { gaProductCode } = product

  let carouselType = ''
  let pageParent: any = sessionStorage.getItem(
    'DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT'
  )
  switch (type) {
    case ProductCardType.Recommended:
    case ProductCardType.CartRecommendedProducts:
      carouselType = EventKeys.RECOMMENDED_PRODUCTS.toLowerCase()
      break

    case ProductCardType.OrderTrackingRecommendedProducts:
      carouselType = EventKeys.RECOMMENDED_PRODUCTS.toLowerCase()
      pageParent = { name: 'order tracking', detail: 'order tracking page' }
      break

    case ProductCardType.CustomersAlsoViewed:
      carouselType = 'customers_also_viewed'
      pageParent = { name: 'pdp', detail: 'product detail page' }
      break

    case ProductCardType.CompareSimilarItems:
      carouselType = 'compare_similar_items'
      pageParent = { name: 'pdp', detail: 'product detail page' }
      break

    case ProductCardType.BestSellers:
      carouselType = 'best_sellers'
      pageParent = { name: 'homepage', detail: 'homepage' }
      break

    case ProductCardType.PopularProducts:
      carouselType = 'popular_products'
      pageParent = { name: 'homepage', detail: 'homepage' }
      break

    case ProductCardType.NewArrivals:
      carouselType = 'new_arrivals'
      pageParent = { name: 'homepage', detail: 'homepage' }
      break

    case ProductCardType.BuyAgainHomepage:
      carouselType = 'buy_again'
      pageParent = { name: 'homepage', detail: 'homepage' }
      break

    case ProductCardType.ProductHeroCard:
    case ProductCardType.Related:
      carouselType = 'related_products'
      pageParent = { name: 'homepage', detail: 'homepage' }
      break

    default:
      carouselType = EventKeys.RECENTLY_VIEWED_PRODUCTS.toLowerCase()
      break
  }

  const promoData = buildGaPromoObjects(
    gaProductCode,
    product,
    index + 1,
    pageParent?.detail
  )

  const ga4Payload: GA4EcommercePayload = {
    creative_slot: `${carouselType} - ${pageParent?.name}`,
    items: [promoData.ga4PromoObj], // Items expects an array but we're passing a single promo object.
  }

  if (isTealium) {
    sendRecommendedProductsClickEventForAnalytic(gaProductCode, ga4Payload)
  }

  if (showGTMTag) {
    Analytics.sendInternalPromotionsClickEvent(
      gaProductCode,
      isAnalyticsEnabled,
      showGTMTag,
      ga4Payload
    )
  }
}

export const sendFeaturedProductsClickEvent = (
  product,
  gaPosition: number,
  viewPDP: boolean = true
): void => {
  const { gaProductCode, productNumber } = product
  const gaEventLabel = `${productNumber} | ${gaPosition}`

  sendEvent({
    eventType: GTMEventTypes.PromotionClick,
    payload: {
      eventCategory: 'featured products',
      eventAction: `view ${
        viewPDP
          ? ProductDetailsEventCategory.ProductDetailsPage
          : ProductDetailsEventCategory.PricingAndAvailability
      }`,
      eventLabel: gaEventLabel,
      eventInteractionType: 0,
    },
  })

  const promoItem = buildGaPromoObjects(
    gaProductCode,
    product,
    gaPosition,
    'search results page'
  )

  const ga4Payload: GA4EcommercePayload = {
    creative_slot: `featured - srp`,
    items: [promoItem.ga4PromoObj], // GA4 items expects array. We only pass one item here.
  }

  Analytics.sendInternalPromotionsClickEvent(
    gaProductCode,
    isAnalyticsEnabled,
    showGTMTag,
    ga4Payload
  )
}

export const sendDownloadFileEvent = (
  event: DownloadFileEvent,
  ga4Payload: GA4DownloadFilePayload,
  ga4Only: boolean = false
): void => {
  const isPDF =
    event.action === EventActions.SDS ||
    event.action === EventValues.OrderConfirmationPDF ||
    event.action === EventActions.Invoices ||
    ga4Payload.linkUrl?.endsWith('.pdf')
  const label = ((): string => {
    switch (event.action) {
      case EventActions.SDS:
        return [event.brandKey, event.productNumber, event.language]
          .join('-')
          .toLowerCase()
      case EventActions.Quotes:
        return event.quoteNumber.toLowerCase()
      case 'specification sheet':
      case 'more documents':
      case 'invoices':
      case EventValues.OrderConfirmationPDF:
        if (isPDF) {
          const fileName =
            ga4Payload.fileName?.split('/').pop() ||
            ga4Payload.linkUrl?.split('/').pop()
          return fileName?.substring(0, fileName.length - 4) || ''
        } else {
          return ga4Payload.linkUrl?.substring(6) || ''
        }
      case 'export orders list':
        return 'order search'
      case 'bulk upload template':
        return 'quick_order_template'
      default:
        return ''
    }
  })()

  const basePayload = {
    event: 'file_download',
    file_extension: isPDF ? 'pdf' : ga4Payload.fileExtension || 'html',
    file_name: label || undefined,
    file_category: ga4Payload.fileCategory || event.action,
    product_id: ga4Payload.productId?.toLowerCase() || undefined,
    lot_number: ga4Payload.lotNumber?.toLowerCase() || undefined,
    error_description: ga4Payload.errorDescription || undefined,
    component: ga4Payload.component,
    element_type: ga4Payload.elementType,
    link_url: ga4Payload.linkUrl || undefined,
    link_text: ga4Payload.linkText.toLowerCase(),
  }

  const legacyUAFields = {
    eventCategory: EventValues.Downloads.toLowerCase(),
    eventAction: ga4Payload.eventAction || event.action,
    eventLabel:
      ga4Payload.eventLabel || label.toLowerCase() || EventValues.NotAvailable,
    eventInteractionType: 0,
  }

  const buildPayload = ga4Only
    ? { payload: basePayload }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }

  sendEvent(buildPayload)
}

export const sendMenuNavigationEvent = ({
  L1item = undefined,
  L2item = undefined,
  L3item = undefined,
  L4item = undefined,
  linkUrl,
  ga4Only = false,
}: Menu): void => {
  const trackingUrl = parseRegionalUrl(linkUrl).pathAfterBasename

  const navItems = [L1item, L2item, L3item, L4item]
    .map((item) =>
      item
        ?.replace(regexForStripHTML, '')
        ?.replace(regexForStripEncodingUnicode, '')
        ?.replace(regexForStripSymbols, '')
        .trim()
        .toLowerCase()
    )
    .filter((item) => item)

  const basePayload = {
    event: 'navigation_interaction',
    action: 'header click',
    detail: navItems.join(' > '),
    section: undefined,
    component: 'header',
    element_type: 'link',
    element_text: navItems[navItems.length - 1],
    link_url: linkUrl || undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const legacyUAFields = {
    eventCategory: GTMEventCategory.Header,
    eventAction: EventValues.Links,
    eventLabel: trackingUrl || EventValues.Empty,
    eventInteractionType: 0,
  }

  const buildPayload = ga4Only
    ? { payload: basePayload }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }

  sendEvent(buildPayload)
}

export const sendInternalPromotionsEvent = (
  promotions: PromoObject[],
  ecommercePayload?: GA4EcommercePayload
): void => {
  if (isTealium) {
    sendInternalPromotionsEventForAnalytic(promotions, ecommercePayload)
  }

  if (showGTMTag) {
    Analytics.sendInternalPromotionsEvent(
      promotions,
      isAnalyticsEnabled,
      showGTMTag,
      ecommercePayload
    )
  }
}

export const sendRecommendedProductsData = (type, products): void => {
  sessionStorage.setItem(type, products)
}

export const getVisibleRecommendedProducts = (
  recommendedProductsParent
): RecommendedProduct[] => {
  if (recommendedProductsParent) {
    const recommendedProducts = [
      ...recommendedProductsParent.querySelectorAll('.promotions-slider-div'),
    ]
    if (recommendedProducts.length) {
      const recommendedProductsList: RecommendedProduct[] = []
      recommendedProducts.forEach((item, index) => {
        const itemLink = item
          .querySelector('a[id^="product-card-"]')
          ?.getAttribute('href')
          ?.split('/')[5]
        if (itemLink) {
          const productNumber = itemLink?.split('?')[0]
          const product: RecommendedProduct = {
            id: `list-item-${index + 1}`,
            isVisible: item?.parentElement?.className.includes('slide-visible'),
            productNumber,
          }
          recommendedProductsList.push(product)
        }
      })
      return recommendedProductsList.filter(
        (visibleProduct) => visibleProduct.isVisible
      )
    } else {
      return []
    }
  } else {
    return []
  }
}

export const sendProductPromotionsViewEvent = (
  products: DynamicProductFragment[],
  type: ProductCardType
): void => {
  const promotions: PromoObject[] = products.map((product) => {
    const [id, name, creative] = (
      product.gaProductCode?.split('|') || []
    ).slice(0, 3)
    return {
      id: id || EventValues.NotAvailable,
      name: name || EventValues.NotAvailable,
      creative: creative || EventValues.NotAvailable,
    }
  })

  let pageParent = ''
  let creativeSlot = ''
  switch (type) {
    case ProductCardType.CompareSimilarItems:
      pageParent = 'product detail page'
      creativeSlot = 'compare_similar_items - pdp'
      break

    case ProductCardType.Featured:
      pageParent = 'search results page'
      creativeSlot = 'featured - srp'
      break

    default:
      break
  }

  const ga4Promotions: GA4EcommercePayloadItem[] = products.map(
    (product, index) => {
      const promoObj = buildGaPromoObjects(
        product?.gaProductCode,
        product,
        index + 1,
        pageParent
      )

      return promoObj.ga4PromoObj
    }
  )

  const ga4Payload: GA4EcommercePayload = {
    creative_slot: creativeSlot,
    items: ga4Promotions,
  }

  sendInternalPromotionsEvent(promotions, ga4Payload)
}

export const getCarousalVisibleCards = (id): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return
  const recommendedProductsParent = document.querySelector(`#${id}`)
  const visibleRecommendedProducts: RecommendedProduct[] =
    getVisibleRecommendedProducts(recommendedProductsParent)
  sessionStorage.setItem(
    EventKeys.VISIBLE_RECOMMENDED_PRODUCTS,
    visibleRecommendedProducts
  )
}

export const sendCarousalPromoTrackEvent = (id, type): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return

  const recommendedProductsParent = document.querySelector(`#${id}`)
  const visibleRecommendedProducts = getVisibleRecommendedProducts(
    recommendedProductsParent
  )

  const initVisitedProducts = {
    recommended: [],
    customers_also_viewed: [],
    related: [],
    recently: [],
    featured: [],
    popular_products: [],
    new_products: [],
    best_sellers: [],
    biaSC: [],
    product_hero_card: [],
  }

  const visitedProducts: Object =
    sessionStorage.getItem(EventKeys.VISITED_RECOMMENDED_PRODUCTS) ||
    initVisitedProducts

  //if the user is clicking the button for the first time among all the product cards,
  // save the visible products as visited.
  if (!visitedProducts[type]?.length) {
    visitedProducts[type] = sessionStorage.getItem(
      EventKeys.VISIBLE_RECOMMENDED_PRODUCTS
    )
  }

  const ga4Promotions: GA4EcommercePayloadItem[] = []
  const promotions: PromoObject[] = []
  const newVisibleProduct = visibleRecommendedProducts.filter(
    (o1) =>
      visitedProducts && !visitedProducts[type]?.some((o2) => o1.id === o2.id)
  )

  const newVisibleProductNumbers = newVisibleProduct.map((product) =>
    product.productNumber.toUpperCase()
  )

  if (newVisibleProduct.length) {
    let productCardType = ''
    let carouselType = ''
    let pageParent: any = sessionStorage.getItem(
      'DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT'
    )

    switch (type) {
      case ProductCardType.Recommended:
      case ProductCardType.CartRecommendedProducts:
        productCardType = EventKeys.RECOMMENDED_PRODUCTS
        carouselType = EventKeys.RECOMMENDED_PRODUCTS.toLowerCase()
        break

      case ProductCardType.CustomersAlsoViewed:
        productCardType = EventKeys.CUSTOMER_VIEWED_PRODUCTS
        carouselType = 'customers_also_viewed'
        pageParent = { name: 'pdp', detail: 'product detail page' }
        break

      case ProductCardType.Featured:
        productCardType = EventKeys.FEATURED_PRODUCTS
        break

      case ProductCardType.PopularProducts:
        productCardType = EventKeys.POPULAR_PRODUCTS
        carouselType = EventKeys.POPULAR_PRODUCTS.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      case ProductCardType.NewArrivals:
        productCardType = EventKeys.NEW_ARRIVALS
        carouselType = EventKeys.NEW_ARRIVALS.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      case ProductCardType.BuyAgainHomepage:
        productCardType = EventKeys.BUY_AGAIN
        carouselType = EventKeys.BUY_AGAIN.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      case ProductCardType.BestSellers:
        productCardType = EventKeys.BEST_SELLERS
        carouselType = EventKeys.BEST_SELLERS.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      case ProductCardType.ProductHeroCard:
        productCardType = EventKeys.PRODUCT_HERO_CARD
        carouselType = EventKeys.RELATED_PRODUCTS.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      case ProductCardType.Related:
        productCardType = EventKeys.RELATED_PRODUCTS
        carouselType = EventKeys.RELATED_PRODUCTS.toLowerCase()
        pageParent = { name: 'homepage', detail: 'homepage' }
        break

      default:
        productCardType = EventKeys.RECENTLY_VIEWED_PRODUCTS
        carouselType = EventKeys.RECENTLY_VIEWED_PRODUCTS.toLowerCase()
        break
    }

    const storedRecommendedProducts: any =
      sessionStorage.getItem(productCardType)

    const pickedProduct = storedRecommendedProducts?.filter((product) =>
      newVisibleProductNumbers.includes(product?.productKey?.toUpperCase())
    )

    if (pickedProduct && pickedProduct.length) {
      pickedProduct.forEach((product, index) => {
        const promoData = buildGaPromoObjects(
          product.gaProductCode,
          product,
          index + 1
        )

        promotions.push(promoData.promo)
        ga4Promotions.push(promoData.ga4PromoObj)
      })

      const ga4Payload: GA4EcommercePayload = {
        creative_slot: `${carouselType} - ${pageParent.name}`,
        items: ga4Promotions,
      }

      sendInternalPromotionsEvent(promotions, ga4Payload)
      visitedProducts[type] = [...visitedProducts[type], ...newVisibleProduct]

      sessionStorage.setItem(
        EventKeys.VISITED_RECOMMENDED_PRODUCTS,
        visitedProducts
      )
    }
  }
}

export const isRecommendedProductsVisibleListener = (): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return

  const recommendedProductsParent = document.querySelector(
    '#recommended-products'
  )

  const storedProducts: any = sessionStorage.getItem(
    EventKeys.RECOMMENDED_PRODUCTS
  )

  const pageParent: any = sessionStorage.getItem(
    'DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT'
  )

  if (recommendedProductsParent) {
    const recommendedProducts = [
      ...recommendedProductsParent.querySelectorAll('.promotions-slider-div'),
    ]

    if (recommendedProducts.length) {
      const ga4Promotions: GA4EcommercePayloadItem[] = []
      const promotions: PromoObject[] = []
      const visibleProducts: any = []

      recommendedProducts.forEach((product) => {
        if (product?.parentElement?.className.includes('slide-visible')) {
          const element = product.getBoundingClientRect()
          Analytics.isInViewport(element) && visibleProducts.push(product)
        }
      })

      visibleProducts.forEach((product, index) => {
        const itemLink = product.querySelector('a[id^="product-card-"]')
        const productNumberURL = itemLink.getAttribute('href')?.split('/')[5]
        const productNumber = productNumberURL?.split('?')[0]?.toUpperCase()

        if (productNumber) {
          const pickedProduct = storedProducts?.filter(
            (product) => productNumber === product?.productKey?.toUpperCase()
          )

          const promoData = buildGaPromoObjects(
            pickedProduct[0]?.gaProductCode,
            pickedProduct[0],
            index + 1
          )

          promotions.push(promoData.promo)
          ga4Promotions.push(promoData.ga4PromoObj)
        }

        const ga4Payload: GA4EcommercePayload = {
          creative_slot: `${EventKeys.RECOMMENDED_PRODUCTS.toLowerCase()} - ${
            pageParent.name
          }`,
          items: ga4Promotions,
        }

        index + 1 === visibleProducts.length &&
          sendInternalPromotionsEvent(promotions, ga4Payload)
        document.removeEventListener(
          'scroll',
          isRecommendedProductsVisibleListener
        )
      })
    }
  }
}

export const isCustomersViewedProductsVisibleListener = (): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return
  const recommendedProductsParent = document.querySelector(
    '#customers-also-viewed-products'
  )
  const storedProducts: any = sessionStorage.getItem(
    EventKeys.CUSTOMER_VIEWED_PRODUCTS
  )
  if (recommendedProductsParent) {
    const recommendedProducts = [
      ...recommendedProductsParent.querySelectorAll('.promotions-slider-div'),
    ]
    if (recommendedProducts.length) {
      const ga4Promotions: GA4EcommercePayloadItem[] = [],
        promotions: PromoObject[] = [],
        visibleProducts: any = []
      recommendedProducts.forEach((product) => {
        if (product?.parentElement?.className.includes('slide-visible')) {
          const element = product.getBoundingClientRect()
          Analytics.isInViewport(element) && visibleProducts.push(product)
        }
      })
      visibleProducts.forEach((product, index) => {
        const itemLink = product.querySelector('a[id^="product-card-"]')
        const productNumberURL = itemLink.getAttribute('href')?.split('/')[5]
        const productNumber = productNumberURL?.split('?')[0]?.toUpperCase()
        if (productNumber) {
          const pickedProduct = storedProducts?.filter(
            (product) => productNumber === product?.productKey?.toUpperCase()
          )

          const promoData = buildGaPromoObjects(
            pickedProduct[0]?.gaProductCode,
            pickedProduct[0],
            index + 1,
            'product detail page'
          )

          promotions.push(promoData.promo)
          ga4Promotions.push(promoData.ga4PromoObj)
        }
        const ga4Payload: GA4EcommercePayload = {
          creative_slot: `customers_also_viewed - pdp`,
          items: ga4Promotions,
        }
        index + 1 === visibleProducts.length &&
          sendInternalPromotionsEvent(promotions, ga4Payload)
        document.removeEventListener(
          'scroll',
          isCustomersViewedProductsVisibleListener
        )
      })
    }
  }
}
export const isRecentlyViewedProductsVisibleListener = (): void => {
  if (typeof window === 'undefined' || typeof document === 'undefined') return
  const recommendedProductsParent = document.querySelector(
    '#recently-viewed-products'
  )
  const storedProducts: any = sessionStorage.getItem(
    EventKeys.RECENTLY_VIEWED_PRODUCTS
  )
  const pageParent: any = sessionStorage.getItem(
    'DYNAMIC_CAROUSEL_PRODUCTS_PAGE_PARENT'
  )
  if (recommendedProductsParent) {
    const recommendedProducts = [
      ...recommendedProductsParent.querySelectorAll('.promotions-slider-div'),
    ]
    if (recommendedProducts.length) {
      const ga4Promotions: GA4EcommercePayloadItem[] = [],
        promotions: PromoObject[] = [],
        visibleProducts: any = []
      recommendedProducts.forEach((product) => {
        if (product?.parentElement?.className.includes('slide-visible')) {
          const element = product.getBoundingClientRect()
          Analytics.isInViewport(element) && visibleProducts.push(product)
        }
      })
      visibleProducts.forEach((product, index) => {
        const itemLink = product.querySelector('a[id^="product-card-"]')
        const productNumberURL = itemLink.getAttribute('href')?.split('/')[5]
        const productNumber = productNumberURL?.split('?')[0]?.toUpperCase()
        if (productNumber) {
          const pickedProduct = storedProducts?.filter(
            (product) => productNumber === product?.productKey?.toUpperCase()
          )

          const promoData = buildGaPromoObjects(
            pickedProduct[0]?.gaProductCode,
            pickedProduct[0],
            index + 1
          )

          promotions.push(promoData.promo)
          ga4Promotions.push(promoData.ga4PromoObj)
        }
        const ga4Payload: GA4EcommercePayload = {
          creative_slot: `${EventKeys.RECENTLY_VIEWED_PRODUCTS.toLowerCase()} - ${
            pageParent.name
          }`,
          items: ga4Promotions,
        }
        index + 1 === visibleProducts.length &&
          sendInternalPromotionsEvent(promotions, ga4Payload)
        document.removeEventListener(
          'scroll',
          isRecentlyViewedProductsVisibleListener
        )
      })
    }
  }
}

export const trackScrollingForProductCards = (
  type: ProductCardType,
  products: DynamicProductFragment[]
): (() => void) => {
  if (
    type === ProductCardType.Recommended ||
    type === ProductCardType.CartRecommendedProducts ||
    type === ProductCardType.OrderTrackingRecommendedProducts
  ) {
    sendRecommendedProductsData(EventKeys.RECOMMENDED_PRODUCTS, products)
    document.addEventListener('scroll', isRecommendedProductsVisibleListener)
  }
  if (type === ProductCardType.CustomersAlsoViewed) {
    sendRecommendedProductsData(EventKeys.CUSTOMER_VIEWED_PRODUCTS, products)
    document.addEventListener(
      'scroll',
      isCustomersViewedProductsVisibleListener
    )
  }
  if (type === ProductCardType.Recently) {
    sendRecommendedProductsData(EventKeys.RECENTLY_VIEWED_PRODUCTS, products)
    document.addEventListener('scroll', isRecentlyViewedProductsVisibleListener)
  }
  return () => {
    document.removeEventListener('scroll', isRecommendedProductsVisibleListener)
    document.removeEventListener(
      'scroll',
      isCustomersViewedProductsVisibleListener
    )
    document.removeEventListener(
      'scroll',
      isRecentlyViewedProductsVisibleListener
    )
  }
}

export const sendCustomProductDetailEvent = (
  product?: PdpFieldsFragment
): void => {
  const productBrand = product?.brand?.key || EventValues.Empty
  const productNumber = product?.productNumber || EventValues.Empty
  const productName = product?.name || EventValues.Empty

  // UA/GA3 legacy event send to delivery only one payload to one platform based on object data flags.
  //This can be replaced with a sendEvent() or removed entirely once UA/GA3 is sunset on July 1st, 2024
  legacySendEvent({
    eventType: GTMEventTypes.ProductDetailView,
    payload: {
      eventCategory: GTMEventCategory.ProductDetailView,
      eventAction: productBrand,
      eventLabel: productNumber,
      eventInteractionType: 1,
      ecommerce: {
        currencyCode: DEFAULT_CURRENCY_CODE.toLowerCase(),
        detail: {
          actionField: {
            list: GTMEventCategory.ProductDetailView.toLowerCase(),
          },
          products: [
            {
              id: productNumber.toLowerCase(),
              name:
                productName
                  .toLowerCase()
                  .replace(regexForStripEncodingUnicode, '')
                  .replace(regexForStripHTML, '')
                  .replace(regexForStripSymbols, '') || '',
              brand: productBrand.toLowerCase(),
            },
          ],
        },
      },
    },
  })

  // GA4 - View item details
  legacySendEvent({
    payload: {
      event: 'view_item',
      ecommerce: {
        currency: DEFAULT_CURRENCY_CODE.toLowerCase(),
        items: [
          {
            item_id: productNumber.toLowerCase(),
            item_name:
              productName
                .toLowerCase()
                .replace(regexForStripEncodingUnicode, '')
                .replace(regexForStripHTML, '')
                .replace(regexForStripSymbols, '') || '',
            item_brand: productBrand.toLowerCase(),
            item_type: product?.isMarketplace ? 'marketplace' : 'standard',
          },
        ],
      },
    },
  })
}

export const sendBreadcrumbsEvent = (
  eventAction,
  eventLabel,
  linkUrl: string,
  showBack?: Boolean
): void => {
  // sometimes they are strings
  // sometimes they are formattedmessages
  // defaultMessage because it needs to be in English
  const combinedBreadCrumbs = isArray(eventLabel)
    ? eventLabel
        .map((item) => {
          return typeof item === 'string'
            ? item
            : item?.props?.defaultMessage || ''
        })
        .join(' > ')
        .toLowerCase()
    : undefined

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'navigation_interaction',
      action: 'breadcrumb click',
      detail: showBack ? `back > ${combinedBreadCrumbs}` : combinedBreadCrumbs,
      section: undefined,
      component: 'breadcrumb',
      element_type: 'link',
      element_text:
        eventAction?.props?.defaultMessage?.toLowerCase() ||
        eventAction?.toLowerCase() ||
        undefined,
      link_url: linkUrl || undefined,
      core_event: 'no',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
      //Legacy UA
      eventCategory: GTMEventCategory.Breadcrumbs,
      eventAction:
        eventAction?.props?.defaultMessage?.toLowerCase() ||
        eventAction?.toLowerCase() ||
        undefined,
      eventLabel: showBack
        ? `back > ${combinedBreadCrumbs}`
        : combinedBreadCrumbs,
      eventInteractionType: 0,
    },
  })
}

export const sendCheckAvailabilityEvent = (
  product: string,
  carouselType?: ProductCardType,
  gaType?: AddToCartPagesEnum,
  ga4Payload?: GA4CheckAvailabilityEventPayload
): void => {
  const locationContext = carouselType
    ? PricingAndAvailabilityPanelType[carouselType]
    : gaType
  const mainAction = 'check availability'
  const completeAction = locationContext
    ? `${mainAction} - ${locationContext}`
    : mainAction

  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'pricing_availability',
      click_type: 'pricing_availability : list',
      click_action: mainAction,
      product_id: ga4Payload?.productId?.toLowerCase() || undefined,
      product_variant: ga4Payload?.productVariant?.toLowerCase() || undefined,
      product_name:
        ga4Payload?.productName
          ?.replace(regexForStripHTML, '')
          .replace(regexForStripSymbols, '')
          .replace(regexForStripEncodingUnicode, '')
          .toLowerCase() || undefined,
      product_brand: ga4Payload?.productBrand?.toLowerCase() || undefined,
      component: 'modal',
      element_type: 'button',
      link_text: mainAction,
      link_url: undefined,
      //legacy UA
      eventCategory: GTMEventCategory.PricingAndAvailability,
      eventAction: completeAction,
      eventLabel: product?.toLowerCase() || EventValues.NotAvailable,
      eventInteractionType: 0,
    },
  })
}

export const sendBundleKitClickEvent = (productId, bundles): void => {
  const bundleIds = bundles.map((bundle) => bundle?.bundleId)
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: AddToCartPagesEnum.ProductDetailPage,
      eventAction: 'bundle kit link',
      eventLabel: `${productId} > ${bundleIds.join('|')}` || EventValues.Empty,
      eventInteractionType: 0,
    },
  })
}

export const sendLotPreference = (materialNumber, selected): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: AddToCartPagesEnum.ShoppingCartPage,
      eventAction: 'lot preference',
      eventLabel: `${materialNumber.toLowerCase()} > ${
        selected ? 'select' : 'remove'
      } single lot`,
      eventInteractionType: 0,
    },
  })
}

export const sendFDD = (wholeCart = false): void => {
  const cookies = new Cookies()
  const gaCookie = cookies.get('_ga') || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.CheckoutPage,
      eventAction: `${wholeCart ? 'whole cart' : 'line item'} delivery date`,
      eventLabel: gaCookie,
      eventInteractionType: 0,
    },
  })
}

export const sendDealerPricingEvent = (
  productNumber: string,
  preferredDealerInfo: Maybe<string> | undefined
): void => {
  const eventLabel = [productNumber, preferredDealerInfo].join('>')
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.PricingAndAvailability,
      eventAction: 'see all buying options',
      eventLabel: eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendSearchCertificatesEvent = ({
  section,
  productNumber,
  lotNumber,
  errorStatus = false,
  ga4Only = false,
  linkUrl,
}: {
  section: string
  productNumber: string
  lotNumber: string
  errorStatus?: boolean
  ga4Only?: boolean
  linkUrl?: string
}): void => {
  const label = `${
    errorStatus ? 'Sorry, unable to find certificate > ' : ''
  }${productNumber?.trim()} > ${lotNumber?.trim()}`
  const filename = productNumber.replace(/\./g, '-')

  const basePayload = {
    event: 'ga4Event',
    event_name: 'file_download',
    file_extension: errorStatus ? undefined : linkUrl ? 'html' : 'pdf',
    file_name: errorStatus
      ? undefined
      : linkUrl
        ? lotNumber.trim().toLowerCase()
        : filename,
    file_category: section.toLowerCase(),
    product_id: productNumber.trim().toLowerCase(),
    lot_number: lotNumber.trim().toLowerCase(),
    error_description: errorStatus
      ? `${section.toLowerCase()} not found`
      : undefined,
    component: 'action_form',
    element_type: 'button',
    link_text: 'search',
    link_url: linkUrl || undefined,
  }

  const legacyUAFields = {
    eventCategory: EventValues.Downloads.toLowerCase(),
    eventAction: `${section.toLowerCase()} search`,
    eventLabel: label,
    eventInteractionType: 0,
  }

  const buildPayload = ga4Only
    ? { payload: basePayload }
    : {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }

  sendEvent(buildPayload)
}

export const sendCertificatesDownloadEvent = ({
  fileExtension,
  productNumber,
  lotNumber,
}: {
  fileExtension: string
  productNumber: string
  lotNumber: string
}): void => {
  sendEvent({
    payload: {
      event: 'file_download',
      file_extension: fileExtension,
      file_name: productNumber.trim().toLowerCase(),
      file_category: 'coa',
      product_id: productNumber.trim().toLowerCase(),
      lot_number: lotNumber.trim().toLowerCase(),
      error_description: undefined,
      component: 'left rail',
      element_type: 'link',
      link_text: lotNumber.trim().toLowerCase(),
      link_url: undefined,
    },
  })
}

export const sendViewSampleCOAEvent = (
  ga4payload: ViewSampleCOAEvent
): void => {
  sendEvent({
    payload: {
      event: 'ga4Event',
      event_name: 'file_download',
      file_extension: ga4payload?.fileExtension,
      file_name: ga4payload?.fileName,
      file_category: 'sample coa',
      product_id: ga4payload?.productId,
      error_description: undefined,
      component: 'action_form',
      element_type: 'button',
      link_text: 'view sample coa',
      link_url: ga4payload?.linkUrl || undefined,
    },
  })
}

export const sendDocumentDownloadEvent = (
  productNmber: string,
  documentName: string,
  text: string
): void => {
  const docName =
    (documentName || EventValues.Empty)
      ?.replace(regexForStripHTMLWithContent, ' ')
      ?.replace(regexForStripEncodingUnicode, '')
      ?.replace(regexForStripSymbols, '') || ''
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: EventValues.Downloads.toLowerCase(),
      eventAction: text,
      eventLabel: `${productNmber} > ${docName}`,
      eventInteractionType: 0,
    },
  })
}

export const sendRequestTrialLicenseEvent = (
  participantId: string,
  errorMessage?: Message
): void => {
  const productName = 'Spectroquant Prove Connect to LIMS'
  const errorMsg = errorMessage?.defaultMessage || EventValues.Empty
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'request trial license',
      eventAction: errorMsg?.length
        ? `${errorMsg?.replace(regexForStripSpecialChars, '')} > ${productName}`
        : productName,
      eventLabel: participantId,
      eventInteractionType: 0,
    },
  })
}

export const sendShareEvent = (
  {
    detail,
    section,
    component,
    elementType,
    elementText,
    method,
    isMarketplaceCart,
    productId,
  }: {
    detail: string
    section?: string
    component: string
    elementType: string
    elementText: string
    method: string
    isMarketplaceCart?: boolean
    productId?: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'share',
    action: undefined,
    detail,
    section,
    component,
    element_type: elementType,
    element_text: elementText,
    link_url: undefined,
    core_event: 'yes',
    method,
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    ...(productId && {
      product_id: productId.toLowerCase(),
    }),
    ...(isMarketplaceCart !== undefined && {
      cart_type: isMarketplaceCart ? 'marketplace' : 'standard',
    }),
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendProductDetailsEvent = (
  eventAction: ProductDetailsEventAction,
  eventCategory: ProductDetailsEventCategory,
  eventLabel?: string,
  ga4Payload?: GA4ProductDetailsEventPayload
): void => {
  //GA4
  const incompletePayload = {
    event: 'ga4Event',
    click_action: ga4Payload?.clickAction,
    click_type: ga4Payload?.clickType,
    click_details: ga4Payload?.clickDetails || undefined,
    element_type: ga4Payload?.elementType,
    component: ga4Payload?.component,
    link_text: ga4Payload?.linkText,
    //Legacy UA Event Properties
    eventCategory,
    eventAction,
    eventLabel,
    eventInteractionType: 0,
  }
  if (eventAction === ProductDetailsEventAction.ViewSampleCOAError) {
    sendEvent({
      eventType: GTMEventTypes.AnalyticsEvent,
      payload: {
        ...incompletePayload,
        eventCategory: ProductDetailsEventCategory.ProductDetailsPage,
        eventAction: ProductDetailsEventAction.ViewSampleCOAError,
        eventLabel,
        eventInteractionType: 0,
      },
    })
  } else {
    sendEvent({
      eventType: GTMEventTypes.AnalyticsEvent,
      payload: {
        ...incompletePayload,
        event_name: ga4Payload?.eventName,
        click_details: ga4Payload?.clickDetails || undefined,
        product_id: ga4Payload?.productId?.toLowerCase() || undefined,
        product_name:
          ga4Payload?.productName
            ?.replace(regexForStripSymbols, '')
            .replace(regexForStripHTML, '')
            .toLowerCase() || undefined,
        product_brand: ga4Payload?.productBrand?.toLowerCase() || undefined,
        product_variant: ga4Payload?.productVariant?.toLowerCase() || undefined,
        link_url: ga4Payload?.linkUrl || undefined,
      },
    })
  }
}

export const sendProductInfoInteractionEvent = (
  {
    action,
    detail,
    section,
    component,
    elementType,
    elementText,
    linkUrl,
    productId,
    productBrand,
    productVariant,
  }: {
    action?: string
    detail?: string | null
    section?: string
    component: string
    elementType: string | undefined
    elementText?: string | null
    linkUrl?: string
    productId?: string
    productBrand?: string
    productVariant?: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'product_info_interaction',
    action: action?.toLowerCase(),
    detail:
      detail
        ?.replace(regexForStripHTML, '')
        .replace(regexForStripSymbols, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase() || undefined,
    section,
    component,
    element_type: elementType,
    element_text: elementText?.toLowerCase() || undefined,
    link_url: linkUrl,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    product_id: productId?.toLowerCase(),
    product_brand: productBrand?.toLowerCase(),
    product_variant: productVariant?.toLowerCase(),
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendQuickOrderTracking = (
  eventAction: string,
  userInfo: string | undefined,
  ga4payload?: GA4QuickOrderTrackingPayload,
  categoryOverride?: QuickOrderCategoryOverride
): void => {
  //GA4
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'ga4Event',
      event_name: 'quick_order_page_click',
      click_type: ga4payload?.clickType,
      click_action: ga4payload?.clickAction,
      click_details: ga4payload?.clickDetails || undefined,
      component: ga4payload?.component,
      element_type: ga4payload?.elementType,
      link_text: ga4payload?.linkText,
      link_url: ga4payload?.linkUrl || undefined,
      //Legacy UA Event Properties
      eventCategory: categoryOverride || 'quick order page',
      eventAction: eventAction,
      eventLabel: userInfo,
      eventInteractionType: 0,
    },
  })
}

export const sendQuickOrderInteractionEvent = (
  ga4payload?: GA4QuickOrderInteractionPayload,
  legacyUAFields?: LegacyUAFields,
  productDetails?: ProductDetails
): void => {
  const productPayload = productDetails
    ? {
        product_id: productDetails?.productId?.toLowerCase(),
        product_brand: productDetails?.productBrand?.toLowerCase(),
        product_variant: productDetails?.productVariant?.toLowerCase(),
        product_name: productDetails?.productName
          ?.replace(regexForStripHTML, '')
          .replace(regexForStripSymbols, '')
          .replace(regexForStripEncodingUnicode, '')
          .toLowerCase(),
      }
    : {}

  const basePayload = {
    event: 'quick_order_interaction',
    action: ga4payload?.action,
    detail: ga4payload?.detail,
    section: 'quick order',
    component: ga4payload?.component,
    element_type: ga4payload?.elementType,
    element_text: ga4payload?.elementText || undefined,
    link_url: undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }
  // enhancedPayload created beacuse for cross icon click from manual tab search in Quick Order Page required Product data
  // Manual tab and Bulk order click doed not required product data
  const enhancedPayload = productDetails
    ? { ...basePayload, ...productPayload }
    : basePayload

  // GA3 for Manual Tab, Bluk order and cross icon click in Quick Order Page
  legacySendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: legacyUAFields,
  })

  // GA4 2.0 for Manual Tab, Bluk order and cross icon click in Quick Order Page
  legacySendEvent({
    payload: enhancedPayload,
  })
}

export const sendOrderNotesTracking = (page = '', userId = ''): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: page,
      eventAction: EventActions.OrderNotes,
      eventLabel: userId,
      eventInteractionType: 0,
    },
  })
}

export const sendOrderStatusInfoTracking = (
  action: OrderStatusInfoEvent | null,
  userId: string
): void => {
  if (action !== null) {
    sendEvent({
      eventType: GTMEventTypes.AnalyticsEvent,
      payload: {
        eventCategory: 'order-center',
        eventAction: `order status info - ${action}`,
        eventLabel: userId,
        eventInteractionType: 0,
      },
    })
  }
}

export const sendDocumentLibraryPage = (
  eventAction: string,
  eventLabel: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: ExtraEventValues.documentLibraryPage,
      eventAction,
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendDocDownloadAnalyticsEvent = ({
  fileName,
  fileCategory,
  productId,
  component,
  elementType,
  linkText,
  linkUrl,
  event = 'file_download',
  fileExtension = 'pdf',
  lotNumber = undefined,
}: {
  fileName: string
  fileCategory: DocLibCertificateTypes
  productId: string
  component: string
  elementType: string
  linkText: string
  linkUrl: string
  event?: string
  fileExtension?: string
  lotNumber?: string
}): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    eventCategory: ExtraEventValues.documentLibraryPage,
    payload: {
      file_name: fileName,
      file_category: fileCategory,
      product_id: productId,
      component: component,
      element_type: elementType,
      link_text: linkText,
      link_url: linkUrl,
      event: event,
      file_extension: fileExtension,
      lot_number: lotNumber,
    },
  })
}

export const sendCommonDetailProductIdEvent = (
  ga4Payload: GA4CommonDetailProductIdEvent,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4Payload.event,
    action: ga4Payload.action?.toLowerCase(),
    detail: ga4Payload.detail?.toLowerCase(),
    section: ga4Payload.section,
    component: ga4Payload.component,
    element_text: ga4Payload.elementText?.toLowerCase(),
    element_type: ga4Payload.elementType,
    link_url: ga4Payload.linkUrl,
    core_event: ga4Payload.coreEvent,
    product_id: ga4Payload.productId?.toLowerCase(),
    user_detail: ga4Payload.userDetail,
    event_group: ga4Payload.eventGroup,
    event_subgroup: ga4Payload.eventSubGroup,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendRequestQuoteTracking = (
  areAllItemsSelected: boolean,
  userId: string | undefined
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'list page',
      eventAction: areAllItemsSelected
        ? 'full list quote request'
        : 'partial list quote request',
      eventLabel: userId || '',
      eventInteractionType: 0,
    },
  })
}

export const sendBeginOrCompleteQuoteRequestEvent = (
  {
    event,
    detail,
    section,
    component,
    elementType,
    elementText,
  }: {
    event: string
    detail?: string
    section: string
    component: string
    elementType: string
    elementText: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event,
    action: undefined,
    detail,
    section,
    component,
    element_type: elementType,
    element_text: elementText,
    link_url: undefined,
    core_event: 'yes',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendQuotesPageTracking = (
  eventAction: string,
  userId: string | undefined
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'quotes page',
      eventAction,
      eventLabel: userId || '',
      eventInteractionType: 0,
    },
  })
}

export const switchAccountTracking = (
  eventAction: string,
  eventLabel: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'switch accounts',
      eventAction,
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendRequestQuoteEvent = ({
  category,
  action,
  label,
}: RequestQuoteEvent): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventInteractionType: 0,
    },
  })
}

export const sendQuoteInteractionEvent = (
  {
    action,
    detail,
    section,
    component,
    elementType,
    elementText,
    linkUrl,
    quoteStatus,
  }: {
    action: string
    detail?: string | null
    section: string | undefined
    component: string
    elementType: string
    elementText?: string
    linkUrl?: string | null
    quoteStatus?: string
  },
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: 'quote_interaction',
    action,
    detail: detail || undefined,
    section,
    component,
    element_type: elementType,
    element_text: elementText || undefined,
    link_url: linkUrl || undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    quote_status: quoteStatus || undefined,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendSafetyAndRegulatoryEvent = (
  eventAction: SafetyAndRegulatoryEventCategory,
  eventCategory: SafetyAndRegulatoryEventCategory,
  eventLabel?: string
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory,
      eventAction,
      eventLabel,
      eventInteractionType: 0,
    },
  })
}

export const sendBuyInKitClickEvent = (
  materialNumber: string,
  bundleData: PromotionalBundle[],
  eventAction: BuyInKItEventActionEnum
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'promo bundle',
      eventAction,
      eventLabel:
        `${materialNumber} > ${bundleData
          .map((item) => item.bundleId)
          .join('|')}` || EventValues.Empty,
      eventInteractionType: 0,
    },
  })
}

export const sendBuyItNowClickEvent = (
  materialNumbers: string[],
  eventAction: BuyItNowEventActionEnum
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'buy it now',
      eventAction,
      eventLabel: materialNumbers.join(' | '),
      eventInteractionType: 0,
    },
  })
}

export const sendEmailLinkVerificationPageEvents = (
  eventLabel: string,
  eventAction: EmailLinkVerificationActionEnum
): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: EmailLinkVerificationEnum.EmailVerification,
      eventAction,
      eventLabel,
      eventInteractionType:
        eventAction === EmailLinkVerificationActionEnum.VerificationSend
          ? 1
          : 0,
    },
  })
}

export const sendSubstanceClickEvent = (substanceId: string): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: GTMEventCategory.SearchResultPage,
      eventAction: 'substance click',
      eventLabel: substanceId,
      eventInteractionType: 0,
    },
  })
}

export const sendProductClickEvent = ({
  product,
  searchTerm,
  substanceId,
  substancePosition,
}: ProductAnalytics): void => {
  /**
   * Clearing the ecommerce object out
   * before sending the UA click event
   */
  legacySendEvent({
    payload: {
      ecommerce: null,
    },
  })

  legacySendEvent({
    eventType: 'productClick',
    payload: {
      eventCategory: 'product lists clicks',
      eventAction: GTMEventCategory.SearchResultPage.toLowerCase(),
      eventLabel: searchTerm.toLowerCase(),
      eventInteractionType: 0,
      ecommerce: {
        click: {
          actionField: {
            list: GTMEventCategory.SearchResultPage.toLowerCase(),
          },
          products: [
            {
              name: decodeTitle(product?.description?.toLowerCase() ?? ''),
              id: product.productNumber?.toLowerCase(),
              brand: decodeTitle(product?.brand?.name?.toLowerCase() ?? ''),
              variant: product.productNumber?.toLowerCase(),
              list: GTMEventCategory.SearchResultPage?.toLowerCase(),
              position: product?.position ?? '',
              dimension112: substanceId,
              dimension113: substancePosition?.toString(),
            },
          ],
        },
      },
    },
  })

  /**
   * Clearing the ecommerce object out
   * before sending the GA4 click event
   */
  legacySendEvent({
    payload: {
      ecommerce: null,
    },
  })

  legacySendEvent({
    payload: {
      event: 'select_item',
      ecommerce: {
        item_list_id: GTMEventCategory.SearchResultPage?.toLowerCase(),
        item_list_name: GTMEventCategory.SearchResultPage?.toLowerCase(),
        items: [
          {
            item_id: product.productNumber?.toLowerCase(),
            item_name: decodeTitle(product?.description?.toLowerCase() ?? ''),
            index: product?.position ?? '',
            item_brand: decodeTitle(product?.brand?.name?.toLowerCase() ?? ''),
            item_substance: substanceId,
            item_list_id: GTMEventCategory.SearchResultPage.toLowerCase(),
            item_list_name: GTMEventCategory.SearchResultPage.toLowerCase(),
            item_substance_position: substancePosition?.toString(),
          },
        ],
      },
    },
  })
}

export type EventAction =
  | 'close'
  | 'ignore'
  | 'open button'
  | 'open link'
  | 'show products'

export const sendSelectorToolEvent = ({
  eventAction,
  eventLabel,
  action,
  detail,
  section,
  component,
  elementType,
  elementText,
  productId,
  productName,
  productBrand,
  basePayloadOverrides = {},
}: {
  eventAction?: EventAction
  eventLabel?: string
  action: string
  detail?: string
  section?: string
  component?: string
  elementType?: string
  elementText?: string
  productId: string
  productName: string | undefined
  productBrand: string
  basePayloadOverrides?: Record<string, unknown>
}): void => {
  const basePayload = {
    event: 'antibody_selector_interaction',
    action,
    detail: detail?.toLowerCase() || undefined,
    section,
    component,
    element_type: elementType,
    element_text: elementText?.toLowerCase() || undefined,
    link_url: undefined,
    core_event: 'no',
    user_detail: undefined,
    event_group: undefined,
    event_subgroup: undefined,
    product_id: productId ? productId?.toLowerCase() : undefined,
    product_name: productName
      ? productName
          ?.replace(regexForStripHTML, '')
          .replace(regexForStripSymbols, '')
          .replace(regexForStripEncodingUnicode, '')
          .toLowerCase()
      : undefined,
    product_brand: productBrand ? productBrand?.toLowerCase() : undefined,
    product_variant: undefined,
    ...basePayloadOverrides,
  }

  const buildPayload =
    !eventAction && !eventLabel
      ? { payload: basePayload }
      : {
          eventType: GTMEventTypes.AnalyticsEvent,
          payload: {
            ...basePayload,
            eventCategory: 'antibody selector tool',
            eventAction,
            eventLabel,
            eventInteractionType: 0,
          },
        }

  sendEvent(buildPayload)
}

export const sendVideoInteractionEvent = ({
  action,
  videoCurrentTime,
  videoDuration,
  videoPercent,
  videoUrl,
  visible,
  productId,
  productName,
  productBrand,
}: {
  action: string
  videoCurrentTime: number
  videoDuration: number
  videoPercent: number
  videoUrl: string
  visible: boolean
  productId: string | undefined
  productName: string | undefined
  productBrand: string | null | undefined
}): void => {
  const videoUrlSection = videoUrl.split('/')
  sendEvent({
    payload: {
      event: 'ga4Event',
      event_name: `video_${action}`,
      video_current_time: videoCurrentTime,
      video_duration: videoDuration,
      video_percent: videoPercent,
      video_provider: videoUrlSection[1],
      video_title: videoUrlSection[videoUrlSection.length - 1],
      video_url: videoUrl,
      visible,
      product_id: productId?.toLowerCase() || undefined,
      product_name:
        productName
          ?.replace(regexForStripHTML, '')
          .replace(regexForStripSymbols, '')
          .replace(regexForStripEncodingUnicode, '')
          .toLowerCase() || undefined,
      product_brand: productBrand?.toLowerCase() || undefined,
    },
  })
}

export const sendWalkMeTracking = (
  tour: string,
  eventAction: string,
  eventLabel: string
): void =>
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: `walk me - ${tour}`,
      eventAction,
      eventLabel: eventLabel,
      eventInteractionType: 0,
    },
  })

export const analyticsEnTabName = (tabKey): DocumentLibraryTab | undefined => {
  switch (tabKey) {
    case 'RECENTLY_PURCHASED':
      return DocumentLibraryTab.RecentlyPurchase
    case 'RECENTLY_VIEWED':
      return DocumentLibraryTab.RecentlyViewed
    case 'YOUR_DOCUMENTS':
      return DocumentLibraryTab.YourDocuments
    case 'SAVED_PROTOCOLS':
      return DocumentLibraryTab.ProtocolsAndArticles
    default:
      return undefined
  }
}

export const sendErrorEvent = (
  ga4Payload: GA4ErrorEvent,
  legacyUAFields?: LegacyUAFields
): void => {
  const basePayload = {
    event: ga4Payload.event || 'exception',
    description: ga4Payload.description?.toLowerCase(),
    error_category: ga4Payload.errorCategory?.toLowerCase(),
    product_id: ga4Payload.productId?.toLowerCase() || undefined,
    product_brand: ga4Payload.productBrand?.toLowerCase() || undefined,
    product_variant: ga4Payload.productVariant?.toLowerCase() || undefined,
    product_name:
      ga4Payload.productName
        ?.replace(regexForStripHTML, '')
        .replace(regexForStripSymbols, '')
        .replace(regexForStripEncodingUnicode, '')
        .toLowerCase() || undefined,
    lot_number: ga4Payload.lotNumber,
    cart_type: ga4Payload.cartType,
  }

  const buildPayload = legacyUAFields
    ? {
        eventType: GTMEventTypes.AnalyticsEvent,
        payload: { ...basePayload, ...legacyUAFields },
      }
    : { payload: basePayload }

  sendEvent(buildPayload)
}

export const sendSignInInteractionEvents = ({
  action,
  section,
  element_type,
  element_text,
  link_url,
  component = 'card',
}: {
  action: string
  section: string
  element_type: string
  element_text: string
  link_url?: string
  component?: string
}): void =>
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: 'sign_in_interaction',
      event_name: 'scroll',
      eventAction: action,
      section,
      component,
      element_type,
      element_text,
      link_url,
      core_event: 'no',
      user_detail: undefined,
      event_group: undefined,
      event_subgroup: undefined,
    },
  })

export const sendAEMProductRecommendationsEvent = (
  ga4Payload: GA4EcommercePayload
): void => {
  const buildPayload = {
    event: 'view_promotion',
    ecommerce: ga4Payload,
  }
  sendEvent({ payload: buildPayload })
}

export const sendRegisterErrorEvent = (ga4Payload: GA4ErrorEvent): void => {
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      event: ga4Payload.event || 'exception',
      description: ga4Payload.description?.toLowerCase(),
      error_category: 'registration errors',
      product_id: undefined,
      product_brand: undefined,
      product_variant: undefined,
      product_name: undefined,
      lot_number: undefined,
      cart_type: undefined,
    },
  })
}
