import { Cookie as UniversalCookie, CookieSetOptions } from 'universal-cookie'
import { useCookies as useReactCookies } from 'react-cookie'
import getConfig from 'next/config'

const {
  publicRuntimeConfig: {
    featureFlags: { thirdPartyCookies },
  },
} = getConfig()

export enum AppCookies {
  HideSitewideMessages = 'hideSitewideMessages',
  AccessToken = 'accessToken',
  Country = 'country',
  Language = 'language',
  Store = 'store',
  LoggedIn = 'loggedIn',
  IsMarketplaceCatalogEnabled = 'isMarketplaceCatalogEnabled',
  IsBlueErpIntegrationEnabled = 'isBlueErpIntegrationEnabled',
  UserErpType = 'userErpType',
  HasOnlyBlueERP = 'hasOnlyBlueERP',
  IsDarmstadtUser = 'isDarmstadtUser',
  HideEmailVerifionModal = 'hideEmailVerificationModal',
  SSORedirectCookie = 'ssoRedirect',
  MMViewedTransactionIds = 'mmPreviousViewedTransactions', // De-duplication limiter for GA4-410
}

export enum OptlyCookies {
  OptlyFind391SRPVariation = 'optly-find391-SRPVariation',
}

enum CookieSameSite {
  Strict = 'strict',
  None = 'none',
}

export type Cookie = UniversalCookie
export type Cookies = { [name: string]: any }
export type SetCookie = (
  name: string,
  value: Cookie,
  options?: CookieSetOptions
) => void

export type RemoveCookie = (name: string, options?: CookieSetOptions) => void
export type CookiesHook = [Cookies, SetCookie, RemoveCookie]

export const defaultCookieOptions = {
  secure: !!thirdPartyCookies,
  sameSite: thirdPartyCookies ? CookieSameSite.None : CookieSameSite.Strict,
}

export const useCookies = (cookieList?: string[]): CookiesHook => {
  const [cookies, setCookie, removeCookie] = useReactCookies(cookieList)

  return [
    cookies,
    (name, value, options): void =>
      setCookie(name, value, {
        ...(options || {}),
        ...defaultCookieOptions,
      }),
    (name, options): void =>
      removeCookie(name, {
        ...(options || {}),
        ...defaultCookieOptions,
      }),
  ]
}
