import { CSSProperties } from '@material-ui/core/styles/withStyles'

// https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
export const fieldsetReset = (): CSSProperties => ({
  border: 0,
  padding: '0.01em 0 0 0',
  margin: 0,
  minWidth: 0,
})

// https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
export const legendReset = (): CSSProperties => ({
  padding: 0,
  display: 'table',
})
