import FormAssembly from '@src/components/FormAssembly'
import { MapTo } from '@adobe/aem-react-editable-components'
import { useCurrentUser } from '@utils/useCurrentUser'
import { useCookies } from '@utils/cookies'
import { sessionStorage } from 'react-storage'
import { DOCUMENT_REFERRER } from '@utils/analytics'
import { countries, states } from './formAssemblyMapping'

interface Props {
  formId: number
  formAssemblyUrl?: string
  anchorId?: string
  activatePrefill?: boolean
  formFields?: object
  isInEditor: boolean
  cqPath: string
}

const FormAssemblyAdapter: React.FC<Props> = (props) => {
  const { currentUser, userId, organizationPosition } = useCurrentUser()
  const [cookies] = useCookies(['_ga', '__gtm_campaign_url', 'GUID'])
  const isClient = typeof window !== 'undefined'

  let formFieldsMapping: object = {}
  // is AEM user prefill active?
  if (props.activatePrefill) {
    formFieldsMapping = {
      tfa_12: currentUser?.firstName,
      tfa_13: currentUser?.lastName,
      tfa_15: currentUser?.email,
      tfa_16: currentUser?.telephone || currentUser?.mobilePhone,
      tfa_18: currentUser?.organizationName,
      tfa_19: currentUser?.contactAddress?.postalCode,
      tfa_20: currentUser?.contactAddress?.city,
      tfa_2894:
        currentUser?.contactAddress?.street ||
        currentUser?.contactAddress?.postOfficeBox,
      tfa_2891: organizationPosition,
      tfa_4790: isClient && window.location.href,
      tfa_4813: isClient && sessionStorage.getItem(DOCUMENT_REFERRER),
      tfa_4820: currentUser?.soldToPartners?.[0]?.participantId,
      tfa_4854: currentUser?.soldToPartners?.[0]?.shipTo,
      tfa_4855: currentUser?.soldToPartners?.[0]?.soldTo,
      tfa_4857: currentUser?.soldToPartners?.[0]?.billTo,
    }

    // SET country
    const userCountry = currentUser?.contactAddress?.country
    if (userCountry && countries[userCountry]) {
      formFieldsMapping['tfa_2582'] = countries[userCountry]
    }

    // SET state
    const userState = currentUser?.contactAddress?.state
    if (userState && states[userState]) {
      formFieldsMapping['tfa_2820'] = states[userState]
    }

    // UTM parameters
    if (userId) {
      formFieldsMapping['tfa_4823'] = userId
    }
    formFieldsMapping['tfa_4896'] = cookies?.GUID?.split('|')[0]
    formFieldsMapping['tfa_4834'] = cookies?._ga
    const campaignUrl: string = cookies?.__gtm_campaign_url?.split('?')[1]
    formFieldsMapping['tfa_4907'] = campaignUrl?.substring(0, 255)
  }

  return <FormAssembly {...props} formFields={formFieldsMapping} />
}

export default MapTo<Props>('cms-commons/components/content/formAssembly')(
  FormAssemblyAdapter
)
