import React, { ReactNode, CSSProperties } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import FormHelperText from '@material-ui/core/FormHelperText'
import ErrorIcon from '@material-ui/icons/Error'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.grey[100],
      fontSize: theme.typography.pxToRem(14),
      margin: theme.spacing(2, 0, 0),
    },
    messageIcon: {
      fontSize: theme.typography.pxToRem(14),
    },
    messageText: {
      marginLeft: theme.spacing(1),
      marginTop: '1px', // Micro adjustment to get the text to sit inline with the icon
      fontWeight: theme.typography.fontWeightRegular,
    },
  })
)

interface LiquidFormHelpTextProps {
  children: ReactNode
  error?: boolean
  className?: string
  style?: CSSProperties
  id?: string
}

const LiquidFormHelpText: React.FC<LiquidFormHelpTextProps> = ({
  children,
  error,
  className,
  style,
  id,
}) => {
  const classes = useStyles()

  return (
    <FormHelperText
      id={id}
      classes={{ root: classes.root }}
      error={error}
      className={className}
      style={style}
    >
      {error ? (
        <Box component="span" display="flex">
          <ErrorIcon classes={{ root: classes.messageIcon }} />
          <Box component="span" className={classes.messageText}>
            {children}
          </Box>
        </Box>
      ) : (
        { children }
      )}
    </FormHelperText>
  )
}

export default LiquidFormHelpText
