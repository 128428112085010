import React from 'react'
import dynamic from 'next/dynamic'
import AEMTopNav from '@src/aem-content/components/TopNav'
import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { createStyles } from '@material-ui/core/styles'

const HeaderBottomRightNav = dynamic(() => import('./HeaderBottomRightNav'), {
  ssr: false,
})

//const classes = useStyles()
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerBottom: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
        width: '100%',
        position: 'relative',
        boxShadow: `0 3px 8px 0 rgba(0, 0, 0, 0.16)`,
        background: theme.palette.brand.main,
        color: theme.palette.common.white,
      },
      // a11y - focus states for all buttons
      // custom focus for better visibility
      '& button': {
        '&:focus': { outline: 'revert' },
      },
    },
    headerBottomNavWrapper: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(0, 20, 0, 16),
        display: 'flex',
        width: '100%',
        maxWidth: `1440px`,
        marginLeft: `auto`,
        marginRight: `auto`,
        height: theme.spacing(12),
      },
    },
  })
)

const HeaderBottom = () => {
  const classes = useStyles()
  return (
    <div className={classes.headerBottom}>
      <nav className={classes.headerBottomNavWrapper}>
        <AEMTopNav />
        {/* user account & cart */}
        <HeaderBottomRightNav />
      </nav>
    </div>
  )
}

export default HeaderBottom
