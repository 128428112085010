import React, { useEffect } from 'react'
import Head from 'next/head'
import { NextPage } from 'next'
import { Page } from '@src/aem-content/components'
import { PageProps } from '@src/aem-content/components/Page'
import { useCookies } from '@src/utils/cookies'
import DefaultLayout from '@src/components/DefaultLayout'
import ErrorPage from '@src/pages/_error'
import { decodePageTitle } from '@src/utils/decodeTitle'
import LocaleContext from '@sial/aem-cms-components/dist/_utils/LocaleContext'
import { EmbeddedSurvey } from './EmbeddedSurvey'
import { AemSeoSchema } from './AemSeoSchema/AemSeoSchema'
import { AemContextProvider } from '@src/aem-content/components/AemContext'
import OgMetaTags from '@src/aem-content/components/OgMetaTags'
import dynamic from 'next/dynamic'
import { useChinaUser } from '@utils/useChinaUser'
import { SeoTags } from '@utils/seo'

const MachineTranslationDisclaimer = dynamic(
  () => import('@src/aem-content/components/MachineTranslationDisclaimer'),
  { ssr: false }
)

export interface AemStaticContentProps extends PageProps {
  asPath: string
  language: string
  country: string
  error?: boolean
  basicError?: boolean
  metaDataObj: AemMetaTagsProps
  isAemFullPage: boolean
  isHomepage?: boolean
  className?: string
}

export interface AemMetaTagsProps {
  description: string
  keywords: string[]
  pageType: string
  robots: string[]
  title: string
  schemas?: object[]
  ogImageMetaInfo?: OgImageMetaInfo[]
  translationMethod?: string
  canonicalUrl?: string
  publishedDate?: number
  lastModifiedDate?: number
}

export interface OgImageMetaInfo {
  ogDescription?: string
  ogImage?: string
  ogTitle?: string
  ogType?: string
  ogUrl?: string
}

const AemStaticContent: NextPage<AemStaticContentProps> = (props) => {
  const [cookies] = useCookies(['country', 'language'])

  const isChinaUser = useChinaUser()

  const ChinaWelcomeDialog = dynamic(
    () => import('@src/components/WelcomeDialog'),
    { ssr: false }
  )

  useEffect(() => {
    const handleHashLinkScroll = () => {
      const hashId = window.location.hash
      if (hashId.length) {
        let elementId = hashId.substring(1)
        //to handle URLs of pattern /US/en/<static_page_URL>#hashLink?test=sial
        if (elementId.includes('?')) {
          elementId = elementId.split('?')[0]
        }
        const element = document.querySelector(
          `[id="${elementId}"]`
        ) as HTMLElement
        if (element) {
          const scrollTopPos = element?.offsetTop - element?.offsetHeight
          window.scrollTo({
            top: scrollTopPos,
            left: 0,
            behavior: 'smooth',
          })
        }
      }
    }
    window.addEventListener('load', handleHashLinkScroll)
    return () => {
      window.removeEventListener('load', handleHashLinkScroll)
    }
  }, [])

  // Prep Global 404 component
  const errorPageComponent = <ErrorPage statusCode={404} {...props} />

  // If no path found, return Global 404 component...
  if (!props.asPath) {
    return errorPageComponent
  }
  // Else if Custom PDP, return null to proceed...
  if (props.asPath.includes('custom-pdp') && !props[':items']) {
    return null
  }
  // Else if error...
  if (props.error) {
    // If SDS page return SDS 404 component, else return Global 404 component
    if (props.asPath.includes('/sds/')) {
      return <ErrorPage statusCode={404} title={props.asPath} {...props} />
    }
    return errorPageComponent
  }

  const AemPageHead = () => {
    const metaDataObj = props.metaDataObj || {}
    const { isAemFullPage, isHomepage, asPath } = props
    const {
      description,
      keywords,
      pageType,
      robots,
      title,
      schemas = [],
      ogImageMetaInfo,
      canonicalUrl,
      publishedDate,
      lastModifiedDate,
    } = metaDataObj
    const lastSegment = asPath.split('/').pop()
    const isNewHomepage = lastSegment === 'home' || false

    const published = publishedDate ? new Date(publishedDate).toISOString() : ''
    const lastModified = lastModifiedDate
      ? new Date(lastModifiedDate).toISOString()
      : ''

    return (
      <>
        <Head>
          <meta property="cq:pagemodel_router" content="disable" />
          {/* TODO: follow-up on requirement clarifications, it's odd to see a plain title, w/ missing branding */}
          {title && <title>{decodePageTitle(title)}</title>}
          {description && <meta name="description" content={description} />}
          {keywords && keywords.length && (
            <meta name="keywords" content={keywords.join(', ')} />
          )}
          {pageType && <meta name="pagetype" content={pageType} />}
          {robots && robots.length && (
            <meta name="robots" content={robots.join(',')} />
          )}
          {publishedDate && <meta name="publishedDate" content={published} />}
          {lastModifiedDate && (
            <meta name="lastModifiedDate" content={lastModified} />
          )}
        </Head>
        {schemas && schemas.length > 0 && <AemSeoSchema schemas={schemas} />}
        <OgMetaTags
          title={title && decodePageTitle(title)}
          description={description}
          ogImageMetaInfo={ogImageMetaInfo}
        />
        {!isHomepage && isAemFullPage && (
          <SeoTags
            // We are scrubbing all params for AEM pages
            canonicalParamAllowed={() => false}
            isCMSRoute
            isNewHomepage={isNewHomepage}
            canonicalUrl={canonicalUrl}
          />
        )}
      </>
    )
  }

  const AemPage = () => {
    const translationMethodText = props?.metaDataObj?.translationMethod
    return (
      <>
        {!props.asPath || !props.locationPathname ? (
          <span />
        ) : (
          <AemContextProvider>
            <Page
              cqChildren={props[':children']}
              cqItems={props[':items']}
              cqItemsOrder={props[':itemsOrder']}
              locationPathname={props.locationPathname}
              cqPath={props.cqPath}
              aemHost={props.aemHost}
              pageFooter={props.isAemFullPage && <EmbeddedSurvey />}
              isInEditor={false}
              className={props?.className}
            />
            {translationMethodText === 'MACHINE_TRANSLATION' && (
              <MachineTranslationDisclaimer />
            )}
            {props.isHomepage && isChinaUser && <ChinaWelcomeDialog />}
          </AemContextProvider>
        )}
      </>
    )
  }
  // This version assumes a parent has already loaded the header and footer.
  if (!props.isAemFullPage && !props.isHomepage) {
    return (
      <LocaleContext.Provider
        value={{ country: cookies.country, language: cookies.language }}
      >
        <AemPageHead />
        <AemPage />
      </LocaleContext.Provider>
    )
  }

  // This version loads the header and footer.
  return (
    <LocaleContext.Provider
      value={{ country: cookies.country, language: cookies.language }}
    >
      <DefaultLayout>
        <AemPageHead />
        <AemPage />
      </DefaultLayout>
    </LocaleContext.Provider>
  )
}

export default AemStaticContent
