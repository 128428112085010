import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { Typography, Button } from '@material-ui/core'
import { ProductDetailFragment } from '@src/fragments/ProductDetail.generated'
import { SubstanceFieldsFragment } from '@src/queries/ProductSearchQuery.generated'
import { Brand, ProductAttribute, CatalogType } from '@src/types/graphql-types'
import { usePricingAndAvailabilityLazyQuery } from '@src/queries/PricingAndAvailabilityQuery.generated'
import { ProductPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { useMultiCountryPricingAndAvailabilityLazyQuery } from '@src/queries/MultiCountryPricingAndAvailabilityQuery.generated'
import HandleMarkup from '@src/components/HandleMarkup'
import { FormattedMessage } from 'react-intl'
import vrStyles from '@src/styles/utils/vrStyles'
import ResponsiveModal, { ModalSizes } from '@src/components/ResponsiveModal'
import MobileProductPriceAvailability from '@src/components/ProductPriceAvailability/MobileProductPriceAvailability'
import { useCurrentUser, SitePreference } from '@utils/useCurrentUser'
import { Abilities, useAbility } from '@utils/useAbility'
import { Link } from '@src/components/Link'
import { productDetailRoute, useRouter } from '@src/routes'
import { determineCatalogType, getSellerName } from '@utils/searchUtils'
import CircleLoader from '@src/components/CircleLoader/CircleLoader'
import ProductPriceAvailabilityError from '@src/components/ProductPriceAvailability/ProductPriceAvailabilityError'
import ProductPaMessage from '../ProductPriceAvailability/ProductPaMessage'
import { useChinaUser } from '@src/utils/useChinaUser'

const { vr1, vr2 } = vrStyles

export const useStyles = makeStyles((theme: Theme) => {
  return {
    vr2: {
      ...vr2,
    },
    header: {
      paddingBottom: theme.spacing(6),
    },
    substance: {
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      ...vr1,
      '& sup': {
        fontSize: theme.typography.pxToRem(12),
      },
    },
    description: {
      fontSize: theme.typography.pxToRem(12),
    },
    body: {
      borderTop: `solid 1px ${theme.palette.grey[400]}`,
      overflowY: 'scroll',
    },
    viewDetailsWrapper: {
      marginTop: theme.spacing(4),
    },
    truncateText: {
      width: 'calc(100% - 16px)',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    alternateStatesWrapper: {
      padding: theme.spacing(4),
    },
  }
})

interface ProductProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  substanceName?: SubstanceFieldsFragment['name']
  productNumber: ProductDetailFragment['productNumber']
  productKey: ProductDetailFragment['productKey']
  brandKey: Brand['key']
  description: ProductDetailFragment['description']
  materialIds?: string[]
  isMarketplace?: boolean
  marketplaceSellerId?: string
  marketplaceOfferId?: string
  productAttributes?: ProductAttribute[]
  erp_type?: string[] | null
  productName?: string
  displaySellerName?: string
  pricingData?: ProductPricingDetailFragment
  paMessage?: string | null
}

const ResponsiveProductPAModal: React.FC<ProductProps> = ({
  onClose,
  substanceName,
  productNumber,
  productKey,
  brandKey,
  description,
  materialIds,
  isMarketplace = false,
  marketplaceSellerId,
  marketplaceOfferId,
  productAttributes,
  erp_type,
  productName,
  displaySellerName,
  pricingData,
  paMessage,
}) => {
  const classes = useStyles()
  const {
    getSitePreference,
    isDTAQZuCustomer,
    isMultiCountryUser,
    currentUser,
    isBlueErpIntegrationEnabled,
  } = useCurrentUser()
  const isChinaUser = useChinaUser()
  const router = useRouter()
  const { focus = '', catalog = '' } = router.query || {}
  const orgId = getSitePreference(SitePreference.CatalogFilter)
  const { authorized } = useAbility(Abilities.canAddToCart)
  const marketplace = isMarketplace ? CatalogType.Marketplace : null
  const passERPType =
    isBlueErpIntegrationEnabled && erp_type && erp_type?.length > 0
  const preferredDealerId = isDTAQZuCustomer
    ? getSitePreference(SitePreference.PreferredDealerId)
    : null
  const [materialPricing, setMaterialPricing] = useState(
    pricingData?.materialPricing
  )

  const [
    getPricingForProducts,
    { data: productData, loading: productLoading, error: productError },
  ] = usePricingAndAvailabilityLazyQuery()

  const [
    getPricingForMultiCountryProducts,
    {
      data: multiCountryData,
      loading: multiCountryLoading,
      error: multiCountryError,
    },
  ] = useMultiCountryPricingAndAvailabilityLazyQuery()

  const onCloseModal = () => {
    onClose(false)
  }

  const isLoading = multiCountryLoading || productLoading
  const hasErrors = multiCountryError || productError

  const fetchPricingData = async () => {
    const variables = {
      ...(passERPType && { erp_type }),
      brand: brandKey,
      catalogType: determineCatalogType(marketplace || focus || catalog),
      checkBuyNow: true,
      checkForPb: !isChinaUser,
      dealerId: preferredDealerId,
      displaySDS: false,
      materialIds,
      orgId,
      productKey,
      productNumber,
      quantity: 1,
    }
    isMultiCountryUser
      ? await getPricingForMultiCountryProducts({ variables })
      : await getPricingForProducts({ variables })

    const pricing = isMultiCountryUser
      ? multiCountryData?.getPricingForMultiCountryProducts[0]?.materialPricing
      : productData?.getPricingForProduct?.materialPricing

    setMaterialPricing(pricing)
  }

  useEffect(() => {
    if (pricingData && !isMultiCountryUser) {
      setMaterialPricing(pricingData?.materialPricing)
    } else {
      !paMessage && fetchPricingData()
    }
  }, [pricingData, multiCountryData, isMultiCountryUser, paMessage])

  return (
    <ResponsiveModal
      open
      onClose={onCloseModal}
      size={ModalSizes.Large}
      renderTitle={() => (
        <div className={classes.header}>
          <Typography component="h2" variant="h2" className={classes.vr2}>
            {productNumber}
          </Typography>
          <Typography
            component="div"
            className={clsx(classes.substance, classes.truncateText)}
          >
            <HandleMarkup value={substanceName} />
          </Typography>
          <Typography
            component="div"
            className={clsx(classes.description, classes.truncateText)}
          >
            <HandleMarkup value={description} />
          </Typography>
        </div>
      )}
    >
      <div className={classes.body}>
        {paMessage ? (
          <ProductPaMessage
            productNumber={productNumber}
            brandKey={brandKey}
            paMessage={paMessage}
          />
        ) : materialPricing?.length ? (
          <MobileProductPriceAvailability
            productName={productName}
            materialPricing={materialPricing}
            multiCountryData={multiCountryData}
            canAddToCart={
              authorized &&
              !isMultiCountryUser &&
              (!isMarketplace ||
                !!currentUser?.metadata?.isMarketplaceCartEnabled)
            }
            marketplaceOfferId={
              isMarketplace &&
              materialPricing &&
              materialPricing[0]?.marketplaceOfferId
                ? materialPricing[0]?.marketplaceOfferId
                : marketplaceOfferId
            }
            renderSecondaryAction={() => (
              <div className={classes.viewDetailsWrapper}>
                <Link
                  {...productDetailRoute.index(brandKey, productKey, {
                    catalog: determineCatalogType(focus),
                  })}
                  passHref
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth
                  >
                    <FormattedMessage
                      id="VIEW_PRODUCT_DETAILS"
                      defaultMessage="View Product Details"
                    />
                  </Button>
                </Link>
              </div>
            )}
            erpType={erp_type}
          />
        ) : null}

        {(isLoading || hasErrors) && (
          <div className={classes.alternateStatesWrapper}>
            {isLoading && <CircleLoader />}

            {hasErrors && (
              <ProductPriceAvailabilityError
                error={multiCountryError || productError}
                productNumber={productNumber}
                isMarketplace={isMarketplace}
                sellerName={
                  displaySellerName || getSellerName(productAttributes)
                }
                marketplaceSellerId={marketplaceSellerId}
                marketplaceOfferId={marketplaceOfferId}
              />
            )}
          </div>
        )}
      </div>
    </ResponsiveModal>
  )
}

export default ResponsiveProductPAModal
