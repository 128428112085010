import React from 'react'
import { Box, Grid, useMediaQuery } from '@material-ui/core'
import {
  makeStyles,
  createStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      display: 'inline-block',
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down('xs')]: {
        width: 120,
      },
    },
    button: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    headline: {
      margin: theme.spacing(10, 0),
    },
    image: {
      [theme.breakpoints.down('xs')]: {
        width: 120,
      },
    },
  })
)

interface SkeletonProps {
  count?: number
  xsCount?: number
}
const SkeletonLoader: React.FC<SkeletonProps> = ({
  count = 2,
  xsCount = 1,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  return (
    <>
      <div>
        <Skeleton
          variant="text"
          width="100%"
          height={20}
          className={classes.headline}
        />
      </div>
      <Box mt={6}>
        <Grid container>
          {Array(isMobile ? xsCount : count)
            .fill({})
            .map((_ignored, i) => (
              <Grid item sm={count === 2 ? 6 : 3} xs={12} key={i}>
                <Grid container>
                  <Grid item sm={6} xs={6}>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={120}
                      className={classes.image}
                    />
                  </Grid>
                  <Grid item sm={6} xs={6}>
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={20}
                      className={classes.text}
                    />
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={20}
                      className={classes.text}
                    />
                    <Skeleton
                      variant="text"
                      width="85%"
                      height={20}
                      className={classes.text}
                    />
                  </Grid>
                </Grid>
                <Skeleton
                  variant="rect"
                  width="95%"
                  height={40}
                  className={classes.button}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </>
  )
}

export default SkeletonLoader
