import React, { useContext } from 'react'
import { useLockBodyScroll } from 'react-use'
import { FormattedMessage } from 'react-intl'
import clsx from 'clsx'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme, Button, Typography } from '@material-ui/core'
import { loginRoute, registerRoute, homeRoute, useRouter } from '@src/routes'
import { Link } from '@src/components/Link'
import { AemContext } from '@src/aem-content/components/AemContext'
import messages from '@utils/messages'
import aemMessages from '@src/aem-content/messages'
import { sendGatedContentEvent } from '@src/aem-content/analytics'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    gatedContent: {
      backgroundColor: theme.palette.common.white,
      borderTop: `1px solid ${theme.palette.grey[500]}`,
      bottom: '100%',
      fontSize: theme.typography.pxToRem(14),
      left: 0,
      maxHeight: '70vh',
      overflowY: 'auto',
      position: 'fixed',
      width: '100%',
      zIndex: 0,
      '@media print': {
        display: 'none',
      },
    },
    gatedContentActive: {
      bottom: '0',
    },
    wrapper: {
      margin: '0 auto',
      padding: '30px 20px 40px',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '340px',
        padding: '20px 0 40px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '50px 0 100px',
      },
    },
    signInTitle: {
      display: 'block',
      marginBottom: '5px',
    },
    signInDescription: {
      margin: 0,
    },
    registerTitle: {
      display: 'block',
      marginTop: '30px',
    },
    button: {
      width: '100%',
      marginTop: '15px',
    },
  })
})

const HalfGatedContent = () => {
  const classes = useStyles()
  const router = useRouter()
  const { isHalfGatedContentActive } = useContext(AemContext)

  useLockBodyScroll(isHalfGatedContentActive)

  return (
    <section
      className={clsx(classes.gatedContent, {
        [classes.gatedContentActive]: isHalfGatedContentActive,
      })}
    >
      <div className={classes.wrapper}>
        <Typography
          component="strong"
          variant="h1"
          className={classes.signInTitle}
        >
          <FormattedMessage {...aemMessages.SIGN_IN_TO_CONTINUE} />
        </Typography>
        <p className={classes.signInDescription}>
          <FormattedMessage {...aemMessages.GATED_CONTENT_INSTRUCTIONS} />
        </p>
        <Link
          href={`${loginRoute.index()}?redirect=${encodeURIComponent(
            router.asPath || homeRoute.index()
          )}`}
        >
          <Button
            size="large"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={() => sendGatedContentEvent('sign in')}
          >
            <FormattedMessage id="SIGN_IN" defaultMessage="Sign In" />
          </Button>
        </Link>

        <Typography
          variant="h3"
          component="strong"
          className={classes.registerTitle}
        >
          <FormattedMessage {...messages.DO_NOT_HAVE_AN_ACCOUNT} />
        </Typography>
        <Link
          href={`${registerRoute.index()}?redirect=${encodeURIComponent(
            router.asPath || homeRoute.index()
          )}`}
        >
          <Button
            size="large"
            variant="outlined"
            color="primary"
            className={classes.button}
            onClick={() => sendGatedContentEvent('register')}
          >
            <FormattedMessage id="REGISTER_TITLE" defaultMessage="Register" />
          </Button>
        </Link>
      </div>
    </section>
  )
}

export default HalfGatedContent
