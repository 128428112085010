import React, { FC, useState } from 'react'
import {
  FacetChangeAction,
  useSearchQuery,
} from '@src/pages/[country]/[language]/search/[searchTerm]'
import { Facet } from '@src/types/graphql-types'
import { sendSearchFacetEvent } from '@src/utils/analytics'
import { ButtonBase, Checkbox, FormControlLabel } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'
import HandleMarkup from '@src/components/HandleMarkup'
import { FormattedMessage, useIntl } from 'react-intl'
import messages from '../messages'
import vrStyles from '@src/styles/utils/vrStyles'
import CaretDownIcon from '@src/icons/CaretDownIcon'
import clsx from 'clsx'
import { ShippingTypes } from '@src/utils/searchUtils'
import ShipsTodayIcon from '@src/icons/ShipsTodayIcon'
import FreeFreightIcon from '@src/icons/FreeFreightIcon'
import CheckMarkIcon from '@src/icons/CheckMarkIcon'

const { vr2, vr4 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  vr2,
  vr4,
  labelRoot: { marginLeft: 0 },
  checkboxRoot: {
    padding: theme.spacing(1, 2, 1, 0),
  },
  shippingIcon: {
    fontSize: theme.typography.pxToRem(24),
    marginLeft: theme.spacing(2),
  },
  showMore: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
  },
  checkboxIcon: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 3,
    width: 24,
    height: 24,
    fontSize: theme.typography.pxToRem(4),
  },
  checkboxIconChecked: {
    '& svg': {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  icon: {
    fontSize: theme.typography.pxToRem(10),
    color: theme.palette.primary.main,
    transition: 'transform 0.25s',
    marginLeft: theme.spacing(2),
  },
  iconIsOpen: {
    transform: 'rotate(180deg)',
  },
}))

interface Props {
  facet: Facet
  allFacets: Facet[]
}

const MobileSortFacets: FC<Props> = ({ facet, allFacets }) => {
  const classes = useStyles()
  const intl = useIntl()
  const [query, selectedFacets, queryActions] = useSearchQuery()
  const selectedOptions: string[] = (
    selectedFacets.find(({ key }) => key === facet.key) || { options: [] }
  ).options

  const [showAll, setShowAll] = useState(false)
  const iconClasses = clsx(classes.icon, {
    [classes.iconIsOpen]: showAll,
  })

  const visibleFacets = showAll
    ? facet.options
    : facet.options.slice(0, facet.numToDisplay)

  const isShippingFacet = facet.key === 'facet_shipping'
  const shippingIconToDisplay = (facetValue: string): JSX.Element | null => {
    switch (facetValue) {
      case ShippingTypes.SameDay:
        return <ShipsTodayIcon color="secondary" fontSize="inherit" />
      case ShippingTypes.FreeFreight:
        return <FreeFreightIcon />
      default:
        return null
    }
  }

  // some facets require a prefix to be added to the option in the query (facet_shipping:US/free_ship),
  // we need to remove the prefix to match values on the checkboxes.
  // A handful of facets currently have a / so we filter those out to leave them intact
  // (Genomics / DNA and RNA purification) These are actually nested facets.
  // When filtering, we also make sure to check there is not an HTML tag, such as
  // Aldrich <sup>CPR</sup>
  const selectedOptionsNoPrefix = selectedOptions.map((option) => {
    const optionHasNoPrefix = option.includes(' / ')
    const optionHasTag = option.includes('</')
    const index = option.indexOf('/')
    if (optionHasNoPrefix || optionHasTag) {
      return option
    } else if (index > -1) {
      return option.slice(index + 1)
    } else return option
  })

  return (
    <div>
      <div className={classes.vr4}>
        {visibleFacets.map((option) => {
          const checked = selectedOptionsNoPrefix.indexOf(option.value) >= 0

          return (
            <div className={classes.vr2} key={option.value}>
              <FormControlLabel
                classes={{ root: classes.labelRoot }}
                checked={checked}
                control={
                  <Checkbox
                    data-testid={`srp-facet-${option.value}`}
                    id={option.value}
                    value={option.value}
                    color="primary"
                    classes={{
                      root: classes.checkboxRoot,
                    }}
                    icon={<span className={classes.checkboxIcon} />}
                    checkedIcon={
                      <span
                        className={clsx(
                          classes.checkboxIcon,
                          classes.checkboxIconChecked
                        )}
                      >
                        <CheckMarkIcon />
                      </span>
                    }
                    onClick={() => {
                      queryActions.handleSelectedFacetChange({
                        key: facet.key,
                        options: [option.value],
                        action: checked
                          ? FacetChangeAction.Remove
                          : FacetChangeAction.Add,
                        multiSelect: facet.multiSelect,
                        prefix: facet.prefix,
                      })
                      sendSearchFacetEvent(
                        query.term || '',
                        facet.key,
                        option.value,
                        !checked,
                        selectedFacets,
                        allFacets
                      )
                    }}
                  />
                }
                label={
                  <>
                    <HandleMarkup value={`${option.value} (${option.count})`} />
                    {isShippingFacet && (
                      <span className={classes.shippingIcon}>
                        {shippingIconToDisplay(option.value)}
                      </span>
                    )}
                  </>
                }
              />
            </div>
          )
        })}
      </div>

      {/* Show More */}
      {(facet.options.length > visibleFacets.length || showAll) && (
        <ButtonBase
          onClick={() => setShowAll(!showAll)}
          aria-expanded={showAll}
          aria-label={
            showAll
              ? intl.formatMessage(messages.SHOW_LESS)
              : intl.formatMessage(messages.SHOW_MORE)
          }
          className={classes.showMore}
        >
          {showAll ? (
            <FormattedMessage {...messages.SHOW_LESS} />
          ) : (
            <FormattedMessage {...messages.SHOW_MORE} />
          )}

          <CaretDownIcon className={iconClasses} />
        </ButtonBase>
      )}
    </div>
  )
}

export default MobileSortFacets
