/**
 * TODO: handle locale basename for URLs:
 *
 * When the server starts:
 * - Create server route for all combinations of supported languages + pages
 *
 * When a request comes in:
 * - Determine the user's locale
 * - Send that locale to the FE via the request...
 *
 * On the FE:
 * - Use the locale as a prefix for all navigation!
 */

import NextRouter from 'next/router'
import qs from 'qs'
import getConfig from 'next/config'
import useLocalizedRouter from './utils/useLocalizedRouter'
import { convertToSeoFriendly } from './utils/seo'
import {
  CatalogType,
  ContractType,
  SharedList,
  RecentOrderItem,
} from '@src/types/graphql-types'
import { obfuscateCatalogID } from '@utils/catalogKeyIds'
import { isEmpty } from 'lodash'
import DOMPurify from 'isomorphic-dompurify'

const {
  publicRuntimeConfig: {
    featureFlags: {
      semiConfigurators,
      fullRegistration,
      stratosMultiRegion,
      chromatogramSearch,
      milliplexRedesign,
    },
  },
} = getConfig()

export const Router = NextRouter
export const useRouter = useLocalizedRouter

export interface BuyItAgainQuery {
  sort?: keyof RecentOrderItem
  page?: string
  material?: string
}

export interface SharedListsQuery {
  sort?: keyof SharedList
  page?: string
  orderby?: 'ASC' | 'DESC'
}
export interface DocLibQuery {
  page?: string
}
export const homeRoute = {
  index: () => `/`,
}

export const dataSharingRoute = {
  index: () => `/data-sharing`,
}

export const productDetailRoute = {
  index: (
    brand: string,
    productKey: string,
    query?: { [key: string]: string | string[] },
    anchor?: string
  ) => {
    if (query && 'catalog' in query) {
      // default product page, removing context query
      if (query.catalog && query.catalog !== CatalogType.Sial) {
        query.context = obfuscateCatalogID(query.catalog as CatalogType)
      }
      delete query.catalog
    }
    const hasQuery = !isEmpty(query)
    const brandInLowercase = brand ? brand.toLowerCase() : ''
    const productKeyTerm = productKey ? encodeURIComponent(productKey) : ''

    //Build a base path to the PDP with the bare minimum data needed to reach a product. Attach params from there.
    let pdpPath = `/product/${brandInLowercase}/${productKeyTerm}`

    if (hasQuery) {
      pdpPath += `?${qs.stringify(query)}`
    }

    if (anchor) {
      pdpPath += `#${anchor}`
    }

    return {
      as: pdpPath,
      href: {
        pathname: '/product/[brand]/[productKey]',
        query,
      },
    }
  },
  compare: () => '/product/compare',
}

export const sdsRoute = {
  index: (
    brand: string,
    prodNumber: string,
    country: string,
    language: string,
    userErpType: string
  ) => {
    return `/${country?.toUpperCase()}/${language?.toLowerCase()}/sds/${brand?.toLowerCase()}/${prodNumber?.toLowerCase()}?userType=${userErpType.toLowerCase()}`
  },
}

export const searchResultsRoute = {
  index: () => '/search',
  searchResults: (searchTerm?: string) =>
    `/search/${
      searchTerm
        ? encodeURIComponent(
            searchTerm
              .trim()
              .replace(/[\s/\\|]+/g, '-')
              .toLowerCase()
          )
        : ''
    }`,
}

export const structureSearchDrawRoute = {
  index: () => '/structure-search',
  noResults: () => '/structure-search/no-results',
}

export const dealerTermsAndConditionsRoute = {
  index: () => '/life-science/legal/terms-and-conditions-dealercart',
}

export const orderCenterRoutes = {
  index: () => '/order-center',
  orders: () => '/order-center/orders',
  orderDetail: (orderId: string) => `/order-center/orders/${orderId}`,
  orderReturn: (returnId: string) => `/order-center/order-return/${returnId}`,
  orderCancel: (orderNumber: string, orderId: string) =>
    `/order-center/order-cancel/${orderNumber}/${orderId}`,
  orderSearch: () => {
    return '/order-center/order-search'
  },
  searchReturn: () => `${Router.query.returnUrl}`,
  quotes: () => '/order-center/quotes',
  createQuote: () => '/order-center/quotes/create-quote',
  quoteDetail: (quoteId: string) => `/order-center/quotes/${quoteId}`,
  quoteSearch: () => '/order-center/quote-search',
  approveOrders: () => '/order-center/approve-orders',
  consolidateCarts: () => '/order-center/consolidate-carts',
  orderLookUpDetail: (orderNumber: string) => `/order-lookup/${orderNumber}`,
  creditCardPayment: (fromDate: string, toDate: string, soldTo: string) => {
    return `/order-center/credit-card-payment?fromDate=${fromDate}&toDate=${toDate}&soldTo=${soldTo}`
  },
  creditCardPaymentIndex: () => {
    return `/order-center/credit-card-payment`
  },
  paymentHistoryIndex: () => {
    return `/order-center/payment-history`
  },
  mpayPaymentSuccess: () => {
    return `/order-center/mpay/success`
  },
  mpayPaymentFailure: () => {
    return `/order-center/mpay/failure`
  },
  creditCardPaymentCompleted: (soldTo: string) =>
    `/order-center/credit-card-payment/completed?customerNumber=${soldTo}`,
  paymentHistory: (fromDate: string, toDate: string, soldTo: string) => {
    return `/order-center/payment-history?fromDate=${fromDate}&toDate=${toDate}&soldTo=${soldTo}`
  },
  savedCarts: () => '/order-center/saved-carts',
  dealerRequests: () =>
    '/order-center/dealer-requests?activeLink=dealer-requests',
  fapiaoSearch: () => '/order-center/fapiao-search',
  buyItAgain: (query?: BuyItAgainQuery) => {
    const additionalParams = query ? `?${qs.stringify(query)}` : ''

    return `/order-center/buy-it-again${additionalParams}`
  },
  lists: (query?: SharedListsQuery) => {
    const additionalParams = query ? `?${qs.stringify(query)}` : ''

    return `/order-center/lists${additionalParams}`
  },
  listDetail: (listId: string) => `/order-center/lists/${listId}`,
  invoices: () => '/order-center/invoices',
  invoicePayment: () => `/order-center/invoices/invoice-payment`,
  invoiceList: () => `/order-center/invoice-list`,
  dashboard: () => `/order-center/account`,
  fedexOrderTracking: (
    orderNumber: string,
    trackingId: string,
    shipToZip: string
  ) => `/order-center/orders/${orderNumber}/${trackingId}/${shipToZip}`,
  orderTracking: (
    orderNumber: string,
    trackingId: string,
    shipToZip: string,
    carrierId: string
  ) =>
    `/order-center/orders/${orderNumber}/${trackingId}/${shipToZip}/${carrierId}`,
}

export const quickOrderRoute = {
  index: () => '/quick-order',
}

export const orderLookupRoute = {
  index: () => '/order-lookup',
}

export const loginRoute = {
  index: () => '/login',
}

export const registerRoute = {
  index: () => '/register',
  linkWechat: () => `/register/link-wechat`,
  linkProfile: () => '/register/link-profile',
  /** This feature is in active development as part of
   * Full Registration, Can be removed when all are complete
   */

  fullRegistration: () =>
    fullRegistration ? '/register/full-registration' : '/404',

  pipelineWelcome: () => '/register/pipeline-welcome',
  welcome: () => '/register/welcome',
}

export const profileRoutes = {
  index: () => '/profile',
  personalInfo: () => '/profile/personal-info',
  contactInfo: () => '/profile/personal-info/contact-info',
  changePassword: () => '/profile/personal-info/change-password',
  addressBook: () => '/profile/address-book',
  mailingAddress: () => '/profile/address-book/mailing-address',
  shippingAddress: () => '/profile/address-book/shipping-address',
  billingAddress: () => '/profile/address-book/billing-address',
  sitePreferences: () => '/profile/site-preferences',
  emailPreferences: () => '/profile/email-preferences',
  paymentMethods: () => '/profile/payment-methods',
  orderApprovalsManageRequesters: () =>
    '/profile/order-approvals/approver/manage/requesters',
  orderApprovalRequest: () =>
    '/life-science/ecommerce/order-approvals-advanced-ordering-process',
  delegateApprovals: () =>
    '/profile/order-approvals/approver/manage/delegate-my-approvals',
  buyCard: () => '/profile/payment-methods/buy-card',
  refillCard: () => '/profile/payment-methods/refill-card',
  registerBlanketPo: () => '/profile/payment-methods/register-blanket-po',
  transactions: (contractType: ContractType) =>
    `/profile/payment-methods/transactions?paymentType=${contractType}`,
  creditCard: () => '/profile/payment-methods/credit-card',
  nonCreditCard: () => '/profile/payment-methods/non-credit-card',
  requesterManageOrderApprovals: () =>
    '/profile/order-approvals/requester/view-requester',
  myNetwork: {
    index: (tab?: string) => {
      const basePath = '/profile/my-network'
      return `${basePath}?tab=${tab ? tab : 'coworkers'}`
    },
    editCoworker: (coworkerId) => ({
      as: `/profile/my-network/coworkers/edit/${coworkerId}`,
      href: `/profile/my-network/coworkers/edit/[coworkerId]`,
    }),
    createGroup: () => '/profile/my-network/groups/create',
    editGroup: (groupId: string) => ({
      as: `/profile/my-network/groups/edit/${groupId}`,
      href: `/profile/my-network/groups/edit/[groupId]`,
    }),
  },
  orderApprovals: {
    index: () => '/profile/order-approvals',
    becomeRequester: () =>
      '/profile/order-approvals/requester/become-requester',
    delegateApprovals: () =>
      '/profile/order-approvals/approver/manage/delegate-my-approvals',
    manageRequesters: () =>
      '/profile/order-approvals/approver/manage/requesters',
    approverIndex: () => '/profile/order-approvals/approver',
  },
}

export const profileForgotPinRoute = {
  index: (contractType: ContractType) =>
    `/profile/payment-methods/forgot-pin?paymentType=${contractType}`,
}

export const forgotPasswordRoute = {
  index: () => '/forgot-password',
}

export enum CartView {
  Cart = 'cart',
  CustomerSearch = 'customer_search',
  SelectAccount = 'select_account',
  SelectAddresses = 'select_addresses',
  SelectAddressesQuote = 'select_addresses_quote',
  Checkout = 'checkout',
  QuoteCheckout = 'quote_checkout',
  OrderAcknowledgement = 'order_acknowledgement',
  QuoteConfirmation = 'quote_confirmation',
  Inspect = 'inspect',
  EmailCart = 'email_cart',
  SaveForLater = 'save_for_later',
}

export enum ActiveMiniCartType {
  SIAL = 'sial',
  MARKETPLACE = 'marketplacecart',
  BLUECART = 'bluecart',
}

export enum CartType {
  FTBCart = 'ftbcart', //red ftbcart
  Active = 'active', //red cart
  SavedCart = 'savedcart', //red savedcart
  MarketplaceSavedCart = 'marketplaceSavedCart',
  DealerCart = 'dealercart',
  ContractCart = 'contractcart',
  MarketplaceCart = 'marketplacecart',
  Orc = 'orc',
  OrcSavedCart = 'orcsavedcart',
  Quotefullyconvertcart = 'quotefullyconvertcart',
  SaveForLater = 'saveforlater',
  BuyNowCart = 'buyitnowcart',
  MarketplaceBuyNowCart = 'marketplacebuyitnowcart',
  BuyNowCartMini = 'buyitnowcartmini',
  ApproverCart = 'approvercart',
  BlueCart = 'bluecart',
  BlueSavedCart = 'bluesavedcart',
  BlueFTBCart = 'blueftbcart',
  Emerald = 'emerald',
  EmeraldSavedCart = 'emeraldsavedcart',
  EmeraldFTBCart = 'emeraldftbcart',
  Iscala = 'iscala',
  Phoenix = 'phoenix',
}

export enum EndUserCartType {
  DealerEnduserCart = 'dealerendusercart',
  DealerFtbCart = 'dealerftbcart',
}

export enum CartFddLevel {
  Header = 'HEADER',
  Item = 'ITEM',
}

export enum ContactSellerReasonType {
  'ORDER_MESSAGING',
  'OFFER_MESSAGING',
  'INCIDENT_OPEN',
  'SELLER_MESSAGING',
}

export enum erpSystemTypes {
  Stn = 'STN',
  Prd = 'PRD',
  Iscala = 'ISCALA',
  Qrp = 'QRP',
}

export const cartRoute = {
  [CartView.Cart]: (type: CartType = CartType.Active) =>
    `/cart?action=cart&type=${type}`,
  [CartView.CustomerSearch]: (type: CartType = CartType.Active, error?) =>
    error
      ? `/cart?action=customer_search&type=${type}&error=${error}`
      : `/cart?action=customer_search&type=${type}`,
  [CartView.SelectAccount]: (type: CartType = CartType.Active) =>
    `/cart?action=select_account&type=${type}`,
  [CartView.SelectAddresses]: (
    type: CartType = CartType.Active,
    scrollTo?: string
  ) =>
    scrollTo
      ? `/cart?action=select_addresses&scrollTo=${scrollTo}&type=${type}`
      : `/cart?action=select_addresses&type=${type}`,
  [CartView.SelectAddressesQuote]: (
    type: CartType = CartType.Active,
    scrollTo?: string
  ) =>
    scrollTo
      ? `/cart?action=select_addresses&scrollTo=${scrollTo}&type=${type}&quote=true`
      : `/cart?action=select_addresses&type=${type}&quote=true`,
  [CartView.Checkout]: (type: CartType = CartType.Active) =>
    `/cart?action=checkout&type=${type}`,
  [CartView.QuoteCheckout]: (type: CartType = CartType.Active) =>
    `/cart?action=checkout&type=${type}&quote=true`,
  [CartView.OrderAcknowledgement]: (
    type: CartType = CartType.Active,
    oId?: string,
    orderTimedOut?: boolean
  ) =>
    orderTimedOut
      ? `/cart?action=order_acknowledgement&orderId=${oId}&orderTimedOut=${orderTimedOut}&type=${type}`
      : `/cart?action=order_acknowledgement&orderId=${oId}&type=${type}`,
  [CartView.QuoteConfirmation]: (
    type: CartType = CartType.Active,
    qId?: string
  ) => `/cart?action=quote_confirmation&quoteId=${qId}&type=${type}`,
  [CartView.SaveForLater]: (type: CartType = CartType.Active) =>
    `/cart?action=save_for_later&type=${type}`,
}

export const substanceRoute = {
  index: (substanceIdentifier: string, catalog: CatalogType) => {
    const isSialCatalog = catalog === CatalogType.Sial
    return {
      as: {
        pathname: `/substance/${substanceIdentifier}`,
        query: !isSialCatalog ? { context: obfuscateCatalogID(catalog) } : {},
      },
      href: {
        pathname: `/substance/[substanceIdentifier]`,
        query: !isSialCatalog ? { context: obfuscateCatalogID(catalog) } : {},
      },
    }
  },
}

export const geneDetailRoute = {
  index: (symbol: string, category?: string) => {
    return category
      ? {
          as: { pathname: `/genes/${symbol}`, query: { category } },
          href: { pathname: `/genes/[symbol]`, query: { category } },
        }
      : {
          as: `/genes/${symbol}`,
          href: `/genes/[symbol]`,
        }
  },
}

export const paperDetailRoute = {
  index: (paperId: string) => ({
    as: `/tech-docs/paper/${paperId}`,
    href: `/tech-docs/paper/[paperId]`,
  }),
}

export const certificateCOARoute = {
  index: (brand: string, prodNumber: string, lotNumber: string) =>
    `/coa/${brand}/${prodNumber}/${encodeURIComponent(lotNumber)}`,
}

export const certificateCOORoute = {
  index: (brand: string, prodNumber: string, lotNumber: string) =>
    `/coo/${brand}/${prodNumber}/${lotNumber}`,
}

export const resetPasswordRoute = {
  index: () => `/reset-password`,
}

export const preregisteredEmailRoute = {
  index: () => `/pre-registered-email`,
}

export const staticContent = {
  index: ({
    path = '/',
    language,
    country,
  }: {
    path?: string
    language: string
    country: string
  }) => ({
    href: '/[country]/[language]/[...cmsRoute]',
    as: `/${country || Router.query.country}/${
      language || Router.query.language
    }${path}`,
  }),
}

export const b2bLoginRoute = {
  index: () => '/ecommerce',
}

export const standardTubeRoute = {
  index: () => '/configurators/tube',
}

export const dnaPlatesRoute = {
  index: () => '/configurators/plate',
}

export const crisprgrnaRoute = {
  index: () => '/semi-configurators/crisprgrna',
}

export const kicqStartRoute = {
  index: () =>
    semiConfigurators ? '/semi-configurators/kicqstartProbe' : '/404',
}

export const crisprRoute = {
  index: () => (semiConfigurators ? '/semi-configurators/crispr' : '/404'),
}

export const semiConfiguratorsRoute = {
  index: (confType: string, term: string) => ({
    as: `/semi-configurators/${confType.toLowerCase()}?term=${term}`,
    href: `/semi-configurators/${confType.toLowerCase()}?term=${term}`,
  }),
}

export const sirnaRoute = {
  index: () => (semiConfigurators ? '/semi-configurators/sirna' : '/404'),
}

export const shrnaRoute = {
  index: () => (semiConfigurators ? '/semi-configurators/shrna' : '/404'),
}

export const kicqstartPrimersRoute = {
  index: () =>
    semiConfigurators ? '/semi-configurators/kicqstartPrimers' : '/404',
}

export const fastDuplexOligosRoute = {
  index: () => '/configurators/bitube',
}

export const customOligoRoute = {
  index: () => '/configurators/customOligo',
}

export const pepScreenRoute = {
  index: () => '/configurators/screen',
}

export const NgsoRoute = {
  index: () => '/configurators/ngso',
  navigate: (product: string, activeLink: string) => {
    return `/configurators/ngso?product=${product}&activeLink=${activeLink}`
  },
}

export const savedCartRoute = {
  index: (cartId: string) => ({
    as: `/saved-cart/${cartId}`,
    href: `/saved-cart/[cartId]`,
  }),
}

export const locationSelectRoute = {
  index: (redirect?: string) =>
    stratosMultiRegion
      ? redirect
        ? `/location-select?redirect=${encodeURIComponent(redirect)}`
        : '/location-select'
      : '/404',
}

export const orfRoute = {
  index: () => (semiConfigurators ? '/semi-configurators/orf' : '/404'),
}

export const pipelineRoute = {
  index: () => '/pipeline',
  departments: (orgId = '') => `/pipeline?orgid=${orgId}`,
  completeProfile: () => '/pipeline/complete-profile',
}

export const printInvoiceRoute = {
  index: (payer: string, resultDate: number, transactionNumber: string) =>
    `/invoice?payer=${payer}&resultDate=${resultDate}&transactionNumber=${transactionNumber}`,
}

export const milliplexRoute = {
  index: () => {
    if (milliplexRedesign) {
      return '/configurators/milliplex-old'
    }
    return '/configurators/milliplex'
  },
}

//TODO - When retire milliplexRedesign Feature Flag, see if renaming this const to milliplexRoute
export const milliplexRouteMTO = {
  index: () => '/configurators/milliplex',
}

export const genomicsRoute = {
  index: () => '/configurators/genomics',
}

export const antiseraRoute = {
  index: () => '/configurators/antisera-home/antisera',
}

export const simpleantiseraRoute = {
  index: () => '/configurators/antisera-home/simpleantisera',
}

export const subscriptionsRoutes = {
  index: () => '/subscriptions',
  emproveSuite: () => '/subscriptions/emprove-suite',
}

export const wimsAuthRoutes = {
  index: () => '/wims-auth',
}

export const support = {
  customerSupport: () => '/support/customer-support',
  customerSupportWithFormId: (id) => `/support/customer-support?form=${id}`,
}

export const documentsSearch = {
  index: (tab = 'sds') => `/documents-search?tab=${tab}`,
}

export const kicqstartGeneArrayRoute = {
  index: () =>
    semiConfigurators ? '/semi-configurators/kicqstartgenearrays' : '/404',
}

export const softwareLibraryRoutes = {
  index: () => '/software-library',
}

export const aldrichMarketSelectRoute = {
  index: () => '/aldrich-market-select',
}

export const sellerRoutes = {
  index: (sellerName: string, sellerId: string) => ({
    as: `/seller/${convertToSeoFriendly(sellerName)}/${sellerId}`,
    href: '/seller/[sellerName]/[sellerId]',
  }),
  contact: (
    sellerName: string,
    sellerId: string,
    returnUrl: string,
    reasonType: ContactSellerReasonType,
    subjectId: string
  ) => ({
    as: {
      pathname: `/seller/${convertToSeoFriendly(
        sellerName
      )}/${sellerId}/contact`,
      query: {
        returnUrl,
        t: reasonType,
        sub: subjectId,
      },
    },
    href: {
      pathname: `/seller/[sellerName]/[sellerId]/contact`,
      query: {
        returnUrl,
        t: reasonType,
        sub: subjectId,
      },
    },
  }),
  thankYou: (sellerName: string, sellerId: string, returnUrl: string) => ({
    as: {
      pathname: `/seller/${convertToSeoFriendly(
        sellerName
      )}/${sellerId}/contact/thank-you`,
      query: { returnUrl },
    },
    href: {
      pathname: `/seller/[sellerName]/[sellerId]/contact/thank-you`,
      query: { returnUrl },
    },
  }),
}

export const InviteCoWorkerRoute = {
  index: () => '/invite-coworker',
}
export const ReviewYourProfileRoute = {
  index: () => '/account-affiliation/invitation',
}

export const ScanNowRoute = {
  index: () => '/scan-now',
}

export const chromatogramSearchRoute = {
  index: () => (chromatogramSearch ? '/chromatogram-search' : '/404'),
}

export const chromatogramRoute = {
  index: (productNumber, brand, chromatogramName, technique) => {
    const formattedTechnique = DOMPurify.sanitize(technique, {
      ALLOWED_TAGS: [''],
      KEEP_CONTENT: true,
    })
      .trim()
      .replace(/[\s/\\|]+/g, '-')
      .replace(/-+/g, '-')
      .replace(' ', '-')
      .replace(/\(|\)/g, '')
      .toLowerCase()

    const formattedName = DOMPurify.sanitize(chromatogramName, {
      ALLOWED_TAGS: [''],
      KEEP_CONTENT: true,
    })
      .trim()
      .replace(/[^a-z0-9]+/gi, '-')
      .replace(/-+/g, '-')
      .toLowerCase()

    return {
      as: `/technical-documents/chromatograms/${formattedTechnique}/${formattedName}/${brand}/${productNumber}`,
      href: `/technical-documents/chromatograms/[formattedTechnique]/[formattedName]/[brand]/[productNumber]`,
    }
  },
}

export const documentLibraryRoute = {
  index: () => '/docs',
  savedDocuments: () => '/docs?docLibTab=YOUR_DOCUMENTS',
  savedProtocols: () => '/docs?docLibTab=SAVED_PROTOCOLS',
}

export const mediaCrossReferenceRoute = {
  index: () => '/sales-tools/media-cross-reference',
}

export const websiteUpdateInfoRoute = {
  index: () => '/life-science/ecommerce/website-update-info',
}

export const shoppingAiRoute = {
  index: () => '/life-science/assistant',
}
