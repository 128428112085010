import { useCallback, useMemo } from 'react'
import {
  ApprovalConsolidationModel,
  UserErpType,
  UserType,
} from '@src/types/graphql-types'
import {
  CurrentUserQuery,
  CurrentUserQueryVariables,
  useCurrentUserQuery,
} from '@src/queries/CurrentUserQuery.generated'
import { QueryHookOptions } from 'react-apollo'

export enum SitePreference {
  CatalogFilter = 'catalogFilter',
  DealerTcs = 'dealerTcs',
  IsRedirectToProductDetail = 'isRedirectToProductDetail',
  WebsiteLocale = 'websiteLocale',
  NumberOfRecentOrders = 'numberOfRecentOrders',
  NumberOfRecentQuotes = 'numberOfRecentQuotes',
  PreferredDealerInfo = 'preferredDealerInfo',
  PreferredDealerId = 'preferredDealerId',
  ProductEntryPreference = 'productEntryPreference',
  NumberOfEntryFields = 'numberOfEntryFields',
  SdsLocale = 'sdsLocale',
  HideAddToCartForPrepackItems = 'hideAddToCartForPrepackItems',
  HideBuildingBlocks = 'hideBuildingBlocks',
  HideOligoPeptideDesign = 'hideOligoPeptideDesign',
}

export enum GuestShoppingType {
  Dealer = 'dealer',
  DTAQ = 'dtaq',
}

export type FormAssemblyType = {
  firstName?: string
  lastName?: string
  email?: string
  telephone?: string
  mobilePhone?: string
  organizationName?: string
  soldToNumber?: string
  memberId?: string
  contactAddress?: {
    firstName?: string
    lastName?: string
    telephone?: string
    mobilePhone?: string
    email?: string
    fax?: string
    extension?: string
    city?: string
    country?: string
    postalCode?: string
    state?: string
    street?: string
    postOfficeBox?: string
  }
  soldToPartners?: {
    participantId?: string
    soldTo?: string
    shipTo?: string
    billTo?: string
  }
  adminPartnersInfo?: any
  participantCommunicationSetting?: {
    emproveEmailVerification?: boolean
  }
}

export type UserUnion = FormAssemblyType & CurrentUserQuery['me']

export const useCurrentUser = (
  queryOptions?: QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>
): {
  logonId: string
  currentUser: UserUnion | null
  currentUserLoading: boolean
  userIsLoggedIn: boolean
  userIsFullyAuthenticated: boolean
  isCCRUser: boolean
  isCustomerServiceRep: boolean
  isExternalSalesAgent: boolean
  isPurchasingAgent: boolean
  isRegisteredCustomer: boolean
  isOrderStatusCustomer: boolean
  isQuoteRequester: boolean
  isQuoteConverter: boolean
  isB2BUser: boolean
  isApprovee: boolean
  isInventoryViewUser: boolean
  isOrderApprover: boolean
  isOrderRequester: boolean
  isDealer: boolean
  uniqueSoldTos: number
  hasUniqueSoldTos: boolean
  getSitePreference: (preference: SitePreference) => any
  isMultiCountryUser: boolean
  isOrderConsolidationRequester: boolean
  isOrderConsolidator: boolean
  isKoreaPrePayment: boolean
  isKoreaStandardCustomer: boolean
  isInvoicePaymentCustomer: boolean
  isSourcingUser: boolean
  isDTAQFTBUser: boolean
  isPublicOrderingCustomer: boolean
  isDejaEnabled: boolean
  isDTAQZuCustomer: boolean
  isDigitalProductEnabled: boolean
  currentUserState: string | null | undefined
  roleNames?: string
  soldToNumber?: string
  memberId?: string
  userId: string
  emproveUserType?: string
  guestShoppingType?: string
  organizationType?: string
  organizationPosition?: string
  organizationWebsite?: string
  primaryBusinessActivity?: {}
  organizationId?: string | null | undefined
  approvalConsolidationModel?: ApprovalConsolidationModel | null | undefined
  isPriceUpliftCustomer: boolean | null | undefined
  isDirectCustomer: string | undefined
  isFullQuoteConversionEnabled: boolean
  anonymousShoppingDisabled: boolean
  isCreditCardEnabled: boolean
  isQuoteEnabled: boolean
  isBlueErpIntegrationEnabled: boolean
  isNewCartExperienceEnabled: boolean
  isSwitchAccountsEnabled: boolean
  userErpType: string
  isBlueB2BUser: boolean
  isDarmstadtUser: boolean
  hasOnlyBlueERP: boolean
  adminPartnersInfo: any
  userAdLink: string | undefined
  singlePartners: boolean
  inviteCoWorkerAffiliation: boolean | null | undefined
  isBuyingNetworkManager: boolean
  isPackForExportUser: boolean
  isCustomProductCSR: boolean
  isOrderableCustomer: boolean
  isSAPNextEnabled: boolean
  ccLegalTermsAcceptance: boolean | null | undefined
} => {
  const { error, data, loading } = useCurrentUserQuery(queryOptions)
  const currentUser = data?.me || null

  const currentUserLoading = error ? false : loading
  const getSitePreference = useCallback(
    (preference) => {
      return currentUser?.__typename === 'LoggedInUser'
        ? currentUser?.participantSitePreference[preference]
        : null
    },
    [currentUser]
  )
  const uniqueSoldTos = [
    ...new Set(
      currentUser?.__typename === 'LoggedInUser'
        ? currentUser?.soldToPartners
        : []
    ),
  ].length

  //Ideally objects should be sorted alphabetically based on the sort-keys rule. There is a lot of logic in making this hard to sort so it is ignored.
  /* eslint-disable sort-keys */
  return useMemo(
    () => ({
      logonId: data?.me.__typename === 'LoggedInUser' ? data.me.logonId : '',
      currentUser,
      currentUserLoading,
      organizationId:
        data?.me.__typename === 'LoggedInUser' ? data.me.organizationId : '',
      approvalConsolidationModel:
        data?.me.__typename === 'LoggedInUser'
          ? data.me.approvalConsolidationModel
          : null,
      userIsLoggedIn: data?.me.__typename === 'LoggedInUser',
      userIsFullyAuthenticated:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.type === UserType.LoggedIn,
      soldToNumber:
        data?.me.__typename === 'LoggedInUser' ? data.me.soldToNumber : '',
      memberId: data?.me.__typename === 'LoggedInUser' ? data.me.memberId : '',
      userId: data?.me.__typename === 'LoggedInUser' ? data.me.id : '',
      roleNames:
        data?.me.__typename === 'LoggedInUser' ? data.me.roleNames : '',
      emproveUserType:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me?.metadata?.emproveDomainStatus
          ? data.me.metadata.emproveDomainStatus
          : '',
      guestShoppingType:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me?.metadata?.guestShoppingType
          ? data.me.metadata.guestShoppingType
          : '',
      organizationType:
        (data?.me.__typename === 'LoggedInUser' &&
          data.me.participantComplianceInformation.organizationType) ||
        '',
      organizationPosition:
        (data?.me.__typename === 'LoggedInUser' &&
          data.me.participantComplianceInformation.position) ||
        '',
      organizationWebsite:
        (data?.me.__typename === 'LoggedInUser' &&
          data.me.participantComplianceInformation.organizationWebsite) ||
        '',
      primaryBusinessActivity:
        (data?.me.__typename === 'LoggedInUser' &&
          data.me.participantComplianceInformation.primaryBusinessActivity) ||
        {},
      isCCRUser:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isCCRUser,
      isCustomerServiceRep:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isCustomerServiceRep,
      isExternalSalesAgent:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isExternalSalesAgent,
      isPurchasingAgent:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isPurchasingAgent,
      isRegisteredCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isRegisteredCustomer,
      isOrderStatusCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isOrderStatusCustomer,
      isQuoteConverter:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isQuoteConverter,
      isQuoteRequester:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isQuoteRequester,
      isB2BUser:
        data?.me.__typename === 'LoggedInUser' && data.me.profileType === 'B2B',
      isApprovee:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isApprovee,
      isMultiCountryUser:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isMultiCountryUser,
      isInventoryViewUser:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isInventoryViewUser,
      isOrderApprover:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isOrderApprover,
      isOrderRequester:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isOrderRequester,
      isDealer:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isDealer,
      isOrderConsolidationRequester:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isOrderConsolidationRequester,
      isOrderConsolidator:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isOrderConsolidator,
      isKoreaPrePayment:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isKoreaPrePayment,
      isKoreaStandardCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isKoreaStandardCustomer,
      isInvoicePaymentCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isInvoicePaymentCustomer,
      isSourcingUser:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isSourcingUser,
      isDTAQFTBUser:
        data?.me.__typename === 'LoggedInUser' && data.me.roles.isDTAQFTBUser,
      isPublicOrderingCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isPublicOrderingCustomer,
      isDejaEnabled:
        (data?.me.__typename === 'LoggedInUser' ||
          data?.me.__typename === 'GuestUser') &&
        data.me.metadata.isDejaEnabled,
      isDigitalProductEnabled:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me.metadata.isDigitalProductEnabled,
      isBuyingNetworkManager:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isBuyingNetworkManager,
      isPackForExportUser:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isPackForExportUser,
      uniqueSoldTos,
      hasUniqueSoldTos: uniqueSoldTos > 1,
      getSitePreference,
      currentUserState:
        data?.me.__typename === 'LoggedInUser'
          ? data.me.shippingAddress?.state
          : null,
      isPriceUpliftCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me.isPriceUpliftCustomer,
      isDTAQZuCustomer:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.metadata.isDTAQZuCustomer,
      isDirectCustomer:
        data?.me.__typename === 'LoggedInUser' && data.me.isDirectCustomer
          ? data.me.isDirectCustomer
          : '',
      userAdLink:
        data?.me.__typename === 'LoggedInUser' && data.me.userAdLink
          ? data.me.userAdLink
          : '',
      isFullQuoteConversionEnabled:
        !!data?.me.metadata.isFullQuoteConversionEnabled,
      anonymousShoppingDisabled:
        data?.me.__typename === 'GuestUser' &&
        !data.me.metadata?.guestShoppingType,
      isCreditCardEnabled:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me.metadata?.isCreditCardEnabled,
      isQuoteEnabled:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me.metadata?.isQuoteEnabled,
      isSwitchAccountsEnabled:
        data?.me.__typename === 'LoggedInUser' &&
        !!data.me.metadata?.isSwitchAccountsEnabled,
      isBlueErpIntegrationEnabled:
        !!data?.me.metadata.isBlueErpIntegrationEnabled,
      isNewCartExperienceEnabled: !!data?.me.metadata.canTryNewCartExperience,
      userErpType: data?.me.userErpType || UserErpType.Anonymous,
      isBlueB2BUser:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me.profileType === 'B2B' &&
        data?.me.userErpType === UserErpType.Blue,
      isDarmstadtUser:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me?.soldToPartners[0]?.salesOrg === '0102',
      hasOnlyBlueERP: !!data?.me.metadata.hasOnlyBlueERP,
      adminPartnersInfo:
        data?.me.__typename === 'LoggedInUser' && data.me.adminPartnersInfo
          ? data.me.adminPartnersInfo
          : [],
      singlePartners:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me.soldToPartners?.length === 1,
      inviteCoWorkerAffiliation:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me.inviteCoWorkerAffiliation,
      isCustomProductCSR:
        data?.me.__typename === 'LoggedInUser' &&
        data.me.roles.isCustomProductCSR,
      isOrderableCustomer:
        !!data?.me?.metadata?.guestShoppingType ||
        (data?.me.__typename === 'LoggedInUser' &&
          !data?.me?.metadata?.guestShoppingType &&
          (data.me.roles.isPublicOrderingCustomer ||
            data.me.roles.isQuoteRequester)),
      isSAPNextEnabled: !!data?.me?.metadata?.isSAPNextEnabled,
      ccLegalTermsAcceptance:
        data?.me.__typename === 'LoggedInUser' &&
        data?.me?.paymentMethods?.creditCard[0]?.ccLegalTermsAcceptance,
    }),
    [data, currentUser, currentUserLoading, getSitePreference]
  )
  /* eslint-enable */
}

export type CurrentUser = ReturnType<typeof useCurrentUser>

export const canUserRequestQuote = (user: CurrentUser) => {
  return (
    user.isQuoteEnabled &&
    user.isQuoteRequester &&
    !user.isPurchasingAgent &&
    !user.hasUniqueSoldTos &&
    !user.isCustomerServiceRep &&
    !user.isExternalSalesAgent &&
    !user.isBlueErpIntegrationEnabled
  )
}
