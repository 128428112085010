import React, { ReactNode, CSSProperties } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CheckboxProps, Theme } from '@material-ui/core'
import FormControlLabel, {
  FormControlLabelProps,
} from '@material-ui/core/FormControlLabel'
import CommonCheckbox from '@src/components/CommonCheckbox'
import LiquidFormHelpText from '@src/components/LiquidFormHelpText'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControlLabelRoot: {
      alignItems: 'flex-start',
      verticalAlign: 'inherit',
    },
    label: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: theme.typography.pxToRem(5),
      flexShrink: 0,
      width: '100%',
    },
  })
)
interface LiquidCheckboxProps {
  error?: string
  label?: ReactNode
  name?: string
  id?: string
  checked?: boolean
  value?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  className?: string
  classes?: CheckboxProps['classes']
  style?: CSSProperties
  disabled?: boolean
  formControlClasses?: FormControlLabelProps['classes']
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>
}
const LiquidCheckbox: React.FC<LiquidCheckboxProps> = ({
  error,
  value,
  name,
  id,
  checked,
  label,
  disabled,
  onChange,
  onBlur,
  formControlClasses,
  inputProps,
  ...otherProps
}) => {
  const classes = useStyles()
  const checkboxId = id || name

  return (
    <FormControlLabel
      classes={{
        root: classes.formControlLabelRoot,
        label: classes.label,
        ...formControlClasses,
      }}
      checked={checked}
      control={
        <CommonCheckbox
          disabled={disabled}
          id={checkboxId}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          inputProps={inputProps}
          error={error}
          {...otherProps}
        />
      }
      label={
        <div>
          {label}
          {error && (
            <LiquidFormHelpText id={`${name}-help-text`} error={Boolean(error)}>
              {error ? error : null}
            </LiquidFormHelpText>
          )}
        </div>
      }
    />
  )
}

export default LiquidCheckbox
