import React, { useContext, useEffect, useState } from 'react'
import { sessionStorage } from 'react-storage'
import { MapTo } from '@adobe/aem-react-editable-components'
import DynamicProductCarousel from '@src/components/DynamicProductCarousel'
import { makeStyles } from '@material-ui/core/styles'
import { Theme, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { useIntersectionObserver } from '@src/utils/useIntersectionObserver'
import { useBestSellerProductsQuery as useBuyAgainProductsQuery } from '@src/queries/BestSellerProductsQuery.generated'
import { useBestSellerProductsByRegionQuery } from '@src/queries/BestSellerProductsByRegionQuery.generated'
import { recommendationProductsConst } from '@src/aem-content/aemUtils'
import { useCurrentUser } from '@utils/useCurrentUser'
import { ErpType } from '@utils/useProductErpType'
import { AemContext } from '@src/aem-content/components/AemContext'
import { CarouselProduct } from '@src/components/DynamicProductCarousel/DynamicProductCardTypes'
import { useCountriesData } from '@src/utils/useCountriesData'
import { useUserSession } from '@src/utils/useUserSession'
import SkeletonLoader from '../SkeletonLoader'
import { FormattedMessage } from 'react-intl'
import {
  GA4EcommercePayload,
  GA4EcommercePayloadItem,
} from '@sial/common-utils'
import {
  sendAEMProductRecommendationsEvent,
  sendRecommendedProductsData,
} from '@src/utils/analytics'
import { EventKeys } from '@src/utils/analytics/enums'
import { useRouter } from '@src/routes'

const useStyles = makeStyles((theme: Theme) => ({
  carouselWrapper: {
    '@media (max-width: 1350px)': {
      padding: theme.spacing(0, 5),
    },
  },
  error: {
    padding: theme.spacing(6, 5, 3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  title: {
    marginBottom: theme.spacing(8),
    '$contentCondensed &': {
      marginBottom: theme.spacing(4),
    },
  },
}))

const ProductRecommendationCarousel: React.FC<any> = ({ typeId, headline }) => {
  const classes = useStyles()
  const router = useRouter()
  const { isBlueB2BUser, userId, userIsLoggedIn } = useCurrentUser()
  const { userSession } = useUserSession()
  const { isBuyAgainEmpty, setIsBuyAgainEmpty } = useContext(AemContext)
  const { getCountryProp } = useCountriesData()
  const [carouselVisible, setCarouselVisible] = useState(false)
  const [fetchedProducts, setFetchedProducts] = useState<CarouselProduct[]>([])

  // Note: Checking productRecommendationCarousel is for homePage buy again products.
  const isBuyAgainType: boolean = typeId === 'buyAgain'
  // Note: Checking productRecommendationCarousel is for homePage bestsellers products.
  const isBestSellersType: boolean = typeId === 'bestsellers'
  // Note: Checking productRecommendationCarousel is for homePage popular products.
  const isPopularProductsType: boolean = typeId === 'popular_products'
  // Note: Checking productRecommendationCarousel is for category page bestSellers products.
  const isCatPageBestSellersAPI: boolean =
    recommendationProductsConst[typeId]?.typeId === 'catPageBestSellersAPI'

  const getCreativeSlotName = (typeId) => {
    if (!typeId) return ''
    if (isBuyAgainType && isBuyAgainEmpty) return 'popular_products - homepage'
    const headline = recommendationProductsConst[typeId]?.headline
    const creativeSlot =
      headline.length && headline?.toLowerCase()?.split(' ')?.join('_')
    return `${creativeSlot} - ${
      isCatPageBestSellersAPI ? 'categorypage' : 'homepage'
    }`
  }

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.75,
  }
  const promoBannerItemsView: IntersectionObserverCallback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && !carouselVisible) {
        const ga4Payload: GA4EcommercePayloadItem[] = []
        const visibleProducts = fetchedProducts.slice(0, 4)
        for (const [index, product] of visibleProducts.entries()) {
          const [promotionId, promotionName, creativeName] =
            product.gaProductCode?.split('|') || []
          const ga4PayloadItem: GA4EcommercePayloadItem = {
            creative_name: creativeName?.toLowerCase(),
            promotion_id: promotionId?.toLowerCase(),
            promotion_name: promotionName?.toLowerCase(),
            index: index + 1,
            item_id: product?.productNumber?.toLowerCase(),
            item_brand: product?.brand?.key?.toLowerCase(),
            item_list_id: isCatPageBestSellersAPI ? 'categorypage' : 'homepage',
            item_list_name: isCatPageBestSellersAPI
              ? 'categorypage'
              : 'homepage',
          }
          ga4Payload.push(ga4PayloadItem)
        }
        const payload: GA4EcommercePayload = {
          creative_slot: getCreativeSlotName(typeId),
          items: [...ga4Payload],
        }
        sendAEMProductRecommendationsEvent(payload)
        setCarouselVisible(true)
      }
    })
  }
  const divRef = useIntersectionObserver<HTMLDivElement>(
    promoBannerItemsView,
    options
  )

  // Note: Below function helps to create region wise display code for both homePageBestSellersAPI & catPageBestSellersAPI.
  // Sample Url: https://expanded-nebula-754.appspot.com/deep?get=homePageBestSellersAPI&guid=GA1.2.1607803001.1571084413&language=en&count=10&disp=NA
  // APAC for Asia & Australia
  // NA for North America
  // EMEA for Europe
  // LATAM  for South America

  // Sample Url: https://cloud-analytics.sial.com/deep?get=catPageBestSellersAPI&guid=GA1.2.1607803001.1571084413&country=US&language=en&count=10&disp=S138_APAC
  // S138_APAC for Asia & Australia
  // S138_NA for North America
  // S138_EMEA for Europe
  // S138_LATAM  for South America

  const getRegionCode = (isCatPageBestSellersDisp: boolean = false): string => {
    const regionCode: string = getCountryProp(userSession.country, 'regionCode')
    const disp =
      isCatPageBestSellersDisp && recommendationProductsConst[typeId]?.disp
        ? `${recommendationProductsConst[typeId]?.disp}_`
        : ''
    const regions: { [key: string]: string } = {
      AP: 'APAC',
      EU: 'EMEA',
      AF: 'EMEA',
      SA: 'LATAM',
    }

    const region = regions[regionCode] || regionCode
    return `${disp}${region}`
  }

  // Note: Below function helps to create typeId conditionally for homePageBestSellersAPI, homePagePopularProductsAPI & catPageBestSellersAPI.
  const getTypeId = (): string => {
    let inputTypeId: string = ''
    if (isBestSellersType || isPopularProductsType || isCatPageBestSellersAPI)
      inputTypeId = recommendationProductsConst[typeId]?.typeId || ''
    return inputTypeId
  }

  // Note: Below function helps to create displayCode conditionally for homePageBestSellersAPI, homePagePopularProductsAPI & catPageBestSellersAPI.
  const getDisplay = (): string => {
    let display: string = ''
    if (isBestSellersType || isCatPageBestSellersAPI)
      display = isCatPageBestSellersAPI ? getRegionCode(true) : getRegionCode()
    if (isPopularProductsType)
      display = recommendationProductsConst[typeId]?.disp || ''
    return display
  }

  // Note: Below function helps to set querySkip boolean value to false only for homePageBestSellersAPI, homePagePopularProductsAPI & catPageBestSellersAPI.
  // and this function value is used to skip other query calls except for homePageBestSellersAPI, homePagePopularProductsAPI & catPageBestSellersAPI
  const querySkip = (): boolean =>
    !(
      isBestSellersType ||
      (isPopularProductsType && !userIsLoggedIn) ||
      isCatPageBestSellersAPI
    )

  const { loading, error, data } = useBuyAgainProductsQuery({
    ssr: false,
    fetchPolicy: 'network-only',
    variables: {
      input: {
        ...(isBlueB2BUser ? { erpType: ErpType.Blue } : {}),
        typeId: recommendationProductsConst[typeId]?.typeId,
        disp: recommendationProductsConst[typeId]?.disp,
        ...(isBuyAgainType ? { participant_id: userId } : {}),
        //NOTE - This id helps testing buy again as we are certain to get products for the below participant_id.
        //...(typeId === 'buyAgain' ? {participant_id: '0116a670-9a68-11eb-ab02-d9c6651681af'} : {})
      },
    },
    skip:
      isBestSellersType ||
      isPopularProductsType ||
      (isBuyAgainType && !userIsLoggedIn) ||
      isCatPageBestSellersAPI,
  })

  // Note: The query below will be called conditionally only for homePagePopularProductsAPI if buyAgain api don't have any response for loggedin user.
  const {
    data: buyAgainFallbackPopularProductsData,
    loading: buyAgainFallbackpopularProductsLoading,
    error: buyAgainFallbackpopularProductsError,
  } = useBestSellerProductsByRegionQuery({
    variables: {
      input: {
        typeId: recommendationProductsConst['popular_products'].typeId,
        disp: recommendationProductsConst['popular_products'].disp,
      },
    },
    ssr: false,
    fetchPolicy: 'network-only',
    skip: !isBuyAgainEmpty,
  })

  // Note: The query below will be called only for homePageBestSellersAPI, homePagePopularProductsAPI, and catPageBestSellersAPI.
  const {
    data: bestSellingProductsData,
    loading: bestSellingProductsLoading,
    error: bestSellingProductsError,
  } = useBestSellerProductsByRegionQuery({
    variables: {
      input: {
        typeId: getTypeId(),
        disp: getDisplay(),
      },
    },
    ssr: false,
    fetchPolicy: 'network-only',
    skip: querySkip(),
  })

  useEffect(() => {
    if (
      data &&
      !data?.getBestSellerProducts?.products?.length &&
      isBuyAgainType
    ) {
      setIsBuyAgainEmpty(true)
    }
  }, [data])

  useEffect(() => {
    let products: CarouselProduct[] = []
    if (data && data.getBestSellerProducts?.products.length) {
      products = data?.getBestSellerProducts?.products?.filter(Boolean)
      sendRecommendedProductsData(
        isBuyAgainType ? EventKeys.BUY_AGAIN : EventKeys.NEW_ARRIVALS,
        products
      )
    } else if (
      bestSellingProductsData &&
      bestSellingProductsData.getBestSellerProductsByRegion?.products.length
    ) {
      products =
        bestSellingProductsData?.getBestSellerProductsByRegion?.products
      if (isPopularProductsType)
        sendRecommendedProductsData(EventKeys.POPULAR_PRODUCTS, products)
      else if (isBestSellersType)
        sendRecommendedProductsData(EventKeys.BEST_SELLERS, products)
    } else if (
      buyAgainFallbackPopularProductsData &&
      buyAgainFallbackPopularProductsData.getBestSellerProductsByRegion
        ?.products.length
    ) {
      products =
        buyAgainFallbackPopularProductsData?.getBestSellerProductsByRegion
          ?.products
      sendRecommendedProductsData(EventKeys.POPULAR_PRODUCTS, products)
    }
    const handleSessionStorage = () => {
      sessionStorage.removeItem(EventKeys.POPULAR_PRODUCTS)
      sessionStorage.removeItem(EventKeys.BUY_AGAIN)
      sessionStorage.removeItem(EventKeys.NEW_ARRIVALS)
      sessionStorage.removeItem(EventKeys.BEST_SELLERS)
    }
    setFetchedProducts(products)
    router.events.on('routeChangeStart', handleSessionStorage)

    return () => {
      router.events.off('routeChangeStart', handleSessionStorage)
    }
  }, [data, bestSellingProductsData, buyAgainFallbackPopularProductsData])
  if (
    loading ||
    bestSellingProductsLoading ||
    buyAgainFallbackpopularProductsLoading
  )
    return <SkeletonLoader count={4} />
  if (
    error ||
    bestSellingProductsError ||
    buyAgainFallbackpopularProductsError
  ) {
    console.error(error)
    if (
      data?.getBestSellerProducts?.products &&
      data?.getBestSellerProducts?.products?.length > 0
    ) {
      return (
        <div className={classes.error}>
          <ErrorIcon />
          <span>Something went wrong, please try again.</span>
        </div>
      )
    } else {
      return null
    }
  } else {
    return fetchedProducts &&
      fetchedProducts?.length > 0 &&
      Array.isArray(fetchedProducts) ? (
      <div
        id="aem-recommended-products"
        className={classes.carouselWrapper}
        ref={divRef}
      >
        <DynamicProductCarousel
          titleOverride={
            headline ? (
              <Typography variant="h2" className={classes.title}>
                {isBuyAgainType && isBuyAgainEmpty ? (
                  <FormattedMessage
                    id="POPULAR_PRODUCTS"
                    defaultMessage="Popular Products"
                  />
                ) : (
                  headline
                )}
              </Typography>
            ) : (
              <></>
            )
          }
          type={
            isBuyAgainType && isBuyAgainEmpty
              ? recommendationProductsConst['popular_products']?.disp
              : recommendationProductsConst[typeId]?.disp
          }
          products={fetchedProducts}
          parentPageName={isCatPageBestSellersAPI ? 'categorypage' : 'homepage'}
          parentPageNameDetail={
            isCatPageBestSellersAPI ? 'categorypage' : 'homepage'
          }
          isProductRecommendation
        />
      </div>
    ) : null
  }
}

export default MapTo(
  'cms-commons/components/content/productRecommendationCarousel'
)(ProductRecommendationCarousel)
