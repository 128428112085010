import { Divider, Tab, Tabs, TooltipProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ClassNameMap } from '@material-ui/core/styles/withStyles'
import Tooltip from '@material-ui/core/Tooltip'
import { TooltipClassKey } from '@material-ui/core/Tooltip/Tooltip'
import { useEffect, useState } from 'react'

/**
  Provides default styling in alignment with current design system:

  https://www.figma.com/design/Qakbf0ck9WM6GT0rkMvihB/Design-System-%2F-Component-Library?node-id=11267-18197&node-type=frame&t=kTUu5ds5HagnLYvj-0

  Accepts an array of tab configuration objects of with the CommonTab interface

  Includes option to pass in event callback handlers for change and individual tab selection event
 */
const useStyles = makeStyles((theme) => ({
  tabs: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.text.primary,
      height: '4px',
    },
  },
  tab: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2.5, 4),
    minWidth: 'auto',
    opacity: 1,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(4),
    },
  },
}))

export interface CommonTab {
  id?: string
  label: string
  component: JSX.Element
  selectCallback?: Function
  toolTip?: {
    title: string
    arrow?: boolean
    placement?: TooltipProps['placement']
    classes?: Partial<ClassNameMap<TooltipClassKey>>
  }
}

interface TabPanelProps {
  tab: CommonTab
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { tab, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <>{tab.component}</>}
    </div>
  )
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  }
}

export interface CommonTabsProps {
  tabs: CommonTab[]
  activeTabIndex?: number
  hideDivider?: boolean
  changeCallback?: (newValue: number) => void
}

const CommonTabs = ({
  tabs,
  activeTabIndex,
  hideDivider,
  changeCallback,
}: CommonTabsProps) => {
  const classes = useStyles()

  let defaultTabIndex = 0
  if (activeTabIndex) {
    const activeTabIsValid = activeTabIndex >= 0 && activeTabIndex < tabs.length
    activeTabIsValid
      ? (defaultTabIndex = activeTabIndex)
      : console.warn(
          'The initial active tab integer is invalid. Check length of tabs.'
        )
  }

  // used to maintain state of activeTabIndex changes from prop updates with user selection events
  const [currentTabIndex, setCurrentTabIndex] = useState(defaultTabIndex)
  useEffect(() => {
    setCurrentTabIndex(activeTabIndex || defaultTabIndex)
  }, [activeTabIndex])

  const handleChange = (event: any, newTabIndex: number) => {
    if (newTabIndex !== currentTabIndex) {
      setCurrentTabIndex(newTabIndex)
      changeCallback && changeCallback(newTabIndex)
    }
  }

  const handleSelect = (tab: CommonTab, index: number) => {
    if (index !== currentTabIndex) {
      tab.selectCallback && tab.selectCallback(index)
    }
  }

  const renderTab = (index: number, tab: CommonTab) => (
    <Tab
      key={index}
      className={classes.tab}
      label={tab.label}
      data-testid={tab.id}
      onClick={() => handleSelect(tab, index)}
      {...a11yProps(index)}
    />
  )

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={currentTabIndex}
        onChange={handleChange}
      >
        {tabs.map((tab: CommonTab, index) => {
          if (tab?.toolTip) {
            return (
              <Tooltip
                title={tab.toolTip.title}
                arrow={tab.toolTip?.arrow}
                key={index}
                placement={tab.toolTip?.placement}
                classes={tab.toolTip?.classes}
              >
                {renderTab(index, tab)}
              </Tooltip>
            )
          }
          return renderTab(index, tab)
        })}
      </Tabs>

      {!hideDivider && <Divider />}

      {tabs.map((tab: CommonTab, index) => {
        return (
          <TabPanel
            key={index}
            tab={tab}
            index={index}
            value={currentTabIndex}
          />
        )
      })}
    </>
  )
}

export default CommonTabs
