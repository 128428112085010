import { useCallback, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { ProductPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import {
  MultiplePricingAndAvailabilityDocument,
  MultiplePricingAndAvailabilityQuery,
  MultiplePricingAndAvailabilityQueryVariables,
} from '@src/queries/MultiplePricingAndAvailabilityQuery.generated'
import { MaterialsInput } from '@src/components/QuickOrder'
import { CatalogType, SharedListItemInput } from '@src/types/graphql-types'
import { isDefined } from '@src/utils/isDefined'
import { QueryResult } from '@src/utils/QueryResult'
import { ApolloQueryResult } from 'apollo-client/core/types'
import { MaterialsDetailFragment } from '@src/queries/MaterialDetailsQuery.generated'

const asTuple = <T1, T2>(t1: T1, t2: T2): [T1, T2] => [t1, t2]

export const useQuickOrderPriceAvailability = () => {
  const client = useApolloClient()
  const pricingCache = useRef<
    Partial<{
      [materialNumber: string]: QueryResult<ProductPricingDetailFragment>
    }>
  >({})
  const [data, setData] = useState<MultiplePricingAndAvailabilityQuery>()

  const getPricingForProduct = useCallback(
    async (
      materialValues: (MaterialsInput | SharedListItemInput)[],
      materialsCache: Partial<{
        [materialNumber: string]: QueryResult<MaterialsDetailFragment>
      }>
    ) => {
      const materialsThatNeedPricing = materialValues.filter((input) => {
        return !pricingCache.current[input.materialNumber]
      })
      if (materialsThatNeedPricing.length) {
        materialsThatNeedPricing.forEach(({ materialNumber }) => {
          pricingCache.current[materialNumber] = {
            ...(pricingCache.current[materialNumber] || { data: null }),
            loading: true,
          }
        })
        let res: ApolloQueryResult<MultiplePricingAndAvailabilityQuery>
        let error: unknown
        try {
          res = await client.query<
            MultiplePricingAndAvailabilityQuery,
            MultiplePricingAndAvailabilityQueryVariables
          >({
            query: MultiplePricingAndAvailabilityDocument,
            variables: {
              productDetails: materialsThatNeedPricing.map((material) => ({
                productNumber: material.materialNumber,
                quantity: material.quantity,
                catalogType: materialsCache[material.materialNumber]?.data
                  ?.isMarketplace
                  ? CatalogType.Marketplace
                  : undefined,
              })),
            },
            fetchPolicy: 'network-only',
          })
        } catch (e) {
          error = e
        }
        materialsThatNeedPricing.forEach(({ materialNumber }) => {
          pricingCache.current[materialNumber] = {
            loading: false,
            data: res.data.getPricingForMultipleProducts.find(
              (p) => p.productNumber === materialNumber
            ),
            error,
          }
        })
      }
      setData({
        getPricingForMultipleProducts: materialValues
          .map(({ materialNumber, quantity }) => {
            const productPricing = pricingCache.current[materialNumber]?.data
            const materialPricing = productPricing?.materialPricing[0]
            if (!productPricing || !materialPricing) return null
            return {
              ...productPricing,
              materialPricing: [{ ...materialPricing, quantity }],
            }
          })
          .filter(isDefined),
      })
    },
    [client]
  )

  return asTuple(getPricingForProduct, {
    data,
    isLoading: useCallback(
      () => Object.values(pricingCache.current).some((p) => p?.loading),
      []
    ),
    pricingCache,
  })
}
