import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { FormattedMessage } from 'react-intl'
import messages from '@utils/messages'
import { Link } from '@src/components/Link'
import { useUserSession } from '@utils/useUserSession'
import { staticContent } from '@src/routes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      justifyContent: 'left',
      paddingLeft: theme.spacing(1),
    },
  })
)

interface LearnMoreProps {
  location?: string
}

const MarketplaceLearnMoreLink: React.FC<LearnMoreProps> = () => {
  const classes = useStyles()
  const { userSession } = useUserSession()

  return (
    <Link
      {...staticContent.index({
        path: '/life-science/mpartner',
        language: userSession.language,
        country: userSession.country,
      })}
      passHref
    >
      <a className={classes.link} rel="noopener" target="_blank">
        <FormattedMessage {...messages.LEARN_MORE} />
      </a>
    </Link>
  )
}

export default MarketplaceLearnMoreLink
