import { MultiplePricingAndAvailabilityQuery } from '@src/queries/MultiplePricingAndAvailabilityQuery.generated'
import { MaterialsInput } from '@src/components/QuickOrder'

export const useGetMaterials = () => {
  const getMaterialsFromPricing = (
    validationMaterials: MultiplePricingAndAvailabilityQuery | undefined
  ) =>
    validationMaterials?.getPricingForMultipleProducts?.reduce(
      (keyVal, next) => {
        if (!!next) {
          next.materialPricing?.forEach((material) => {
            if (material) {
              keyVal[material.materialNumber] = {
                marketplaceOfferId: material.marketplaceOfferId,
                catalogType: material.catalogType,
              }
            }
          })
        }
        return keyVal
      },
      {}
    ) || {}

  const getMaterialsWithMarketPlaceData = (
    materials: MaterialsInput[],
    materialsFromPricing: {}
  ) =>
    materials.map((material) => ({
      ...material,
      catalogType: materialsFromPricing[material.materialNumber]?.catalogType,
      marketplaceOfferId:
        materialsFromPricing[material.materialNumber]?.marketplaceOfferId,
    }))

  return { getMaterialsFromPricing, getMaterialsWithMarketPlaceData }
}
