import React, { useState, ReactNode } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, Button, Theme } from '@material-ui/core'
import { validateField } from '@src/utils/validators'
import * as yup from 'yup'
import { Formik, FormikProps, Form, Field, FormikHelpers } from 'formik'
import {
  useSdsCertificateSearchLazyQuery,
  SdsCertificateSearchQuery,
} from '@src/queries/SdsCertificateSearchQuery.generated'
import { SdsData } from '@src/types/graphql-types'
import { sdsRoute } from '@src/routes'
import messages from '@utils/messages'
import {
  SnackbarType,
  useGlobalSnackbar,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import LiquidCertificateAutoCompleteAdapter from '@src/components/LiquidCertificateAutoCompleteAdapter'
import SDSModal from '@src/components/SDSModal'
import { sendErrorEvent } from '@src/utils/analytics'
import vrStyles from '@src/styles/utils/vrStyles'
import { useUserSession } from '@utils/useUserSession'
import clsx from 'clsx'
import { useCurrentUser } from '@utils/useCurrentUser'

export const useStyles = makeStyles((theme: Theme) => {
  const { vr2, vr4, vr6 } = vrStyles
  return {
    submitButton: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    instructions: {
      fontSize: theme.typography.pxToRem(14),
      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.pxToRem(16),
      },
    },
    fieldLabel: {
      color: theme.palette.error.main,
    },
    vr2,
    vr4,
    vr6,
  }
})

interface SDSSearchFormValues {
  productNumber?: string
  title?: ReactNode
  instructions?: ReactNode
  isAdvancedSearch?: boolean
}

const SDSSearchForm: React.FC<SDSSearchFormValues> = ({
  title = <FormattedMessage {...messages.SEARCH_SAFETY_DATA_SHEET} />,
  instructions = (
    <FormattedMessage {...messages.ENTER_PRODUCT_NUMBER_FOR_SDS} />
  ),
  isAdvancedSearch,
}) => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const classes = useStyles()
  const { setGlobalSnackbarState } = useGlobalSnackbar()
  const [sdsModalOpen, setSdsModalOpen] = useState(false)
  const { userSession } = useUserSession()
  const { userErpType } = useCurrentUser()

  const handleSdsData = (data: SdsCertificateSearchQuery) => {
    const sdsData = data?.getSdsCertificateSearch
    const displaySdsData = !!sdsData?.brands.length

    if (sdsData && displaySdsData) {
      const isSingleLanguage =
        sdsData.sds[0]?.languages.altLanguages.length === 1
      const singleLanguage = sdsData.sds[0]?.languages.altLanguages[0] || ''
      const singleBrand = sdsData.brands[0] || ''
      const productNumber = sdsData.productNumber
      if (sdsData.brands.length === 1 && isSingleLanguage) {
        return window.open(
          `${sdsRoute.index(
            singleBrand,
            productNumber,
            userSession.country,
            singleLanguage,
            userErpType
          )}`,
          '_blank'
        )
      }
      return setSdsModalOpen(true)
    } else {
      setGlobalSnackbarState({
        open: true,
        message: formatMessage(messages.ERROR_404_SDS_MESSAGE),
        variant: SnackbarType.Error,
      })

      const eventLabel = formatMessage(messages.ERROR_404_SDS_MESSAGE)
        .replace('.', '')
        .toLowerCase()

      sendErrorEvent(
        {
          description: eventLabel,
          errorCategory: 'file download errors',
          productId: data.getSdsCertificateSearch?.productNumber?.toLowerCase(),
        },
        {
          eventCategory: 'downloads',
          eventAction: 'sds',
          eventLabel: `${eventLabel} > ${data.getSdsCertificateSearch?.productNumber?.toLowerCase()}`,
          eventInteractionType: 0,
        }
      )
    }
  }

  const [getSDSCertificate, { data }] = useSdsCertificateSearchLazyQuery({
    onCompleted: (data) => handleSdsData(data),
    fetchPolicy: 'no-cache',
  })

  const label = (error?: string) => {
    return (
      <Typography
        variant="body2"
        style={{
          color: error ? theme.palette.error.main : theme.palette.common.black,
        }}
      >
        <b>
          {'* '}
          <FormattedMessage {...messages.PRODUCT_NUMBER} />
        </b>
      </Typography>
    )
  }

  return (
    <>
      <Typography variant="h3" className={classes.vr4}>
        {title}
      </Typography>
      <Typography className={clsx(classes.vr4, classes.instructions)}>
        {instructions}
      </Typography>
      {!isAdvancedSearch && (
        <Typography variant="body2" className={classes.vr4}>
          <FormattedMessage {...messages.INDICATES_REQUIRED_FIELD} />
        </Typography>
      )}
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          productNumber: '',
        }}
        onSubmit={(
          values: SDSSearchFormValues,
          actions: FormikHelpers<SDSSearchFormValues>
        ) => {
          getSDSCertificate({
            variables: { productNumber: values.productNumber?.trim() || '' },
          })
          actions.setSubmitting(false)
        }}
      >
        {(formikBag: FormikProps<SDSSearchFormValues>) => (
          <div>
            <Form data-testid="sds-search-form">
              <div className={classes.vr6}>
                <Field
                  name="productNumber"
                  label={label(formikBag.errors.productNumber)}
                  component={LiquidCertificateAutoCompleteAdapter}
                  placeholder="e.g. T1503"
                  filled
                  id="sds-product-number-field"
                  inputProps={{ 'data-testid': 'sds-product-number-field' }}
                  validate={validateField(
                    yup
                      .string()
                      .trim()
                      .required(
                        formatMessage(messages.PLEASE_ENTER_PRODUCT_NUMBER)
                      )
                  )}
                />
              </div>
              <Button
                data-testid="sds-search-submit"
                id="sds-search-submit"
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submitButton}
                disabled={formikBag.isSubmitting}
                aria-label="Submit"
                size="large"
              >
                <FormattedMessage {...messages.SEARCH} />
              </Button>
            </Form>
          </div>
        )}
      </Formik>
      {sdsModalOpen &&
        data?.getSdsCertificateSearch?.sds &&
        data?.getSdsCertificateSearch?.brands && (
          <SDSModal
            open={sdsModalOpen}
            setModalOpen={setSdsModalOpen}
            brands={data?.getSdsCertificateSearch?.brands as string[]}
            sdsDetails={data.getSdsCertificateSearch.sds as SdsData[]}
            productNumber={data?.getSdsCertificateSearch?.productNumber}
            isAdvancedSearch
          />
        )}
    </>
  )
}

export default SDSSearchForm
