import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import HandleMarkup from '@src/components/HandleMarkup'
import { DiscontinuedMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { CatalogImage } from '@src/types/graphql-types'
import { useRouter } from '@src/routes'
import { useCompareProducts } from '@utils/useCompareProducts'
import messages from '@utils/messages'
import ReplacementProduct from './ReplacementProduct'
import { MaterialsInput } from '../QuickOrder'
import { useSearchQuery } from '@src/pages/[country]/[language]/search/[searchTerm]'

const useStyles = makeStyles((theme: Theme) => ({
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(4),
    color: theme.palette.grey[100],
    cursor: 'pointer',
  },
  compare: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    '& > div': {
      flex: '0 0 auto',
    },
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  replacementProducts: {
    maxHeight: 450,
    overflowY: 'auto',
  },
  productHead: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    '& > .MuiGrid-item': {
      padding: theme.spacing(1),
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
}))

interface ReplacementProductsModalProps {
  open: boolean
  setOpen: (open: any) => void
  productNumber: string
  images?: CatalogImage[]
  brandKey?: string
  materialNumber?: string
  materialName?: Maybe<string>
  discontinuedPricingInfo: DiscontinuedMaterialPricingDetailFragment
  hideCompare?: boolean
  addToList?: (replacementMaterials: MaterialsInput[]) => void
}

const ReplacementProductsModal: React.FC<ReplacementProductsModalProps> = ({
  open,
  setOpen,
  productNumber,
  images,
  brandKey,
  materialNumber,
  materialName,
  discontinuedPricingInfo,
  hideCompare = false,
  addToList,
}) => {
  const classes = useStyles()
  const {
    addCompareProduct,
    removeCompareProduct,
    clearAllCompareProducts,
    compareProducts,
    sendToProductCompareDetail,
  } = useCompareProducts()

  const router = useRouter()
  const [query] = useSearchQuery()

  React.useEffect(() => {
    if (brandKey && open) {
      clearAllCompareProducts([{ productKey: productNumber, images, brandKey }])
    }
  }, [open, clearAllCompareProducts, productNumber, brandKey])

  const { replacementProducts, alternateMaterials } = discontinuedPricingInfo

  const isProductSelectedForCompare = React.useCallback(
    (productKey: string, brandKey?: string) => {
      return !!compareProducts.find(
        (product) =>
          product.productKey === productKey && product.brandKey === brandKey
      )
    },
    [compareProducts]
  )

  const isMainProductSelectedForCompare = React.useMemo(
    () => isProductSelectedForCompare(productNumber, brandKey),
    [isProductSelectedForCompare, productNumber, brandKey]
  )

  const setSelectedForCompare = React.useCallback(
    (
      productKey: string,
      images: Partial<CatalogImage>[],
      brandKey: string,
      selected: boolean
    ) => {
      if (selected) {
        addCompareProduct({ productKey, brandKey, images })
      } else {
        removeCompareProduct({ productKey, brandKey })
      }
    },
    [addCompareProduct, removeCompareProduct]
  )

  const compareButtonEnabled = React.useMemo(
    () =>
      isProductSelectedForCompare(productNumber, brandKey) &&
      compareProducts.length > 1 &&
      compareProducts.length <= 4,
    [isProductSelectedForCompare, productNumber, brandKey, compareProducts]
  )

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
      <DialogTitle>
        <FormattedMessage
          {...messages.REPLACEMENT_PRODUCTS_TITLE}
          values={{
            materialNumber: materialNumber || productNumber,
            materialName: <HandleMarkup value={materialName} />,
          }}
        />
        <CloseIcon
          className={classes.closeIcon}
          fontSize="large"
          onClick={() => setOpen(false)}
        />
        {!hideCompare && (
          <div className={classes.compare}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isProductSelectedForCompare(
                      productNumber,
                      brandKey
                    )}
                    onChange={(e) => {
                      clearAllCompareProducts(
                        e.target.checked && brandKey
                          ? [{ productKey: productNumber, brandKey, images }]
                          : []
                      )
                    }}
                  />
                }
                label={
                  <FormattedMessage
                    {...messages.COMPARE_SELECTED_WITH}
                    values={{ productNumber: <b>{productNumber}</b> }}
                  />
                }
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => sendToProductCompareDetail(query, router)}
                disabled={!compareButtonEnabled}
              >
                <FormattedMessage {...messages.COMPARE} />
                {compareProducts.length > 0 && ` (${compareProducts.length})`}
              </Button>
            </div>
          </div>
        )}
        <Divider className={classes.divider} />
      </DialogTitle>
      <DialogContent style={{ maxHeight: 600 }}>
        <Grid container spacing={4} className={classes.replacementProducts}>
          <Grid item xs={12}>
            <FormattedMessage
              {...messages.REPLACEMENT_PRODUCTS_FOR}
              values={{ materialNumber: materialNumber || productNumber }}
            >
              {(chunks) => <Typography variant="h3">{chunks}</Typography>}
            </FormattedMessage>
          </Grid>
          {replacementProducts && alternateMaterials && (
            <Grid item xs={12}>
              <Grid container className={classes.productHead}>
                {!hideCompare && (
                  <Grid item xs={1}>
                    <FormattedMessage {...messages.COMPARE} />
                  </Grid>
                )}
                <Grid item xs={2}>
                  <FormattedMessage {...messages.PRODUCT_NO} />
                </Grid>
                <Grid item xs={hideCompare ? 7 : 6}>
                  <FormattedMessage {...messages.DESCRIPTION} />
                </Grid>
                <Grid item xs={1}>
                  <FormattedMessage {...messages.SDS} />
                </Grid>
                <Grid item xs={2} className={classes.alignRight}>
                  <FormattedMessage {...messages.PRICING} />
                </Grid>
              </Grid>
              <Box mb={4}>
                {replacementProducts.map((replacementProduct) => (
                  <ReplacementProduct
                    key={replacementProduct.productNumber}
                    replacementProduct={replacementProduct}
                    alternateMaterials={alternateMaterials}
                    addToList={addToList}
                    showCompare={
                      isMainProductSelectedForCompare && !hideCompare
                    }
                    selectedForCompare={isProductSelectedForCompare(
                      replacementProduct.productNumber,
                      replacementProduct.brand.key
                    )}
                    setSelectedForCompare={(selected) =>
                      setSelectedForCompare(
                        replacementProduct.productNumber,
                        replacementProduct.images,
                        replacementProduct.brand.key,
                        selected
                      )
                    }
                  />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default ReplacementProductsModal
