import React, { useCallback, useState } from 'react'
import { MapTo } from '@adobe/aem-react-editable-components'
import { ProductSelector } from '@src/components/ProductSelector/ProductSelector'
import { AntibodySelectorTool } from './AntibodySelectorTool'
import {
  productSelectorAnalyticsConfig,
  SelectorToolType,
} from '@src/components/ProductSelector/productSelectorAnalyticsConfig'
import { sendSelectorToolEvent } from '@src/utils/analytics'

const ProductSelectorAdapter = () => {
  const [showSelectorTool, setShowSelectorTool] = useState(false)

  const selectorType: SelectorToolType = SelectorToolType.aemPageAntibody

  const selectorAnalyticsConfig = productSelectorAnalyticsConfig(
    selectorType as SelectorToolType,
    {
      productId: '',
      productKey: '',
      productBrandKey: '',
      productName: '',
    }
  )

  const handleShowSelectorTool = useCallback(
    (openType: 'open button' | 'open link') => {
      setShowSelectorTool(true)
      sendSelectorToolEvent(selectorAnalyticsConfig[openType])
    },
    [showSelectorTool, selectorAnalyticsConfig]
  )
  const handleCloseSelectorTool = useCallback(
    (closeType: 'close' | 'ignore') => {
      setShowSelectorTool(false)
      sendSelectorToolEvent(selectorAnalyticsConfig[closeType])
    },
    [showSelectorTool, selectorAnalyticsConfig]
  )

  return (
    <>
      <ProductSelector
        onButtonClick={() => handleShowSelectorTool('open button')}
        onLinkClick={() => handleShowSelectorTool('open link')}
        isAemPageProductSelector
      />
      {showSelectorTool && (
        <AntibodySelectorTool
          onClose={() => handleCloseSelectorTool('close')}
          onDismiss={() => handleCloseSelectorTool('ignore')}
          open
          toolType="antibody"
        />
      )}
    </>
  )
}
export default MapTo('cms-commons/components/content/productselector')(
  ProductSelectorAdapter
)
