import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useRecentlyViewedProductsQuery } from '@src/queries/RecentlyViewedProductsQuery.generated'
import { AnalyticsDispType } from '@src/types/graphql-types'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'
import { ProductCardType } from '@utils/searchUtils'
import DynamicProductCarousel from '@src/components/DynamicProductCarousel'
import clsx from 'clsx'
import { sendEmptyRecommendationEvent } from '@utils/analytics'
import { ErpType } from '@utils/useProductErpType'
import { useCurrentUser } from '@utils/useCurrentUser'

export const useStyles = makeStyles((theme: Theme) => {
  return {
    recentlyViewedContainer: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(14),
      },
    },
  }
})

interface RecentlyViewedProductsCarouselProps {
  dispType: AnalyticsDispType
  className?: string
  productKey?: string
  brand?: string
  searchKey?: string
  parentPageName: string
  parentPageNameDetail: string
  slidesToShow?: number
  setShouldDisplay?: Dispatch<SetStateAction<boolean>>
  condensed?: boolean
}

enum RecentlyViewedDispType {
  recentlyViewedPDP = 'pdp',
  recentlyViewedSRP = 'srp',
}

const RecentlyViewedProductsCarousel: React.FC<
  RecentlyViewedProductsCarouselProps
> = ({
  dispType,
  className,
  productKey = '',
  brand = '',
  searchKey = '',
  parentPageName,
  parentPageNameDetail,
  slidesToShow,
  setShouldDisplay,
  condensed = false,
}) => {
  const classes = useStyles()

  const { isBlueB2BUser } = useCurrentUser()
  const { data, loading } = useRecentlyViewedProductsQuery({
    variables: {
      dispType,
      productKey,
      brand,
      ...(isBlueB2BUser ? { erpType: ErpType.Blue } : {}),
    },
    ssr: false,
    fetchPolicy: 'no-cache',
  })
  const recentlyViewProducts = data?.getRecentlyViewedProducts?.products || []

  const shouldRender = recentlyViewProducts.length > 0

  useEffect(() => {
    if (setShouldDisplay) {
      setShouldDisplay(shouldRender)
    }
  }, [shouldRender, loading])

  useEffect(() => {
    const pageType = RecentlyViewedDispType[dispType]
      ? RecentlyViewedDispType[dispType]
      : ''
    const productSearched =
      RecentlyViewedDispType[dispType] ===
      RecentlyViewedDispType['recentlyViewedPDP']
        ? productKey
        : searchKey
    if (
      data?.getRecentlyViewedProducts?.products?.length === 0 &&
      pageType &&
      productSearched
    ) {
      sendEmptyRecommendationEvent(
        `${pageType} > recently viewed products >  ${productSearched}`
      )
    }
  }, [data])

  // Return null if no recently viewed products are found
  if (loading) return null

  return shouldRender ? (
    <div className={clsx(className, classes.recentlyViewedContainer)}>
      <DynamicProductCarousel
        type={ProductCardType.Recently}
        products={recentlyViewProducts}
        productSKU={productKey}
        parentPageName={parentPageName}
        parentPageNameDetail={parentPageNameDetail}
        slidesToShow={slidesToShow}
        condensed={condensed}
      />
    </div>
  ) : null
}

export default RecentlyViewedProductsCarousel
