import React from 'react'
import { useIntl } from 'react-intl'
import { Sort } from '@src/types/graphql-types'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import LiquidSelect, {
  liquidSelectOptionRenderer,
} from '@src/components/LiquidSelect'
import { SortOption } from './CategorySearchResults'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    sortContainer: {
      width: 250,
      padding: theme.spacing(0, 2),
      display: 'flex',
      float: 'left',
    },
  })
})

interface CSRSortProps {
  loading: boolean
  value: Sort
  options: SortOption[]
  handleUpdateSort: (sort?: Sort | undefined) => void
}

const CSRSort: React.FC<CSRSortProps> = ({
  loading,
  options,
  handleUpdateSort,
  value,
}) => {
  const classes = useStyles()
  const intl = useIntl()
  return (
    <div className={classes.sortContainer}>
      <LiquidSelect
        value={value}
        onChange={(e) => {
          const sort = Object.values(Sort).find((v) => v === e.target.value)
          handleUpdateSort(sort)
        }}
        size="medium"
        menuMaxHeight={284}
        disabled={loading}
      >
        {options.map((sort, index) =>
          liquidSelectOptionRenderer(
            index,
            sort.value,
            intl.formatMessage(sort.label)
          )
        )}
      </LiquidSelect>
    </div>
  )
}

export default CSRSort
