import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { Grid, Theme, Typography } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { CategorySubstanceProductFieldsFragment } from '@src/queries/CategoryProductSearchQuery.generated'
import { MAX_COMPARE_SIZE, useCompareProducts } from '@utils/useCompareProducts'
import DOMPurify from 'isomorphic-dompurify'
import LiquidCheckbox from '@src/components/LiquidCheckbox'
import { useIntl, FormattedMessage } from 'react-intl'
import { productDetailRoute } from '@src/routes'
import { Link } from '@src/components/Link'
import messages from '@utils/messages'
import ViewPricingLink from '@src/components/ProductPricingModal/ViewPricingLink'

interface CSRGridProductCardProps {
  product: CategorySubstanceProductFieldsFragment
}
const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    Card: {
      margin: 0,
      border: `1px solid ${theme.palette.grey['400']}`,
      padding: theme.spacing(4),
      borderRadius: theme.typography.pxToRem(5),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: 1,
      minHeight: '344px',
    },
    CardContainer: {
      display: 'inline-flex',
      paddingBottom: '12px !important',
    },
    checkboxIcon: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 3,
      width: 18,
      height: 18,
      fontSize: theme.typography.pxToRem(4),
      [theme.breakpoints.up('md')]: {
        width: 18,
        height: 18,
      },
    },
    checkboxIconChecked: {
      '& svg': {
        fontSize: theme.typography.pxToRem(10),
      },
    },
    facetOptionLabel: {
      display: 'inline-flex',
      '& svg': {
        fontSize: theme.typography.pxToRem(28),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(1 / 2),
        color: theme.palette.secondary.main,
      },
      '& sup, sub': {
        verticalAlign: 'baseline',
        position: 'relative',
        top: '-0.4em',
      },
      '& sub': {
        top: '0.1em',
      },
    },
    imageWrap: {
      textAlign: 'center',
      '& > img': {
        maxWidth: '94.13px',
        maxHeight: '94.13px',
        aspectRatio: '1/1',
        objectFit: 'contain',
      },
    },
    productNumber: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'uppercase',
      margin: `${theme.spacing(1, 0)} !important`,
      color: theme.palette.primary.main,
    },
    productName: {
      maxHeight: 66,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.13,
      marginBottom: `${theme.spacing(1)}px !important`,
      '& sup, sub': {
        verticalAlign: 'baseline',
        position: 'relative',
        top: '-0.4em',
      },
      '& sub': {
        top: '0.1em',
      },
    },
    productDescription: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      marginBottom: `${theme.spacing(2)}px  !important`,
      minHeight: theme.typography.pxToRem(60),
    },
    clampLines: {
      display: '-webkit-box',
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      wordBreak: 'break-word',
      textOverflow: 'ellipsis',
      WebkitLineClamp: 3,
    },
    productCheckbox: {
      fontSize: theme.typography.pxToRem(16),
      wordBreak: 'break-all',
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        fontSize: theme.typography.pxToRem(14),
        marginTop: 2,
      },
    },
  })
})
const CSRGridProductCard: React.FC<CSRGridProductCardProps> = ({ product }) => {
  const { formatMessage } = useIntl()
  const [isMounted, setIsMounted] = useState(false)
  useEffect(() => setIsMounted(true), [])
  const { compareProducts, addCompareProduct, removeCompareProduct } =
    useCompareProducts()
  const isCompared = compareProducts.some(
    ({ productKey, brandKey }) =>
      productKey === product.productKey && brandKey === product.brand.key
  )
  const compareDisabled = !isMounted
    ? false
    : !isCompared && compareProducts.length >= MAX_COMPARE_SIZE
  const productCompareValues = {
    productKey: product.productKey,
    productNumber: product.productNumber,
    brandKey: product.brand.key,
    images: product.images,
    productName: product.name,
    brandName: product.brand.name,
  }
  const classes = useStyles()

  return (
    <Grid className={classes.CardContainer} item md={4}>
      <figure className={classes.Card}>
        <div className={clsx(classes.productCheckbox)}>
          <LiquidCheckbox
            name={`${formatMessage(messages.COMPARE)}-${product.productNumber}`}
            checked={isMounted && isCompared}
            onChange={(e) => {
              e.target.checked
                ? addCompareProduct(productCompareValues)
                : removeCompareProduct(productCompareValues)
            }}
            aria-label={`${formatMessage(messages.COMPARE)} ${
              product.productNumber
            }`}
            disabled={compareDisabled}
            label={`${formatMessage(messages.COMPARE)}`}
          />
        </div>
        <div className={classes.imageWrap}>
          <img src={product.images[0].mediumUrl} alt={product.name} />
        </div>
        {product.brand.key && product.productNumber ? (
          <Link
            {...productDetailRoute.index(product.brand.key, product.productKey)}
          >
            <a className={classes.productNumber}>
              {product.productNumber.startsWith('CB_')
                ? formatMessage(messages.DETAILS)
                : product.productNumber}
            </a>
          </Link>
        ) : (
          <>
            {product.productNumber.startsWith('CB_')
              ? formatMessage(messages.DETAILS)
              : product.productNumber}
          </>
        )}
        <Typography className={clsx(classes.productName, classes.clampLines)}>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                product.legalName || product.name || ''
              ),
            }}
          />
        </Typography>
        <Typography
          className={clsx(classes.productDescription, classes.clampLines)}
        >
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(
                product?.legalNameSuffix || product?.description || ''
              ),
            }}
          />
        </Typography>
        <ViewPricingLink
          product={product}
          focus={''}
          typeButtonText={
            <FormattedMessage id="QUICK_VIEW" defaultMessage="Quick View" />
          }
          ga4Payload={{
            component: 'grid',
            elementType: 'button',
            elementText: 'quick view',
          }}
        />
      </figure>
    </Grid>
  )
}

export default CSRGridProductCard
