import React from 'react'
import { ErrorProps } from 'next/error'
import Error404 from '@src/routes/Errors/Error404'
import GenericError from '@src/routes/Errors/GenericError'
import Simple404 from '@src/routes/Errors/Simple404'
import { NextComponentType, NextPageContext } from 'next'
import SDSError from '@src/components/SDSLink/SDSError'

export interface ErrorScreenControlProps extends ErrorProps {
  basicError?: boolean
}

const ErrorPage: NextComponentType<
  NextPageContext,
  {},
  ErrorScreenControlProps
> = ({ statusCode, title, basicError }) => {
  // If cms handler caught a missing file error, show Simple 404 Error component...
  if (basicError) {
    return <Simple404 statusCode={404} />
  }
  // Else if missing SDS error, show SDS 404 Error component...
  if (title && title.includes('/sds/')) {
    return <SDSError />
  }
  // Else if other 404 error, show Global 404 Error component...
  if (statusCode === 404) {
    return <Error404 statusCode={statusCode} />
  }
  // Else generic error, show Generic Error component
  return <GenericError statusCode={statusCode} />
}

ErrorPage.getInitialProps = ({ res, err, asPath }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404
  const title = asPath
  return { statusCode, title }
}

export default ErrorPage
