import { QueryResult } from '@src/utils/QueryResult'
import { ProductPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import { MaterialsDetailFragment } from '@src/queries/MaterialDetailsQuery.generated'

//Looks for common errors and returns an object with those errors if they exist
export function getUpdatedPricingErrors(
  materialsToAdd: { materialNumber: string }[],
  pricingCache: React.MutableRefObject<
    Partial<{
      [materialNumber: string]: QueryResult<
        ProductPricingDetailFragment,
        unknown
      >
    }>
  >,
  materialsCache: Partial<{
    [materialNumber: string]: QueryResult<MaterialsDetailFragment, unknown>
  }>
) {
  const updatedPricingErrors = {}

  materialsToAdd.forEach(({ materialNumber }) => {
    const res = pricingCache.current[materialNumber]
    if (res?.loading || materialsCache[materialNumber]?.loading) {
      return
    }
    const material = res?.data
    if (!material) {
      updatedPricingErrors[materialNumber] = {
        errorMsg: 'UNABLE_TO_GET_RESPONSE_FROM_PI',
      }
    } else if (material.discontinuedPricingInfo) {
      updatedPricingErrors[material.productNumber] =
        material.discontinuedPricingInfo
    } else if (
      material.materialPricing.length === 0 ||
      !material.materialPricing.find(
        (x: { materialNumber: string }) => x.materialNumber === materialNumber
      )
    ) {
      updatedPricingErrors[material.productNumber] = {
        errorMsg: 'PRICING_AND_AVAILABILITY_UNAVAILABLE',
      }
    }
  })

  return updatedPricingErrors
}
