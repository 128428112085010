import * as ApolloReactHooks from '@apollo/react-hooks'
import * as Types from '@src/types/graphql-types'
import {
  AddUserActivityDocument,
  AddUserActivityMutation,
  AddUserActivityMutationVariables,
} from '@src/mutations/AddUserActivityMutation.generated'
import {
  UserActivityDocument,
  UserActivityQuery,
  UserActivityQueryVariables,
} from '@src/queries/UserActivityQuery.generated'

export type TrackingDataTypes = {
  RECENTLY_VIEWED: {
    // The user activity service was identified in CX-1905 as a means of implementing the Recently Viewed pages feature
  }
  WALK_ME_INVITE_COWORKER_RESULTS: {
    numWalkMeDismissals?: number
    hasOptedOutOfWalkMe?: boolean
    hasCompletedWalkMe?: boolean
  }

  WALK_ME_MY_NETWORK_RESULTS: {
    numWalkMeDismissals?: number
    hasOptedOutOfWalkMe?: boolean
    hasCompletedWalkMe?: boolean
  }

  READY_TO_SHIP_NOTIFICATIONS: {
    numWalkMeDismissals?: number
    hasOptedOutOfWalkMe?: boolean
    hasCompletedWalkMe?: boolean
  }

  WALK_ME_MANAGE_LIST_MEMBERS_OWNER: {
    numWalkMeDismissals?: number
    hasOptedOutOfWalkMe?: boolean
    hasCompletedWalkMe?: boolean
  }
}

type TrackingDataMap = {
  [K in keyof TrackingDataTypes]: {
    featureType: K
    trackingData: TrackingDataTypes[K]
  }
}

export type TrackingData = TrackingDataMap[keyof TrackingDataMap]

export const isFeatureType =
  <K extends keyof TrackingDataMap>(k: K) =>
  (t: TrackingData): t is TrackingDataMap[K] =>
    t.featureType === k

type TypedUserActivityQuery = {
  [K in keyof UserActivityQuery]: K extends 'getUserActivity'
    ? TrackingData[]
    : UserActivityQuery[K]
}

export function useTypedUserActivityQuery(
  options?: ApolloReactHooks.QueryHookOptions<
    TypedUserActivityQuery,
    UserActivityQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    TypedUserActivityQuery,
    UserActivityQueryVariables
  >(UserActivityDocument, options)
}

type TypedAddUserActivityMutation = {
  [K in keyof AddUserActivityMutation]: K extends 'addUserActivity'
    ? TrackingData
    : AddUserActivityMutation[K]
}

type TypedAddUserActivityMutationVariables = Types.Exact<{
  [K in keyof AddUserActivityMutationVariables]: K extends 'input'
    ? Types.InputMaybe<TrackingData>
    : AddUserActivityMutationVariables[K]
}>

export function useTypedAddUserActivityMutation(
  options?: ApolloReactHooks.MutationHookOptions<
    TypedAddUserActivityMutation,
    TypedAddUserActivityMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    TypedAddUserActivityMutation,
    TypedAddUserActivityMutationVariables
  >(AddUserActivityDocument, options)
}
