import React, { FC } from 'react'
import { Radio } from '@material-ui/core'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    icon: {
      display: 'flex',
      width: '24px',
      height: '24px',
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: '50%',
      [theme.breakpoints.up('md')]: {
        width: '18px',
        height: '18px',
      },
    },
    checkedIcon: {
      backgroundColor: '#ffffff',
      '&:before': {
        display: 'block',
        width: '14px',
        height: '14px',
        backgroundColor: `${theme.palette.primary.main}`,
        borderRadius: '50%',
        transform: 'translateY(4px) transLateX(4px)',
        content: '""',
        [theme.breakpoints.up('md')]: {
          width: '8px',
          height: '8px',
        },
      },
    },
  })
})

interface RadioButtonProps {
  className?: string
}

const RadioButton: FC<RadioButtonProps> = (props) => {
  const classes = useStyles()

  return (
    <Radio
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      {...props}
    />
  )
}

export default RadioButton
