import { CertificateType } from '@src/types/graphql-types'
import { QmDocumentType } from '@src/utils/analytics/enums'

export const CertificateName = {
  [CertificateType.Coa]: 'CERTIFICATE_OF_ANALYSIS',
  [CertificateType.Coo]: 'CERTIFICATE_OF_ORIGIN',
  [CertificateType.Coq]: 'CERTIFICATE_OF_QUALITY',
}

export const getCooOrCoqType = (isBrandMM: boolean) => {
  return isBrandMM ? CertificateType.Coq : CertificateType.Coo
}

/**
 * Converts a PDF content string into a Blob and triggers a download of the PDF file.
 *
 * @param {string} pdfString - The content of the PDF as a string.
 * @param {string} [filename] - The desired name for the downloaded file. If not provided, defaults to 'download'.
 */

export const downloadPDF = (pdfString: string, filename?: string) => {
  const pdfArray = new Uint8Array(
    Array.from(pdfString, (char: string) => char.charCodeAt(0))
  )

  const pdfBlob = new Blob([pdfArray], { type: 'application/pdf' })
  const pdfUrl = URL.createObjectURL(pdfBlob)

  const link = document.createElement('a')
  link.href = pdfUrl

  const sanitizedFilename = filename?.replace(/\./g, '-') || 'download'
  link.setAttribute('download', sanitizedFilename)
  link.click()

  // clean up the object URL
  URL.revokeObjectURL(pdfUrl)
}

export const documentTypeMapper = {
  [QmDocumentType.CofAnalysisDoc]: CertificateType.Coa,
  [QmDocumentType.CofOriginDoc]: CertificateType.Coo,
  [QmDocumentType.CofQualityDoc]: CertificateType.Coq,
}
