import { Theme, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  mainNav: {
    height: '100%',
  },
  mainModal: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    outline: 'none !important',
    padding: '15px 16px 30px',
    zIndex: 2,
    '&:before': {
      content: '""',
      width: '100%',
      position: 'absolute',
      top: '-48px', // matches height of headerBottom
      left: 0,
      height: '48px', // matches height of headerBottom
      pointerEvents: 'none',
      boxShadow: '0 3px 8px 0 rgba(0, 0, 0, 0.16)',
    },
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      boxShadow: '0 6px 12px 0 rgba(0, 0, 0, 0.16)',
      zIndex: '-1',
    },
    '&$smallMenu': {},
    '&$smallMenu:before': {
      display: 'none',
    },
  },
  fullWidthWrapper: {
    maxWidth: '1280px',
    margin: '0 auto',
    outline: 'none !important',
  },
  isHidden: {
    display: 'none',
  },
  isVisible: {
    display: 'block',
  },
  smallWrapper: {
    outline: 'none !important',
    minWidth: '284px',
    margin: '-8px 0 -8px -20px',
  },
  smallMenu: {},
  noBorder: {},
  subNavContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '8px 0',
    borderRight: '2px solid rgba(201,201,201,0.25)',
    '&$noBorder': {
      borderRight: 'none',
    },
  },
  closeIcon: {
    minWidth: 24,
    width: 24,
    height: 24,
    padding: 0,
    color: theme.palette.text.primary,
    margin: '0 14px 0 auto',
  },
  closeIconSVG: {
    fontSize: '24px',
  },

  additionalHeading: {
    width: '100%',
    fontSize: theme.typography.pxToRem(16),
    textAlign: 'left',
    fontWeight: 900,
    padding: '8px 14px 8px 20px',
  },
  imageWrapper: {
    margin: '0 14px 32px 20px',
  },
  image: {
    display: 'block',
    width: '100%',
    marginBottom: '8px',
  },
  imageLink: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 900,
  },
}))

export default useStyles
