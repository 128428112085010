import React from 'react'
import Container from '@material-ui/core/Container'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Button, Theme, Typography, Grid } from '@material-ui/core'
import { Link } from '@src/components/Link'

import DefaultLayout from '../DefaultLayout'
import { FormattedMessage } from 'react-intl'
import messages from '../../utils/messages'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(28),
      margin: '24px 0',
    },
    layoutContainer: {
      minHeight: 400,
      padding: 0,
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 4),
      },
    },
    sdsButton: {
      marginTop: 32,
      padding: '8px 26px',
    },
  })
)

const SDSError = () => {
  const classes = useStyles()

  return (
    <DefaultLayout className={'classes.root'}>
      <Container maxWidth="lg" className={classes.layoutContainer}>
        <Grid container>
          <Grid item xs={12}>
            <h1 className={classes.title}>
              <FormattedMessage {...messages.SDS_NOT_FOUND} />
            </h1>
          </Grid>
          <Grid item md={10} xs={12}>
            <Typography variant="body1" component="p">
              <FormattedMessage {...messages.SDS_NOT_FOUND_MESSAGE} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Link href={'/support/customer-support?selected=tfa_12'} passHref>
              <Button
                className={classes.sdsButton}
                variant="contained"
                color="primary"
              >
                <FormattedMessage {...messages.REQUEST_SDS} />
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </DefaultLayout>
  )
}

export default SDSError
