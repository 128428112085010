import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react'

import { MaterialsInput } from '../Types'

export interface PendingItem {
  id: number
  materialNumber: string
  quantity: number
}
export interface QuickOrdersContextData {
  addItems: boolean
  pendingItems: PendingItem[]
  setAddItems: Dispatch<SetStateAction<boolean>>
  setPendingItems: Dispatch<SetStateAction<PendingItem[]>>
}

export const quickOrdersContextDefaultValue: QuickOrdersContextData = {
  addItems: false,
  pendingItems: [],
  setAddItems: () => {
    // Added comment for avoid es-lint error
  },
  setPendingItems: () => {
    // Added comment for avoid es-lint error
  },
}

export const QuickOrdersContext = createContext<QuickOrdersContextData>(
  quickOrdersContextDefaultValue
)

export const QuickOrderProvider: FC<{}> = (props) => {
  const [addItems, setAddItems] = useState(false)
  const [pendingItems, setPendingItems] = useState<PendingItem[]>([])

  return (
    <QuickOrdersContext.Provider
      value={{ addItems, pendingItems, setAddItems, setPendingItems }}
      {...props}
    />
  )
}

export const useQuickOrder = () => {
  const { addItems, pendingItems, setAddItems, setPendingItems } =
    useContext(QuickOrdersContext)

  const mergedQuickOrderItems = (
    currentItems: MaterialsInput[],
    defaultMaterial: MaterialsInput
  ) => {
    const actualMaterials = currentItems.filter(
      (material) => material.materialNumber
    )
    const pendingMaterials = pendingItems
      .sort((a, b) => (a.id > b.id ? 1 : -1))
      .map((item) => {
        return {
          materialNumber: item.materialNumber,
          quantity: item.quantity,
        }
      })

    return actualMaterials.length + pendingMaterials.length >=
      currentItems.length
      ? actualMaterials.concat(pendingMaterials)
      : actualMaterials.concat(pendingMaterials).concat(
          Array(
            currentItems.length -
              (actualMaterials.length + pendingMaterials.length)
          )
            .fill(0)
            .map(() => defaultMaterial)
        )
  }

  const resetPendingItems = () => {
    setAddItems(false)
    setPendingItems([])
  }

  const updatePendingItems = (id: number, number: string, quantity: number) => {
    setPendingItems(
      pendingItems.some((item) => item.id === id)
        ? [...pendingItems].filter((item) => item.id !== id)
        : [
            ...pendingItems,
            {
              id: id,
              materialNumber: number,
              quantity: quantity,
            },
          ]
    )
  }

  return {
    addItems,
    mergedQuickOrderItems,
    pendingItems,
    resetPendingItems,
    setAddItems,
    setPendingItems,
    updatePendingItems,
  }
}
