import React, { useRef, useState, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import { FormattedMessage, MessageDescriptor } from 'react-intl'
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles'
import { Sort } from '@src/types/graphql-types'
import {
  Button,
  ButtonGroup,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps'
import MenuIcon from '@material-ui/icons/Menu'
import HandleMarkup from '@src/components/HandleMarkup'
import CSRResultCount from './CSRResultCount'
import CSRFacets from './CSRFacets'
import ResponsiveCSRProductTable from './ResponsiveCSRProductTable'
import useCategoryProductSearch, {
  FacetSelection,
} from './useCategoryProductSearch'
import CSRMobileActionsModal from './CSRMobileActionsModal'
import FilterAndSortIcon from '@src/icons/FilterAndSortIcon'
import CSRSort from './CSRSort'
import messages from '@src/utils/messages'
import CSRPagination from './CSRPagination'
import CSRGridProductList from './CSRGridProductList'
import { sessionStorage } from 'react-storage'
import { Skeleton } from '@material-ui/lab'
import CSRSearchField from './CSRSearchField'
import { sendPricingAvailabilityInteractionEvent } from '@utils/analytics/pricingAndAvailability'
import { useGetRecommendedProductsAltQuery } from '@src/queries/GetRecommendedProductsAlt.generated'
import DynamicProductCarousel from '@src/components/DynamicProductCarousel'
import useAemStaticContentOptlyCarousel from '@src/aem-content/components/AemStaticContent/useAemStaticContentOptlyCarousel'

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    productSearchWrapper: {
      display: 'flex',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
    },
    productSearchWrapperLoading: {
      opacity: '40%',
    },
    facetsWrapper: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
        width: 270,
        paddingRight: theme.spacing(8),
        marginTop: theme.spacing(2),
      },
    },
    resultsWrapper: {
      flex: 1,
      margin: theme.spacing(0, -5),
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
    },
    actionsRow: {
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(8),
      },
    },
    searchInput: {
      padding: theme.spacing(0, 2, 0, 0),
    },
    resultsCount: {
      padding: theme.spacing(0, 5, 4),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0, 0, 0, 2),
      },
    },
    topActions: {},
    desktopActions: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block',
      },
    },
    mobileActions: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    mobileFilterTrigger: {
      margin: theme.spacing(0, 5, 4),
    },
    mobileFilterIcon: {
      fontSize: theme.typography.pxToRem(24),
      marginRight: theme.spacing(2),
    },
    modalBody: {
      overflow: 'scroll',
      padding: theme.spacing(6, 4),
    },
    modalFooter: {
      padding: theme.spacing(6, 4),
    },
    paginationContainer: {},
    bottomActions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      margin: theme.spacing(6, 5, 0),
      [theme.breakpoints.up('md')]: {
        justifyContent: 'space-between',
        margin: theme.spacing(4, 0, 0),
        display: 'flex',
        flexDirection: 'row',
      },
    },
    viewToggleButton: {
      borderRadius: '2px',
      padding: theme.spacing(1),
      minWidth: '30px',
      border: `1px solid ${theme.palette.grey[400]}`,
    },
    alignRight: {
      textAlign: 'right',
    },
    toggleLabel: {
      fontSize: theme.typography.pxToRem(14),
      marginRight: theme.spacing(2),
      verticalAlign: 'text-bottom',
    },
    resultsCountMargin: {
      display: 'flex',
      marginTop: theme.spacing(2),
    },
    // Cleaning up styles when the carousel is on an AEM page.
    aemCarousel: {
      '& .MuiContainer-root': { padding: 0 },
      '& .MuiContainer-root > div': {
        paddingTop: 'unset',
      },
      '& p': {
        margin: 'unset',
      },
    },
  })
})

interface CategorySearchResultsProps {
  anchorId?: string
  title?: string
  maxRows: number
  searchTerm: string
  additionalColumns: AdditionalColumn[]
  authorSelectedFacets: string[]
  defaultSort?: Sort
  authorCuratedFacets?: string[]
  hideSearchBar?: boolean
  openFacets?: number
}

export interface AdditionalColumn {
  text: string
  value: string
}

export interface SortOption {
  value: string
  label: MessageDescriptor
}

enum ViewPreference {
  grid,
  list,
}

const SORT_OPTIONS: SortOption[] = [
  {
    value: Sort.Relevance,
    label: messages.SORT_BY_RELEVANCE,
  },

  {
    value: Sort.Nameasc,
    label: messages.SORT_BY_NAME_ASCENDING,
  },
  {
    value: Sort.Namedesc,
    label: messages.SORT_BY_NAME_DESCENDING,
  },
]

const CategorySearchResults: React.FC<CategorySearchResultsProps> = ({
  title,
  maxRows,
  searchTerm,
  authorSelectedFacets,
  authorCuratedFacets,
  defaultSort,
  additionalColumns,
  openFacets = 0,
  hideSearchBar,
  ...props
}) => {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const classes = useStyles()
  const csrRef = useRef<HTMLDivElement>(null)
  const [mobileActionsModalOpen, setMobileActionsModalOpen] = useState(false)
  const anchorId = props.anchorId || 'csr'

  // Initialize state with the saved view preference or default to ViewPreference.list
  const [view, setView] = useState<ViewPreference | null>(null)

  useEffect(() => {
    // Retrieve the saved view preference from session storage on component mount
    const savedViewPreference = sessionStorage.getItem(
      'selectedView'
    ) as ViewPreference | null
    setView(savedViewPreference ?? ViewPreference.list)
  }, [])

  const {
    loading,
    error,
    facets,
    page,
    itemCount,
    numPages,
    pageStart,
    pageEnd,
    products,
    sort,
    term,
    searched,
    updatePage,
    updateSort,
    updateFacets,
    updateSearchTerm,
    applyFacets,
  } = useCategoryProductSearch({
    searchTerm,
    authorSelectedFacets,
    authorCuratedFacets,
    defaultSort,
    defaultPerPage: maxRows,
  })
  const handleScrollTop = () => {
    if (csrRef.current) {
      window.scrollTo({
        left: 0,
        top: csrRef.current.offsetTop - 100,
        behavior: 'smooth',
      })
    }
  }
  const handleUpdatePage = useCallback(
    (page) => {
      handleScrollTop()
      updatePage(page)
    },
    [updatePage]
  )
  const handleUpdateSort = useCallback(
    (sort) => {
      handleScrollTop()
      updateSort(sort)
    },
    [updateSort]
  )
  const handleUpdateFacets = useCallback(
    (facet, clearAll?) => {
      handleScrollTop()
      updateFacets(facet, clearAll)
    },
    [updateFacets]
  )
  const handleUpdateSearchTerm = useCallback(
    (str) => {
      handleScrollTop()
      updateSearchTerm(str)
    },
    [updateSearchTerm]
  )

  if (!loading && products.length === 0 && !!term && term !== searched) {
    updateSearchTerm('', term) // sets the param &term='' and &searched={term}
  }
  // Client-side parsing of location.hash (doesn't get sent to server request) for dynamic linking
  // of in-page routing of filters.
  // For example, we will see something like:
  //   /US/en/{some-page}#csr?facets=facet_product_category:needles
  //
  const checkHashForFacets = () => {
    if (
      typeof window === 'undefined' ||
      !window.location.hash.includes('#' + anchorId)
    )
      return

    window.location.hash
      .split('&')
      .filter((q) => q.includes('facets='))
      .filter((q, idx) => idx === 0)
      .forEach((q) => {
        const facets: FacetSelection[] = decodeURIComponent(q.split('=')[1])
          .replace(/\+/g, ' ')
          .split(',')
          .map((facet) => {
            const keyVal = facet.split(':')
            return {
              key: keyVal[0],
              value: keyVal[1],
              prefix: null,
            }
          })

        facets.length > 0 && applyFacets(facets)
      })

    handleScrollTop()
  }

  const handleSelectView = (selectedView: ViewPreference) => {
    setView(selectedView)
    sessionStorage.setItem('selectedView', selectedView)
    sendPricingAvailabilityInteractionEvent({
      action: `switch to ${view ? 'box' : 'list'} view`,
      section: 'p&a',
      component: 'body',
      elementType: 'icon',
      elementText: 'change view',
    })
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    checkHashForFacets()

    window.addEventListener('hashchange', checkHashForFacets)
    return () => {
      window.removeEventListener('hashchange', checkHashForFacets)
    }
  }, [])

  // Get A/B testing Carousel Data
  const { model, carouselTitle, carouselEndpoint } =
    useAemStaticContentOptlyCarousel('optly-abTestingCategoryCarousel')

  const { data: carouselData } = useGetRecommendedProductsAltQuery({
    variables: {
      input: {
        get: carouselEndpoint,
        model: model,
      },
    },
    errorPolicy: 'all',
    ssr: false,
    skip: model === '0' || !carouselEndpoint,
  })

  const carouselProducts = carouselData?.getRecommendedProductsAlt?.products

  if (error || products.length === 0) {
    return <div id={anchorId} ref={csrRef}></div>
  }
  if (view === null) {
    // Optionally, return a loader or null while the view preference is being loaded
    return (
      <div id={anchorId}>
        <Skeleton width="100%" variant="rect" height={150} animation="wave" />
        <Skeleton width="50%" />
        <Skeleton width="50%" />
      </div>
    )
  }

  return (
    <>
      {carouselProducts?.length && carouselTitle ? (
        <div className={classes.aemCarousel}>
          <DynamicProductCarousel
            type={carouselTitle}
            products={carouselProducts}
          />
        </div>
      ) : (
        '' // need this otherwise it outputs a 0
      )}
      <div id={anchorId} ref={csrRef} className="aem-ms-cmp">
        {title ? (
          <Typography variant="h2">
            <HandleMarkup value={title} />
          </Typography>
        ) : null}
        <div
          className={clsx(classes.productSearchWrapper, {
            [classes.productSearchWrapperLoading]: loading,
          })}
        >
          <div className={classes.facetsWrapper}>
            <CSRFacets
              loading={loading}
              facets={facets}
              handleUpdateFacets={handleUpdateFacets}
              openFacets={openFacets}
              term={term}
              handleUpdateSearchTerm={handleUpdateSearchTerm}
            />
          </div>
          <div className={classes.resultsWrapper}>
            <div className={classes.topActions}>
              <div className={classes.desktopActions}>
                <div className={classes.actionsRow}>
                  <Grid container>
                    <Grid item md={9}>
                      <Grid container>
                        <Grid item>
                          {!hideSearchBar && (
                            <div className={classes.searchInput}>
                              <CSRSearchField
                                handleUpdateSearchTerm={handleUpdateSearchTerm}
                              />
                            </div>
                          )}
                        </Grid>
                        <Grid item>
                          <CSRSort
                            loading={loading}
                            value={sort}
                            options={SORT_OPTIONS}
                            handleUpdateSort={handleUpdateSort}
                          />
                        </Grid>
                        <Grid item>
                          <div
                            className={clsx(
                              classes.resultsCount,
                              classes.resultsCountMargin
                            )}
                          >
                            {itemCount && pageEnd && (
                              <CSRResultCount
                                itemCount={itemCount}
                                pageStart={pageStart}
                                pageEnd={pageEnd}
                              />
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={3} className={classes.alignRight}>
                      <Typography
                        component="span"
                        className={classes.toggleLabel}
                      >
                        <FormattedMessage
                          id="CHANGE_VIEW"
                          defaultMessage="Change View"
                        />
                      </Typography>
                      <ButtonGroup size="small">
                        <Button
                          variant={
                            view === ViewPreference.list
                              ? 'contained'
                              : undefined
                          }
                          color="primary"
                          onClick={() => handleSelectView(ViewPreference.list)}
                          className={classes.viewToggleButton}
                        >
                          <MenuIcon />
                        </Button>
                        <Button
                          variant={
                            view === ViewPreference.grid
                              ? 'contained'
                              : undefined
                          }
                          color="primary"
                          onClick={() => handleSelectView(ViewPreference.grid)}
                          className={classes.viewToggleButton}
                        >
                          <AppsIcon />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </div>
              </div>
              <div className={classes.mobileActions}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.mobileFilterTrigger}
                  onClick={() => setMobileActionsModalOpen(true)}
                >
                  <FilterAndSortIcon
                    className={classes.mobileFilterIcon}
                    fontSize="inherit"
                    color="primary"
                  />
                  <FormattedMessage {...messages.FILTER_AND_SORT_HEADING} />
                </Button>
                <div className={classes.resultsCount}>
                  {itemCount && pageEnd && (
                    <CSRResultCount
                      itemCount={itemCount}
                      pageStart={pageStart}
                      pageEnd={pageEnd}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              {view === ViewPreference.grid && isDesktop ? (
                <CSRGridProductList products={products} />
              ) : (
                <ResponsiveCSRProductTable
                  products={products}
                  additionalColumns={additionalColumns}
                />
              )}
            </div>
            <div className={classes.bottomActions}>
              {itemCount && pageEnd && (
                <CSRResultCount
                  itemCount={itemCount}
                  pageStart={pageStart}
                  pageEnd={pageEnd}
                  isBottomCount
                />
              )}
              <CSRPagination
                loading={loading}
                numPages={numPages}
                currentPage={page}
                handleUpdatePage={handleUpdatePage}
              />
            </div>
          </div>
        </div>
      </div>
      {mobileActionsModalOpen && facets && (
        <CSRMobileActionsModal
          loading={loading}
          itemCount={itemCount}
          facets={facets}
          sort={sort}
          handleClose={() => setMobileActionsModalOpen(false)}
          sortOptions={SORT_OPTIONS}
          term={term}
          handleUpdateFacets={handleUpdateFacets}
          handleUpdateSearchTerm={handleUpdateSearchTerm}
          handleUpdateSort={handleUpdateSort}
        />
      )}
    </>
  )
}

export default CategorySearchResults
