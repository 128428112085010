import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { InviteCoWorkerWalkMeProvider } from '@src/routes/InviteCoWorker/InviteCoWorkerWalkMe'
import { MyNetworkWalkMeProvider } from '@src/routes/Profile/MyNetwork/MyNetworkWalkMe'

import { useRouter } from '@src/routes'

interface GlobalModalContextValue {
  setGlobalModalComponent: Dispatch<SetStateAction<JSX.Element | undefined>>
}

const GlobalModalContext = React.createContext<GlobalModalContextValue>({
  setGlobalModalComponent: () => null,
})

export const GlobalModalProvider = (props) => {
  const router = useRouter()
  const [globalModalComponent, setGlobalModalComponent] = useState<
    JSX.Element | undefined
  >(undefined)

  useEffect(() => {
    const dismissModalOnRouteChange = () => {
      setGlobalModalComponent(undefined)
    }

    router.events.on('routeChangeStart', dismissModalOnRouteChange)

    // If the component is unmounted, unsubscribe from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', dismissModalOnRouteChange)
    }
  }, [])

  const value = React.useMemo(
    () => ({ globalModalComponent, setGlobalModalComponent }),
    [globalModalComponent, setGlobalModalComponent]
  )

  return (
    <GlobalModalContext.Provider value={value} {...props}>
      <InviteCoWorkerWalkMeProvider>
        <MyNetworkWalkMeProvider>
          {props.children}
          {globalModalComponent ? globalModalComponent : null}
        </MyNetworkWalkMeProvider>
      </InviteCoWorkerWalkMeProvider>
    </GlobalModalContext.Provider>
  )
}

export const useGlobalModal = () => {
  const { setGlobalModalComponent } = React.useContext(GlobalModalContext)
  const clearGlobalModalComponent = () => setGlobalModalComponent(undefined)

  return { setGlobalModalComponent, clearGlobalModalComponent }
}
