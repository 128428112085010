import React from 'react'
import clsx from 'clsx'
import InputBase, { InputBaseProps } from '@material-ui/core/InputBase'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(16),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[500]}`,
      color: theme.palette.text.primary,
      transition: 'all .3s',
      '&:hover:not($disabled):not($error)': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 6px 0 ${theme.palette.primary.main}`,
      },
    },
    input: {
      height: 46,
      boxSizing: 'border-box',
      padding: '4px 12px 6px',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.up('sm')]: {
        height: 38,
      },
      '&[type=date]::-webkit-clear-button': {
        display: 'none',
      },
      '&[type=date]::-webkit-inner-spin-button': {
        display: 'none',
      },
      '&[type=date]::-webkit-calendar-picker-indicator': {
        margin: 0,
      },
      // Todo: investigate this hack for noto-sans
      '&[type=password]': {
        fontFamily: 'sans-serif',
        fontSize: 16,
        letterSpacing: 1,
        padding: '8px 12px 6px',
      },
      '&::placeholder': {
        color: theme.palette.grey[500],
      },
      '&::-ms-clear': {
        display: 'none',
      },
    },
    focused: {
      '&:not($error)': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 6px 0 ${theme.palette.primary.main}`,
      },
    },
    filled: {
      backgroundColor: theme.palette.common.white,
    },
    inputSmall: { height: 34 },
    inputLarge: { fontSize: theme.typography.pxToRem(14), height: 46 },
    multiline: { height: 'auto' },
    disabled: {
      backgroundColor: theme.palette.action.disabledBackground,
      borderColor: theme.palette.action.disabledBackground,
      color: theme.palette.common.black,
    },
    error: {
      border: `solid 1px ${theme.palette.error.main}`,
      boxShadow: `inset 0 0 0 1px ${theme.palette.error.main}`,
    },
    // Fixes for IE11 specific issues
    '@media all and (-ms-high-contrast:none)': {
      root: {
        '& .MuiInputAdornment-root': {
          margin: 0,
        },
      },
    },
  })
)

export interface LiquidInputProps extends InputBaseProps {
  filled?: boolean
  size?: 'small' | 'medium' | 'large'
  placeholder?: string
  fullWidth?: boolean
  error?: boolean
  inputClass?: string
  readOnly?: boolean
}

const LiquidInput: React.FC<LiquidInputProps> = ({
  filled = false,
  size = 'medium',
  placeholder,
  fullWidth = true,
  error,
  inputClass,
  multiline,
  readOnly,
  ...otherProps
}) => {
  const classes = useStyles()

  return (
    <InputBase
      classes={{
        root: clsx(classes.root, filled && classes.filled),
        input: clsx(
          classes.input,
          size === 'small' && classes.inputSmall,
          size === 'large' && classes.inputLarge,
          multiline && classes.multiline,
          inputClass
        ),
        focused: classes.focused,
        error: classes.error,
        disabled: classes.disabled,
      }}
      placeholder={placeholder}
      readOnly={readOnly}
      fullWidth={fullWidth}
      error={error}
      multiline={multiline}
      {...otherProps}
    />
  )
}

export default LiquidInput
