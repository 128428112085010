import React from 'react'
import clsx from 'clsx'
import NativeSelect from '@material-ui/core/NativeSelect'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Theme, MenuProps, Select, SelectProps } from '@material-ui/core'
import CaretDownIcon from '@src/icons/CaretDownIcon'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      border: 'none',
      boxShadow: `inset 0 0 0 1px ${theme.palette.grey['500']}`,
      transition: 'all .3s',
      minHeight: 48,
      boxSizing: 'border-box',
      fontSize: theme.typography.pxToRem(16),
      lineHeight: '40px',
      [theme.breakpoints.up('sm')]: {
        minHeight: 40,
        lineHeight: '32px',
      },
      '&$select': {
        padding: '4px 32px 4px 12px',
      },

      '&:hover:not($disabled)': {
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}`,
      },
      // custom focus styles - TODO: Move to theme some day so all selects can share
      '&:focus': {
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        boxShadow: `inset 0 0 0 1px ${theme.palette.primary.main}, 0 0 6px 0 rgba(15, 105, 175, 0.5)`,
      },
      '&$error': {
        boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}`,
        '&:hover': { boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}` },
        '&:focus': { boxShadow: `inset 0 0 0 2px ${theme.palette.error.main}` },
      },
    },
    filled: {
      backgroundColor: theme.palette.background.grey,
      border: `1px solid ${theme.palette.background.grey}`,
      '&:focus': {
        backgroundColor: theme.palette.background.grey,
      },
    },
    icon: {
      height: 6,
      color: theme.palette.primary.main,
      right: 16,
      top: 21,
      fontSize: theme.typography.pxToRem(10),
      [theme.breakpoints.up('sm')]: {
        top: 17,
      },
    },
    iconSmall: {
      [theme.breakpoints.up('sm')]: {
        top: 13,
        right: theme.spacing(3),
        fontSize: theme.typography.pxToRem(9),
      },
    },
    iconDisabled: {
      color: theme.palette.text.disabled,
    },
    selectSmall: {
      [theme.breakpoints.up('sm')]: {
        minHeight: 32,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: '23px',
        '&$select': {
          padding: '4px 28px 4px 12px',
        },
      },
    },
    selectLarge: {
      fontSize: theme.typography.pxToRem(14),
      minHeight: 48,
      lineHeight: '38px',
      [theme.breakpoints.up('sm')]: {
        lineHeight: '40px',
      },
      '& ~ svg': {
        top: '21px',
      },
    },
    error: {},
    disabled: {
      backgroundColor: theme.palette.grey['300'],
      borderColor: theme.palette.grey['300'],
      color: theme.palette.common.black,
    },
    paper: (props: { menuMaxHeight: LiquidSelectProps['menuMaxHeight'] }) => ({
      border: `1px solid ${theme.palette.grey['500']}`,
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(1),
      maxHeight: props.menuMaxHeight,
    }),
    menuList: {
      '& .MuiMenuItem-root': {
        whiteSpace: 'break-spaces',
      },
    },
    menuListSmall: {
      padding: theme.spacing(1, 0),
      '& .MuiMenuItem-root': {
        fontSize: theme.typography.pxToRem(14),
        padding: theme.spacing(1, 3),
      },
    },
  })
)

export interface LiquidSelectProps extends SelectProps {
  filled?: boolean
  size?: 'small' | 'medium' | 'large'
  native?: boolean
  menuMaxHeight?: number
}

const LiquidSelect: React.FC<LiquidSelectProps> = ({
  children,
  filled = false,
  size = 'medium',
  native = false,
  placeholder,
  fullWidth = true,
  error,
  disabled,
  menuMaxHeight,
  ...otherProps
}) => {
  const classes = useStyles({ menuMaxHeight })
  const selectClasses = {
    select: clsx(classes.select, {
      [classes.filled]: filled,
      [classes.selectSmall]: size === 'small',
      [classes.selectLarge]: size === 'large',
      [classes.disabled]: disabled,
      [classes.error]: error,
    }),
    icon: clsx(classes.icon, {
      [classes.iconSmall]: size === 'small',
      [classes.iconDisabled]: disabled,
    }),
  }
  const menuClasses = {
    paper: classes.paper,
  }

  const MenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    elevation: 0,
    classes: menuClasses,
    MenuListProps: {
      className: clsx({
        [classes.menuListSmall]: size === 'small',
        [classes.menuList]: size !== 'small',
      }),
    },
  }
  const selectProps = {
    classes: selectClasses,
    disableUnderline: true,
    IconComponent: CaretDownIcon,
    placeholder,
    fullWidth,
    error,
    disabled,
    ...otherProps,
  }

  if (native) {
    return (
      // @ts-ignore
      <NativeSelect {...selectProps}>{children}</NativeSelect>
    )
  }

  selectProps.MenuProps = MenuProps

  return <Select {...selectProps}>{children}</Select>
}

export default LiquidSelect
