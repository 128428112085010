import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, useMediaQuery } from '@material-ui/core'
import { useTheme, makeStyles, Theme } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles((theme: Theme) => ({
  disabledTextButton: {
    '&.MuiButton-root.Mui-disabled:not(.MuiButton-contained)': {
      color: theme.palette.text.disabled,
    },
  },
}))

interface QuickOrderFormActionsProps {
  shouldRenderRequestQuoteButton: boolean
  isRequestQuoteDisabled: boolean
  isAddToCartDisabled: boolean
  onClickRequestAQuote: () => void
  shouldRenderAddToCartButton?: boolean
}

const QuickOrderFormActions: React.FC<QuickOrderFormActionsProps> = ({
  shouldRenderRequestQuoteButton,
  isRequestQuoteDisabled,
  isAddToCartDisabled,
  onClickRequestAQuote,
  shouldRenderAddToCartButton = true,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')) === true

  const RequestAQuoteButton = () => {
    return (
      <Button
        className={clsx({
          [classes.disabledTextButton]: isRequestQuoteDisabled,
        })}
        size={isDesktop ? 'medium' : 'large'}
        variant={shouldRenderAddToCartButton ? 'text' : 'contained'}
        type={shouldRenderAddToCartButton ? 'button' : 'submit'}
        color="primary"
        fullWidth={!isDesktop}
        onClick={onClickRequestAQuote}
        disabled={isRequestQuoteDisabled}
        id="quick-order-request-a-quote"
      >
        <FormattedMessage id="REQUEST_QUOTE" defaultMessage="Request Quote" />
      </Button>
    )
  }

  const AddToCartButton = () => (
    <Button
      size="large"
      variant="contained"
      type="submit"
      color="secondary"
      fullWidth={!isDesktop}
      disabled={isAddToCartDisabled}
      id="quick-order-add-to-cart"
    >
      <FormattedMessage id="ADD_TO_CART" defaultMessage="Add to Cart" />
    </Button>
  )

  return isDesktop ? (
    <>
      {shouldRenderRequestQuoteButton && <RequestAQuoteButton />}
      {shouldRenderAddToCartButton && <AddToCartButton />}
    </>
  ) : (
    <>
      {shouldRenderAddToCartButton && <AddToCartButton />}
      {shouldRenderRequestQuoteButton && <RequestAQuoteButton />}
    </>
  )
}

export default QuickOrderFormActions
