import React from 'react'
import { useIntl } from 'react-intl'

import { MaterialsInput, StagedMaterial, SavedMaterial } from '../Types'
import {
  Material,
  QuickOrderItem,
  SharedListItemInput,
} from '@src/types/graphql-types'
import messages from '@utils/messages'

export enum QuickOrderModalContentType {
  Order = 'order',
  Quote = 'quote',
  SavedCart = 'saved_cart',
  List = 'list',
}

const parseBulkUploadInput = <TKeys extends readonly string[]>(
  value: string,
  keys: TKeys,
  filterFn: (row: string) => boolean
): Record<TKeys[number], string>[] => {
  return value
    .replaceAll(/[\uff0c]/g, ',')
    .split(/[\r\n]/g)
    .filter((row) => row.trim())
    .filter(filterFn)
    .map((row) => {
      const arr = row.split(/[\t,]/g)
      return keys.reduce(
        (acc, key, i) => {
          //Handles edge case where last item column may contain commas.
          if (i === keys.length - 1) {
            acc[key] = arr.slice(i).join(',').trim()
          } else {
            acc[key] = (arr[i] || '').trim()
          }
          return acc
        },
        {} as Record<TKeys[number], string>
      )
    })
}

export const parseListBulkInput = (value: string): SharedListItemInput[] =>
  parseBulkUploadInput(
    value,
    ['materialNumber', 'quantity', 'itemNote'],
    (row) => !row.match(/^SKU\s*[,\t]\s*Quantity\s*[,\t]\s*Product\sNote*$/)
  ).map((row) => ({
    ...row,
    materialNumber: row.materialNumber.toUpperCase(),
    quantity: parseInt(row.quantity) || 1,
  }))

/*
  This function splits the materials input text by row, then filters out any empty rows,
  then filters out the header row from a uploaded file, and then finally maps it to a
  MaterialsInput[] format.
*/
export const parseMaterialsInput = (value: string): MaterialsInput[] =>
  parseBulkUploadInput(
    value,
    ['materialNumber', 'quantity', 'promoCode', 'yourReference'] as const,
    (row) => {
      return !row.match(
        /^SKU\s*[,\t]\s*Quantity\s*[,\t]Promo\sCode*[,\t]\s*Reference\sNumber*[,\t]$/
      )
    }
  ).map((row) => ({
    ...row,
    materialNumber: row.materialNumber.toUpperCase(),
    quantity: parseInt(row.quantity) || 1,
  }))

export const parseSpreadsheet = async <TKeys extends readonly string[]>(
  file: File,
  keys: TKeys
): Promise<Record<string, string>[]> => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return resolve([])
    }
    const reader = new FileReader()
    reader.onload = async (e) => {
      try {
        const XLSX = await import('xlsx')
        const data = new Uint8Array((e.target as any).result)
        const workbook = XLSX.read(data, { type: 'array', raw: true })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const hasHeaders =
          (worksheet['A1'].v === 'SKU' ||
            worksheet['A1'].v === 'Product Number') &&
          worksheet['B1'].v === 'Quantity'

        // Convert readonly string[] to string[]
        const headerKeys = Array.from(keys)

        const products = XLSX.utils.sheet_to_json(worksheet, {
          header: headerKeys,
        }) as Record<string, string>[]

        if (hasHeaders) {
          products.splice(0, 1)
        }
        resolve(products)
      } catch (err) {
        reject(err)
      }
    }
    reader.readAsArrayBuffer(file)
  })
}

export const parseQuickOrderSpreadsheet = (
  file: File
): Promise<MaterialsInput[]> => {
  return parseSpreadsheet(file, [
    'materialNumber',
    'quantity',
    'promoCode',
    'yourReference',
  ]).then((materials) => {
    return materials.map(
      ({ materialNumber, quantity = '1', promoCode, yourReference }) => ({
        materialNumber: materialNumber?.toString() || '',
        quantity: parseInt(quantity) || 1,
        promoCode,
        yourReference,
      })
    )
  })
}

export const parseSharedListSpreadsheet = (
  file: File
): Promise<SharedListItemInput[]> => {
  return parseSpreadsheet(file, [
    'materialNumber',
    'quantity',
    'itemNote',
  ]).then((materials) => {
    return materials.map(({ materialNumber, quantity = '1', itemNote }) => ({
      materialNumber: materialNumber?.toString() || '',
      quantity: parseInt(quantity) || 1,
      itemNote,
    }))
  })
}

export const parseCreateQuoteSpreadsheet = (
  file: File
): Promise<MaterialsInput[]> => {
  return parseSpreadsheet(file, ['materialNumber', 'quantity']).then(
    (materials) => {
      return materials.map(({ materialNumber, quantity = '1' }) => ({
        materialNumber: materialNumber?.toString() || '',
        quantity: parseInt(quantity) || 1,
      }))
    }
  )
}

// Adds material details to items and formats to StagedMaterial[] type before adding to item list
export const formatStagedMaterials = (
  materialsSaved: SavedMaterial[],
  materialsDetail: Material[]
): StagedMaterial[] =>
  materialsSaved.map((material) => {
    const foundMaterial = materialsDetail.find(
      ({ number }) =>
        material.materialNumber.toUpperCase() === number.toUpperCase()
    ) as Material

    return {
      ...material,
      material: foundMaterial,
      error: '',
    }
  })

// Formats items that returned with valid details before saving them to the user profile
export const formatMaterialsToSave = (
  materialsEntered: MaterialsInput[],
  materialsDetail: Material[]
): QuickOrderItem[] =>
  materialsEntered.filter(({ materialNumber }) => {
    return materialsDetail.find(
      (material) =>
        materialNumber.toUpperCase() === material.number.toUpperCase()
    )
  }) as QuickOrderItem[]

/*
  Formats the spreadsheet upload on the quick order screen. Maps items/materials properties/keys to a function where the key can be manipulated.
  Some keys need specific formatting or fills if they are blank or not present. Manipulating them allows the corrend end format to be parsed and returend.
*/
export const formatQuickOrderBulkUploadEntry = (
  materials: MaterialsInput[]
): string =>
  materials
    .map(({ materialNumber, quantity, promoCode, yourReference }) => {
      materialNumber = materialNumber ? materialNumber.toUpperCase() : ''

      //Additional info is optional and can be negated if not specified in the upload and to avoid a bunch of blank repeating commas.
      let additionalInfo = ''

      if (promoCode || yourReference) {
        promoCode = promoCode || ''
        yourReference = yourReference || ''

        additionalInfo = `,${promoCode},${yourReference}`
      }

      return `${materialNumber},${quantity}${additionalInfo}`
    })
    .join('\n')

export const formatListsBulkUploadEntry = (
  materials: SharedListItemInput[]
): string =>
  materials
    .map(({ materialNumber, quantity, itemNote }) => {
      materialNumber = materialNumber ? materialNumber.toUpperCase() : ''

      // Additional info is optional and can be negated if not specified in the upload and to avoid a bunch of blank repeating commas.
      let additionalInfo = ''

      if (itemNote) {
        itemNote = itemNote || ''

        additionalInfo = `,${itemNote}`
      }

      return `${materialNumber},${quantity}${additionalInfo}`
    })
    .join('\n')

export const formatCreateQuoteBulkUploadEntry = (
  materials: MaterialsInput[]
): string =>
  materials
    .map(({ materialNumber, quantity }) => {
      materialNumber = materialNumber ? materialNumber.toUpperCase() : ''

      return `${materialNumber},${quantity}`
    })
    .join('\n')

type BulkUploadFormType = 'QuickOrder' | 'List' | 'CreateQuote'

export const BulkUploadRegex: Record<BulkUploadFormType, RegExp> = {
  QuickOrder: /^SKU\s*[,\t]\s*Promo\sCode*[,\t]\s*Quantity\s$/,
  List: /^SKU\s*[,\t]\s*Quantity\s*[,\t]\s*Product\sNote*$/,
  CreateQuote: /^SKU\s*[,\t]\s*Quantity\s*/,
}

export const useBulkUploadValidation = (formType: BulkUploadFormType) => {
  const { formatMessage } = useIntl()

  const validateBulkUpload = (
    values: string,
    setValidationErrors: React.Dispatch<React.SetStateAction<string[]>>
  ): { hasValidationErrors: boolean } => {
    const validateErrors: string[] = []
    const rows: string[] = values
      .split(/[\r\n]/g)
      .filter((row) => !!row.trim())
      .filter((row) => !row.match(BulkUploadRegex[formType]))
    if (rows) {
      if (rows.length > 50) {
        validateErrors.push(
          formatMessage(messages.BULK_UPLOAD_ERROR_MAX_PRODUCTS)
        )
      }
      rows.forEach((row) => {
        const quantity = (row.split(',')[1] || '').trim()
        // If the quantity does not have a value we do not validate and default to 1
        if (
          quantity &&
          (isNaN(parseInt(quantity)) || parseInt(quantity) > 9999)
        ) {
          validateErrors.push(
            formatMessage(messages.BULK_UPLOAD_ERROR_BAD_QTY, { row })
          )
        }
      })
    }

    setValidationErrors(validateErrors)

    return { hasValidationErrors: validateErrors.length ? true : false }
  }

  return { validateBulkUpload }
}
