import { makeStyles, createStyles } from '@material-ui/core/styles'
import { FC, useState, useRef } from 'react'
import ResponsiveCatalogImage from '../ResponsiveCatalogImage'
import { CatalogImage } from '@src/types/graphql-types'
import clsx from 'clsx'

interface styleProps {
  cursor: string
  transform: string
  transformOrigin: string
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      overflow: 'hidden',
    },
    imageWrapper: {
      alignItems: 'center',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'auto 100%',
      cursor: (props: styleProps) => props.cursor,
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      overflow: 'hidden',
      transform: (props: styleProps) => props.transform,
      transformOrigin: (props: styleProps) => props.transformOrigin,
      transition: `transform 0.3s ease-out`,
    },
    zoomableImage: {
      maxHeight: '100%',
      maxWidth: '100%',
      willChange: 'transform',
    },
  })
)

interface Props {
  image: CatalogImage
  customContainerStyles?: string
  customMouseOut?: () => void
  zoomStateChange?: (isZoomEnabled: boolean) => void
}

const ZoomableImage: FC<Props> = ({
  image,
  customContainerStyles,
  customMouseOut,
  zoomStateChange,
}) => {
  const [isZoomEnabled, setIsZoomEnabled] = useState(false)
  const [mouseX, setMouseX] = useState(50)
  const [mouseY, setMouseY] = useState(50)
  const imageWrapperRef = useRef<HTMLDivElement>(null)

  const classes = useStyles({
    cursor: `${isZoomEnabled ? 'zoom-out' : 'zoom-in'}`,
    transform: `scale(${isZoomEnabled ? '2.5' : '1'})`,
    transformOrigin: `${mouseX}% ${mouseY}%`,
  })
  const slideImgsizes = '(min-width:960px) 716px, calc(100vw - 100px)'

  const onToggleZoom = () => {
    setIsZoomEnabled(!isZoomEnabled)
    setMouseX(50)
    setMouseY(50)
    if (zoomStateChange) {
      zoomStateChange(!isZoomEnabled)
    }
  }

  const onMouseMove = (e) => {
    if (!isZoomEnabled) return

    // get size and position of image wrapper
    const rect = imageWrapperRef?.current?.getBoundingClientRect() as DOMRect
    const { left, top, width, height } = rect

    // calculate and set current mouse x,y coordinates
    setMouseX(((e.pageX - left) / width) * 100)
    setMouseY(((e.pageY - top) / height) * 100)
  }

  return (
    <div
      className={clsx(classes.container, customContainerStyles)}
      onBlur={() => setIsZoomEnabled(false)}
      onMouseOut={() =>
        customMouseOut ? customMouseOut : setIsZoomEnabled(false)
      }
      onMouseMove={onMouseMove}
    >
      <div
        ref={imageWrapperRef}
        className={classes.imageWrapper}
        onClick={onToggleZoom}
        onKeyDown={onToggleZoom}
        role="button"
        tabIndex={0}
        aria-label="zoom"
      >
        <ResponsiveCatalogImage
          className={classes.zoomableImage}
          alt={image?.altText}
          src={image?.largeUrl}
          sizes={slideImgsizes}
          urls={[image?.smallUrl, image?.mediumUrl, image?.largeUrl]}
        />
      </div>
    </div>
  )
}

export default ZoomableImage
