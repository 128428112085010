import React, { useState } from 'react'
import { homeRoute } from '@src/routes'
import { useCookies } from '@src/utils/cookies'
import MenuIcon from '@icons/MenuIcon'
import { sendHeaderTopMenuEvents } from '@utils/analytics'
import { EventValues } from '@sial/common-utils'
import CompanyLogo from '@src/components/CompanyLogo'
import CountryAndLanguageMenu from './CountryAndLanguageMenu/CountryAndLanguageMenu'
import { ButtonBase, Theme, Badge } from '@material-ui/core'
import dynamic from 'next/dynamic'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/styles'
import { createStyles } from '@material-ui/core/styles'
import { Linq } from '@src/components/Linq'
import { useCompanyName } from '@utils/useCompanyName'
import { useChinaUser } from '@src/utils/useChinaUser'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import { useGetNotificationsQuery } from '@src/queries/NotificationsQuery.generated'

const HeaderSearch = dynamic(() => import('./HeaderSearch'), { ssr: false })
const MobileMegaNav = dynamic(() => import('./MobileMegaNav'), { ssr: false })
const MiniCart = dynamic(() => import('../MiniCart'), { ssr: false })
const ScannerIcon = dynamic(() => import('@src/icons/ScannerIcon'), {
  ssr: false,
})
const DynamicBarcodeModal = dynamic(
  () => import('@src/components/BarcodeModal'),
  { ssr: false }
)
const NotificationCenter = dynamic(() => import('./NotificationsCenter'), {
  ssr: false,
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      backgroundColor: theme.palette.common.white,
      borderBottom: `1px solid ${theme.palette.brand.main}`,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    scanButton: {
      display: 'inline-flex',
      marginLeft: 'auto',
      marginRight: theme.spacing(6),
      color: theme.palette.brand.main,
      '& > svg': {
        fontSize: theme.typography.pxToRem(24),
      },
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    headerTop: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(4, 4, 0),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 12, 6, 8),
        borderBottom: `1px solid ${theme.palette.brand.main}`,
      },

      [theme.breakpoints.up('lg')]: {
        border: 'unset',
        padding: theme.spacing(7.5, 20),
        maxWidth: `1440px`,
        marginLeft: `auto`,
        marginRight: `auto`,
      },
    },
    menuLogoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    mobileMenuIcon: {
      color: theme.palette.brand.main,
      fontSize: theme.typography.pxToRem(24),
      marginRight: theme.spacing(6),
      cursor: 'pointer',
      background: 'none',
      border: 'none',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(8),
      },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },

    logoWrapper: {
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(15),
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 'unset',
      },
    },

    searchWrapper: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(12),
        display: 'flex',
        width: '100%',
      },
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(0, 10),
        maxWidth: `760px`,
      },
    },
    caaWrapper: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    minicartWrapper: {
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0, 3),
      },
      '&:empty': { display: 'none' },
      [theme.breakpoints.up('lg')]: {
        display: 'none',
      },
    },
    notificationsWrapper: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    notificationBadge: {
      '& .MuiBadge-badge': {
        fontSize: theme.typography.pxToRem(8),
        height: theme.spacing(3),
        minWidth: theme.spacing(3),
        width: theme.spacing(3),
      },
      '& .MuiBadge-anchorOriginTopRightCircular': {
        top: '-2px',
        right: '-2px',
      },
    },
  })
)

const HeaderTop = () => {
  const classes = useStyles()
  const [cookies] = useCookies(['_ga'])
  const [isScanOpen, setIsScanOpen] = useState<boolean>(false)
  const [mobileNavOpen, setMobileNavOpen] = useState<boolean>(false)
  const companyName = useCompanyName()
  const { formatMessage } = useIntl()
  const { userIsLoggedIn } = useCurrentUser()
  const isChinaUser = useChinaUser()

  const { data } = useGetNotificationsQuery({
    ssr: false,
    skip: !userIsLoggedIn,
  })
  const notificationsData = data?.getNotifications || []

  return (
    <>
      <div className={classes.headerTop}>
        <div className={classes.menuLogoContainer}>
          <button
            className={classes.mobileMenuIcon}
            onClick={() => setMobileNavOpen(true)}
            aria-label="Main Menu"
            aria-expanded={mobileNavOpen}
            type="button"
          >
            <Badge
              color="error"
              invisible={notificationsData?.length === 0}
              className={classes.notificationBadge}
              overlap="circular"
            >
              <MenuIcon fontSize="inherit" />
            </Badge>
          </button>
          <div className={classes.logoWrapper}>
            <Linq
              href={homeRoute.index()}
              aria-label={`${companyName} ${formatMessage({
                id: 'HOME',
                defaultMessage: 'Home',
              })}`}
              onClick={() =>
                sendHeaderTopMenuEvents(EventValues.Logo, cookies._ga || '')
              }
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  sendHeaderTopMenuEvents(EventValues.Logo, cookies._ga || '')
                }
              }}
            >
              <CompanyLogo fontSize="inherit" />
            </Linq>
          </div>
        </div>
        {/* Hidden on desktop css */}
        <ButtonBase
          aria-label="Barcode Scanner"
          onClick={() => setIsScanOpen(true)}
          className={classes.scanButton}
        >
          <ScannerIcon />
        </ButtonBase>

        <div className={classes.searchWrapper}>
          <HeaderSearch id="search-wrapper" />
        </div>
        <div className={classes.minicartWrapper}>
          <MiniCart id="show-mini-cart-menu-button-mobile" />
        </div>

        {!isChinaUser && userIsLoggedIn && (
          <div className={classes.notificationsWrapper}>
            <NotificationCenter data={notificationsData} />
          </div>
        )}

        <div className={classes.caaWrapper}>
          <CountryAndLanguageMenu />
        </div>
      </div>
      <div className={classes.search}>
        <HeaderSearch id="search" />
      </div>
      <MobileMegaNav
        isOpen={mobileNavOpen}
        onClose={() => setMobileNavOpen(false)}
        notificationsData={notificationsData}
      />
      {isScanOpen && (
        <DynamicBarcodeModal open={isScanOpen} setOpen={setIsScanOpen} />
      )}
    </>
  )
}

export default HeaderTop
