import React, { FC } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

import { searchResultsRoute, structureSearchDrawRoute } from '@src/routes'
import { EventValues } from '@sial/common-utils'
import {
  sendSearchInteractionEvent,
  sendSRPActionEvent,
} from '@src/utils/analytics'
import { FormattedMessage } from 'react-intl'
import { Linq } from '@src/components/Linq'
import { useChinaUser } from '@src/utils/useChinaUser'

const useStyles = makeStyles((theme: Theme) => ({
  searchLink: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
  },
}))

interface AdvancedStructureSearchLinksProps {
  orgId?: string
  isStructureSearch: boolean
}

const AdvancedStructureSearchLinks: FC<AdvancedStructureSearchLinksProps> = ({
  orgId,
  isStructureSearch,
}) => {
  const classes = useStyles()
  const isChinaUser = useChinaUser()
  return (
    <>
      <div className={classes.searchLink}>
        <Linq
          href={searchResultsRoute.index()}
          aria-hidden
          onClick={() => {
            sendSRPActionEvent(EventValues.AdvancedSearchLink, '')
            sendSearchInteractionEvent({
              action: 'visit advanced search page',
              component: 'left rail',
              elementType: 'link',
              elementText: 'advanced search',
              linkUrl: searchResultsRoute.index(),
            })
          }}
        >
          <FormattedMessage
            id="ADVANCED_SEARCH"
            defaultMessage="Advanced Search"
          />
        </Linq>
      </div>
      {!orgId && !isStructureSearch && !isChinaUser && (
        <div className={classes.searchLink}>
          <Linq
            href={structureSearchDrawRoute.index()}
            aria-hidden
            onClick={() => {
              sendSRPActionEvent(EventValues.StructureSearchLink, '')
              sendSearchInteractionEvent({
                action: 'visit chemical structure search',
                component: 'left rail',
                elementType: 'link',
                elementText: 'chemical structure search',
                linkUrl: structureSearchDrawRoute.index(),
              })
            }}
          >
            <FormattedMessage
              id="CHEMICAL_STRUCTURE_SEARCH"
              defaultMessage="Chemical Structure Search"
            />
          </Linq>
        </div>
      )}
    </>
  )
}

export default AdvancedStructureSearchLinks
