import getConfig from 'next/config'
import {
  Analytics,
  GTMEventTypes,
  GTMEventCategory,
  EventValues,
} from '@sial/common-utils'
import { groupBy, forEach } from 'lodash'
import { uTagLinkAdapter } from '@utils/tealiumAnalytics'
import { ConfiguratorProductType } from '@utils/analytics/configuratorAnalytics'
import {
  AddToCartEvent,
  GTMEcommerceItem,
  MaterialProductsData,
  PaClipData,
  PromoProductItems,
} from '@utils/analytics/types'
import {
  AddToCartPagesEnum,
  PaymentMethodValues,
  Dimension91,
} from '@utils/analytics/enums'
import { CartItemInput } from '@src/types/graphql-types'

const {
  publicRuntimeConfig: {
    isAnalyticsEnabled: analyticsAreEnabled, // Import under different name to export and use as isAnalyticsEnabled elsewhere.
    enableTealium,
    enableGoogleTagManager,
    adobeLaunchId,
    featureFlags: { stratosMultiRegion },
  },
} = getConfig()

// Globally defines is ANY analytics platform should be used on the site.
export const isAnalyticsEnabled = analyticsAreEnabled

// If the flag to force enable GTM (enableGoogleTagManager) is set triggers the app to use the GTM tag.
// If not uses the stratosMultiRegion variable to determine this. stratosMultiRegion is all non-China sites.
export const showGTMTag =
  enableGoogleTagManager !== undefined
    ? enableGoogleTagManager
    : stratosMultiRegion

// If the flag is set to force enable Tealium (enableTealium) then Tealium events will be loaded and fired.
// If not use a NOT stratos multi region flag (China site) to set the Tealium usage flag to fire events or not.
export const isTealium =
  enableTealium !== undefined ? enableTealium : !showGTMTag

// Adobe Target Launch Event.
export const sendLaunchEvent = (props): void => {
  if (!adobeLaunchId || typeof window === 'undefined') return
  window?.adobeDataLayer?.push(props)
}

const ga3DataModel = {
  eventCategory: 'Category name',
  eventAction: 'Category Action - Click, Hover, Whatever',
  eventLabel: 'Event Label - Text Of Link, etc',
  eventInteractionType: 1,
  purchaseSoldToNumber: 'Sold To Number',
  purchaseBillToNumber: 'Bill To Number',
  purchaseShipToNumber: 'Purchase Ship To',
  purchaseParticipantId: 'Participant ID?',
  // ecommerce: {} - Is ignored because it is radically different between UA and GA4. Consider using legacySendEvent() for payloads with eCommerce data.

  // One off custom dimension properties semingly used by UA.
  memberId: 'Member ID on me',
  newsLetterSignup: 'Yes/No',
}

function conformToPayload<T extends object>(obj, interfaceDef): Partial<T> {
  const conformedPayload: Partial<T> = {}

  for (const key in interfaceDef) {
    if (typeof obj[key] !== 'undefined') {
      if (
        typeof interfaceDef[key] === 'object' &&
        !Array.isArray(interfaceDef[key])
      ) {
        //When a subkey that is an object is found, find the subkey in the modeled data structure and then recursively compare the two.
        conformedPayload[key] = conformToPayload(obj[key], interfaceDef[key])
      } else {
        conformedPayload[key] = obj[key]
      }
    }
  }

  return conformedPayload
}

/*
  Legacy Send Event Usage:

  There are times where the sendEvent() function for delivering UA and GA4 events at the same time is insufficient or detremental.
  This is most commonly found when the payload requires the ecommerce: property.

  The ecommerce data structure varies fairly significantly between the two platforms and as such they need to be sent in legacy mode with one sendEvent() for UA and one for GA4.
*/
export const legacySendEvent = (props): void => {
  Analytics.sendEvent(props, isAnalyticsEnabled, showGTMTag)
  sendLaunchEvent(props)
}

export const sendEvent = (props): void => {
  const { payload, eventType } = props
  // Legacy UA events still need submited to the datalayer for other third-party systems that have not adapted to new GA4 data structures.
  if (eventType) {
    const conformGa3Payload = conformToPayload(payload, ga3DataModel)

    //Make a new GA3 event object with the existing event type, and new validated/conformed payload.
    const ga3OnlyEvent = { eventType, payload: conformGa3Payload }

    showGTMTag &&
      Analytics.sendEvent(ga3OnlyEvent, isAnalyticsEnabled, showGTMTag)
  } else {
    console.warn(
      'Analytics: No eventType set. Legacy UA event not send to dataLayer. If you are creating a new analytics event, you can ignore this warning.'
    )
  }

  if (
    payload?.event === GTMEventTypes.AnalyticsEvent ||
    payload?.event === eventType
  ) {
    console.error(
      "Analytics: GA4 'event' should not be the same as, or using a UA 'eventType' parameter."
    )
  }

  /*
    LOGIC:
    We read 'ga4Event' as the generic event type in GTM and our @sial/common-utils package.
    As such if a legacy UA event not yet refactored for GA4 is provided to this function we are going to assume it was a generic legacy event, and cast it as a generic event in GA4.
    If a specific event parameter is supplied we'll just assume it is correct and keep it as such.

    WHY:
    When the legacy Universal Analytics (UA) system was still active before July 1st 2024 the 'eventType' field was used to signal to the platform what type of event was being sent.
    Since Google Analytics has always had a variety of different event types that could be funneled into different parts of the platform, it was expected you declare the type of event passed along.

    GA4 which came fully online after the closure of has a similar system where there are generic events, as well as a host of recommended and 'enhanced' events used for things like ecommerce funnel tacking.
    Google provides a list of recommended trakcings here: https://support.google.com/analytics/answer/9267735?sjid=9296292270182979919-NA

    Additionally our Analytics implementation is loaded via @sial/common-utils and has a Google Tag Manager (GTM) script that interacts with our dataLayer.
    Meaning it also reads and manipulates data the Nextjs app is submitting to make sure it goes to the right place in our analytics infrastructure.
  */
  const ga4Payload = payload
  ga4Payload.event = payload.event || 'ga4Event'

  /*
    In cases where a seemingly legacy UA event passes or is cast to a generic GA4 event,
    but there is actually a GA4 'event_type' present we want to instead set the 'event' as the 'event_name' and then drop the 'event_name' from the object.
    
    See GA4-332 for more details.
  */
  if (ga4Payload.event === 'ga4Event' && ga4Payload?.event_name) {
    ga4Payload.event = ga4Payload.event_name
    payload.event_name = undefined
  }

  // Convert the legacy eventWhatever payload parameters to new snake_case formatted GA4 and the Analytics team internally prefer.
  // If eventWhatever doesn't exist on any given payload it will explicitly set 'undefined'
  ga4Payload.event_category = ga4Payload?.eventCategory
  ga4Payload.event_action = ga4Payload?.eventAction
  ga4Payload.event_label = ga4Payload?.eventLabel

  // The GA team asked that the legacy params be cleaned from GA4 payloads. This function quickly removes all matching keys
  const cleanedGa4Payload = {}
  const legacyParams = [
    'eventInteractionType',
    'eventCategory',
    'eventAction',
    'eventLabel',
  ]

  for (const [key, value] of Object.entries(ga4Payload)) {
    if (!legacyParams.includes(key)) {
      cleanedGa4Payload[key] = value
    }
  }

  // A bit wonky since GA4 wants an object with only a single property of 'payload' and nothing else. This also makes eventType which triggers GA3 events omitted.
  showGTMTag &&
    Analytics.sendEvent(
      { payload: cleanedGa4Payload },
      isAnalyticsEnabled,
      showGTMTag
    )

  // Push event data into the Adobe analytics layer. No changes here interface wise between GA4 and Adobe Analytics at this time.
  // Adobe Analytics should only be loaded and fire events when Analytics are enabled globally and GA4 is loaded.
  isAnalyticsEnabled && showGTMTag && sendLaunchEvent(props)

  // When Tealium is enabled we want to submit the uTagLink event with some Tealium specific payload modifications.
  isTealium && uTagLinkAdapter(cleanedGa4Payload)
}

// There is this legacy cart related event only used by configurators and the MTO team.
// It should eventually be refactored or removed but to do a hotfix for GA4-195
export const uaConfiguratorAddToCart = ({
  productIds,
  actionTitle,
  ecommerce,
}: AddToCartEvent): void => {
  legacySendEvent({
    eventType: GTMEventTypes.AddToCart,
    payload: {
      eventCategory: GTMEventCategory.AddToCart,
      eventAction: actionTitle,
      eventLabel: productIds,
      eventInteractionType: 0,
      ecommerce,
    },
  })
}

/***
 * HELPER UTILITY FUNCTIONS AND CONSTANTS
 *
 * There are a number of functions in the analytics files made for simply extracting things like
 * price and currency codes from a line item.
 *
 * As well as a number of regex cleanups for item structure remappings.
 *
 * They'll all be houses below here and are imported as needed throughout other analytics files and the application.
 */

// regex that for stripping encoded unicode code string from product name
export const regexForStripEncodingUnicode = /([&][#](\d+)[;])/g
// regex for stripping html tab e.g <> from product name
export const regexForStripHTML = /(<([^>]+)>)/gi
// regex for stripping special characters that other than letter/space/number from product name
export const regexForStripSpecialChars = /[^\w\s]/gi
// regex for stripping trade mark, copy right symbol etc,
// some case we  have getting symbols instead of encoded unicode in product name
export const regexForStripSymbols = /[™®©]/g
export const regexForStripBrackets = / *\{[^}]*\} */g
export const regexForStripHTMLWithContent = /<.*>.*?/gi

// Tealium uses CNY as default currency, GA uses USD
export const DEFAULT_CURRENCY_CODE = showGTMTag ? 'USD' : 'CNY'
export const CART_DATA_STORAGE_KEY = 'CART_DATA_STORAGE_KEY'
export const ADD_TO_CART_GA_DATA_STORAGE_KEY = 'ADD_TO_CART_GA_DATA_STORAGE_KEY'

// Google Analytics accepts only "MXN" unit for Mexico, So changing mxp to mxn unit.
export const getCurrencyCode = (currencyCode: string): string =>
  currencyCode?.toLowerCase() === 'mxp' ? 'mxn' : currencyCode?.toLowerCase()

export const getCurrencyCodeFromProduct = (materialPricing): string =>
  (materialPricing?.length && materialPricing[0]?.currency
    ? materialPricing[0]?.currency
    : DEFAULT_CURRENCY_CODE
  ).toLowerCase()

export const mapPaymentMethod = (
  paymentMethod = '',
  isChargeback = false,
  contractNumber = '',
  isB2BUser = false
): string => {
  let paymentMethodString = ''
  switch (paymentMethod.toLowerCase()) {
    case 'cc':
      paymentMethodString = !!contractNumber
        ? PaymentMethodValues.cn
        : PaymentMethodValues.cc
      break

    case 'std':
      paymentMethodString = isChargeback
        ? PaymentMethodValues.chargeback
        : !!contractNumber
          ? PaymentMethodValues.cn
          : PaymentMethodValues.po
      break

    case 'mpower':
      paymentMethodString = PaymentMethodValues.mpower
      break

    case 'rapidred':
      paymentMethodString = PaymentMethodValues.rapidred
      break

    case 'cn':
      paymentMethodString = PaymentMethodValues.cn
      break

    default:
      paymentMethodString = isB2BUser
        ? PaymentMethodValues.b2b
        : PaymentMethodValues.others
      break
  }

  return paymentMethodString
}

/*
  Price formatter function for analytics where if the value isn't a valid number we set it to null, and all valid numbers are parsed to be a type of a float with two digits of percision.

  This is to fix some inconsistencies we had when setting fallback prices in the event a price was undefined.
  For undefined prices we always want null.

  And all other prices should get two digits of percision and be a float. Not a number as a string.
*/
export const priceFormatter = (
  priceValue: number | string | null | undefined
): number | null => {
  // null, undefined, and '' (empty string) are all falsey in javascript.
  // If priceValue is ever one of these we simply should immediately give back the null undefined value requested by the analytics team.
  if (!priceValue) {
    return null
  }

  // Try to parse input price Value to a number if it is defined.
  // Number gives back a NaN (falsey) value if it gets a string with things other than numeric characters and decimals. Which should return null for interface support with Google Analytics.
  const parsePrice = Number(priceValue)

  if (!parsePrice) {
    return null
  }

  // Try to parse the number to a float with two digits. May not need a try/catch but number parsing in javascript can behave oddly sometimes so better safe than sorry.
  try {
    return parseFloat(parsePrice.toFixed(2))
  } catch (err) {
    console.error(`[Analytics] Error Formatting Price:`, err)
    return null
  }
}

export const setPaClipData = (
  values: CartItemInput[],
  materialPricing
): PaClipData[] | undefined => {
  try {
    if (!values.length || !materialPricing.length) return
    const products = values?.map((product) => {
      const material = materialPricing?.find(
        (item) => item.materialNumber === product.materialNumber
      )

      return {
        id: (material?.product || EventValues.Empty).toLowerCase(),
        name: (material?.materialDescription || EventValues.Empty)
          .toLowerCase()
          ?.replace(regexForStripHTML, '')
          ?.replace(regexForStripSpecialChars, ''),
        variant: (material?.materialNumber || EventValues.Empty).toLowerCase(),
        brand: (material?.brand || EventValues.Empty).toLowerCase(),
        quantity: product?.quantity,
        price: priceFormatter(material?.price),
      }
    })
    return products.length ? products : []
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
    return []
  }
}

export const setMaterialData = (
  values: CartItemInput[],
  favorite: any,
  pageName?: AddToCartPagesEnum,
  bundle?: {
    quantity: number
    bundleId: string
  }
): MaterialProductsData[] => {
  try {
    const itemGroups = groupBy(values || [], (item) => item.materialNumber)
    const priceGroups = groupBy(
      favorite || [],
      (item) => item.materialNumber || item?.material?.number
    )
    const products = Object.values(itemGroups).map((group, index) => {
      const item = group[0]

      const materialPricing = favorite?.find((elem) => {
        if (
          pageName === AddToCartPagesEnum.QuoteDetails ||
          pageName === AddToCartPagesEnum.QuickOrderQuotesModal ||
          pageName === AddToCartPagesEnum.AccountDashboardQuote
        ) {
          return elem?.materialInfo?.number === item?.materialNumber
        }

        if (pageName === AddToCartPagesEnum.PromoBundle) {
          return elem?.materialNumber === item?.materialNumber
        }

        return elem?.material?.number === item?.materialNumber
      })

      const avgGroupPrice = (
        Object.values(priceGroups)[index]?.reduce(
          (acc, item) =>
            (item?.material?.price ||
              item?.pricePerUnit ||
              item?.listPrice ||
              0) + acc,
          0
        ) / group.length
      )?.toFixed(2)

      if (
        pageName === AddToCartPagesEnum.QuoteDetails ||
        pageName === AddToCartPagesEnum.QuickOrderQuotesModal ||
        pageName === AddToCartPagesEnum.AccountDashboardQuote
      ) {
        materialPricing.material = materialPricing.materialInfo
      }
      return {
        id: (
          materialPricing?.material?.product ||
          materialPricing?.product ||
          materialPricing?.material?.number ||
          materialPricing?.materialNumber ||
          EventValues.Empty
        )?.toLowerCase(),
        name:
          (materialPricing?.type === ConfiguratorProductType.Configurator
            ? materialPricing?.material?.description ||
              materialPricing?.material?.name
            : materialPricing?.material?.name ||
              materialPricing?.material?.description ||
              materialPricing?.materialName
          )
            ?.replace(regexForStripEncodingUnicode, '')
            ?.replace(regexForStripHTML, '')
            ?.replace(regexForStripSymbols, '')
            ?.toLowerCase() || EventValues.Empty,
        variant: (
          materialPricing?.material?.number ||
          materialPricing?.materialNumber ||
          EventValues.Empty
        )?.toLowerCase(),
        brand: (
          materialPricing?.material?.brand?.key ||
          materialPricing?.material?.brand?.name ||
          materialPricing?.brand ||
          EventValues.Empty
        )?.toLowerCase(),
        coupon: bundle?.bundleId || EventValues.Empty,
        quantity: bundle
          ? bundle?.quantity * item?.quantity
          : group.reduce((acc, item) => acc + item?.quantity, 0) || 1,
        price: priceFormatter(avgGroupPrice),
        dimension91: 'standard',
      }
    })
    return products ? products : []
  } catch (error) {
    console.error('ERROR - Add to cart Event ', error)
    return []
  }
}

export const clubbingUpProducts = (
  products,
  page?: string,
  dimension91?: string
): (GTMEcommerceItem | PromoProductItems)[] => {
  let flattenedProducts: any = []
  if (page === 'order_acknowledgement') {
    flattenedProducts = products.filter(
      (item) => item?.type === 'PromotionalBundle'
    )
  } else {
    flattenedProducts = products.flatMap((item) => item?.bundleItems)
  }

  // flattenedProducts =
  //   flattenedProducts.length &&
  //   flattenedProducts.map((item, index) => {
  //     item.bundleItem = index
  //     return item
  //   })
  const promoItems: any = groupBy(flattenedProducts || [], (item) => {
    return item?.material?.id
  })
  forEach(promoItems, (_value, key) => {
    promoItems[key] = groupBy(promoItems[key] || [], (item) => item?.promoCode)
  })
  const promoProducts = Object.values(promoItems).map((group: any) => {
    return Object.values(group).map((group1: any) => {
      const item = group1[0]
      const avgGroupPrice =
        group1.reduce(
          (acc, item) =>
            (item.pricing?.price ||
              item?.pricePerUnit ||
              item?.listPrice ||
              0) + acc,
          0
        ) / group1.length
      return {
        id: (item?.material?.product || EventValues.Empty)?.toLowerCase(),
        name:
          (item.material?.name || item.material?.description)
            ?.replace(regexForStripHTML, '')
            ?.replace(regexForStripEncodingUnicode, '')
            ?.toLowerCase() || EventValues.Empty,
        variant: item?.material?.number?.toLowerCase() || EventValues.Empty,
        brand: (item?.material?.brand?.key || EventValues.Empty)?.toLowerCase(),
        quantity: group1.reduce((acc, item) => acc + item?.quantity, 0) || 1,
        price: avgGroupPrice?.toString() || '0.00',
        coupon: item?.promoCode || EventValues.Empty,
        discount: item?.discount,
      }
    })
  })
  const flattenedProd = (promoProducts.length && promoProducts?.flat(1)) || []
  const nonBundleItems = products.filter(
    (item) => item?.type !== 'PromotionalBundle'
  )

  const itemGroups = groupBy(nonBundleItems || [], (item) =>
    item?.type === EventValues.Emprove
      ? item?.emproveDossierInfo?.dossierMaterialNumber
      : item?.material?.id
  )

  const productsArray: GTMEcommerceItem[] = Object.values(itemGroups).map(
    (group) => {
      const item = group[0]
      const avgGroupPrice =
        group.reduce(
          (acc, item) =>
            (item.pricing?.price ||
              item?.pricePerUnit ||
              item?.listPrice ||
              0) + acc,
          0
        ) / group.length
      if (item.type === EventValues.Emprove) {
        return {
          id: (item?.material?.id || EventValues.Empty).toLowerCase(),
          name: (
            (item.material.description
              ? item.material.description
              : item.material.name) || EventValues.Empty
          )
            .toLowerCase()
            .replace(regexForStripHTML, '')
            .replace(regexForStripEncodingUnicode, ''),
          variant: (
            item?.emproveDossierInfo?.dossierMaterialNumber || EventValues.Empty
          ).toLowerCase(),
          brand: (
            item?.material?.brand?.name || EventValues.SialBrand
          ).toLowerCase(),
          quantity: 1,
          price: priceFormatter(avgGroupPrice),
          coupon: item.promoCode || EventValues.Empty,
          dimension91: dimension91 ?? Dimension91.Standard,
          discount: item?.discount,
        }
      } else {
        return {
          id: (item?.type === ConfiguratorProductType.Configurator
            ? item?.material?.number || EventValues.Empty
            : item?.material?.product || EventValues.Empty
          ).toLowerCase(),
          name: (
            (item?.type === ConfiguratorProductType.Configurator
              ? item.material?.description || item.material?.name
              : item.material?.name || item.material?.description) ||
            EventValues.Empty
          )
            .toLowerCase()
            .replace(regexForStripHTML, '')
            .replace(regexForStripEncodingUnicode, ''),
          variant: (item?.material?.number || EventValues.Empty).toLowerCase(),
          brand: (item?.type === ConfiguratorProductType.Configurator
            ? item?.material?.brand?.name || EventValues.Empty
            : item?.material?.brand?.key || EventValues.Empty
          ).toLowerCase(),
          quantity: group.reduce((acc, item) => acc + item.quantity, 0) || 1,
          price: priceFormatter(avgGroupPrice),
          coupon: item.promoCode || EventValues.Empty,
          dimension91: dimension91 ?? Dimension91.Standard,
          discount: item?.discount,
        }
      }
    }
  )
  return [...productsArray, ...flattenedProd] || []
}
