import React from 'react'
import { Theme, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GlobalAlert from '../GlobalAlert'

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: ' center',
      justifyContent: 'center',
      marginTop: 40,
    },
    gridWrapper: {
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '900px',
      },
      [theme.breakpoints.up('md')]: {
        maxWidth: '1280px',
      },
    },
  }
})

interface GlobalAlertBarProps {
  severity: 'success' | 'info' | 'warning' | 'error'
  handleClose: () => void
}
const GlobalAlertBar: React.FC<GlobalAlertBarProps> = ({
  severity,
  handleClose,
  children,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Grid className={classes.gridWrapper}>
        <GlobalAlert severity={severity} onClose={handleClose}>
          {children}
        </GlobalAlert>
      </Grid>
    </div>
  )
}

export default GlobalAlertBar
