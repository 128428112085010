import gql from 'graphql-tag'

export const MATERIALS_DETAIL = gql`
  query MaterialsDetail(
    $materialNumbers: [String!]!
    $catalogType: CatalogType
    $filter: String
  ) {
    getMaterialsDetail(
      input: {
        materialNumbers: $materialNumbers
        catalogType: $catalogType
        filter: $filter
      }
    ) {
      ...MaterialsDetail
    }
  }

  fragment MaterialsDetail on Material {
    id
    number
    name
    isMarketplace
    marketplaceOfferId
    description
    brand {
      key
      erpKey
      name
      color
    }
    product
    image {
      altText
      smallUrl
      mediumUrl
      largeUrl
    }
    packageSize
    shipsToday
    freeFreight
    erpType
  }
`
