// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type B2bShoppingCartQueryVariables = Types.Exact<{
  cartType?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type B2bShoppingCartQuery = { __typename?: 'Query', getB2bShoppingCart: { __typename?: 'B2bShoppingCartDataResponse', punchoutResponseMsg?: string | null, isDecodeNeeded?: boolean | null, postBackURL?: string | null, isSapOci?: boolean | null } };


export const B2bShoppingCartDocument = gql`
    query B2bShoppingCart($cartType: String) {
  getB2bShoppingCart(cartType: $cartType) {
    punchoutResponseMsg
    isDecodeNeeded
    postBackURL
    isSapOci
  }
}
    `;
export type B2bShoppingCartComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>, 'query'>;

    export const B2bShoppingCartComponent = (props: B2bShoppingCartComponentProps) => (
      <ApolloReactComponents.Query<B2bShoppingCartQuery, B2bShoppingCartQueryVariables> query={B2bShoppingCartDocument} {...props} />
    );
    

/**
 * __useB2bShoppingCartQuery__
 *
 * To run a query within a React component, call `useB2bShoppingCartQuery` and pass it any options that fit your needs.
 * When your component renders, `useB2bShoppingCartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useB2bShoppingCartQuery({
 *   variables: {
 *      cartType: // value for 'cartType'
 *   },
 * });
 */
export function useB2bShoppingCartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>(B2bShoppingCartDocument, options);
      }
export function useB2bShoppingCartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>(B2bShoppingCartDocument, options);
        }
export type B2bShoppingCartQueryHookResult = ReturnType<typeof useB2bShoppingCartQuery>;
export type B2bShoppingCartLazyQueryHookResult = ReturnType<typeof useB2bShoppingCartLazyQuery>;
export type B2bShoppingCartQueryResult = ApolloReactCommon.QueryResult<B2bShoppingCartQuery, B2bShoppingCartQueryVariables>;