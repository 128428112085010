import { MATERIALS_DETAIL } from '@src/queries/MaterialDetailsQuery'
import { useState } from 'react'
import {
  MaterialsDetailFragment,
  MaterialsDetailQuery,
  MaterialsDetailQueryVariables,
} from '@src/queries/MaterialDetailsQuery.generated'
import { QueryResult } from '@src/utils/QueryResult'
import { fromPairs } from 'lodash'
import { useApolloClient } from '@apollo/react-hooks'
import { useMaterialCatalogFilter } from '@utils/useMaterialCatalogFilter'
import { useLatest } from 'react-use'

export const useMaterialsCache = () => {
  const client = useApolloClient()
  const { catalogType, filter } = useMaterialCatalogFilter()
  const [materialsCache, setMaterialsCache] = useState<
    Partial<{ [materialNumber: string]: QueryResult<MaterialsDetailFragment> }>
  >({})
  const latestMaterialsCache = useLatest(materialsCache)

  const loadMaterialsData = async (
    materialValues: { materialNumber: string }[]
  ) => {
    const materialsToFetch = materialValues.filter(({ materialNumber }) => {
      return !materialsCache[materialNumber]
    })
    let updatedMaterialsCache: typeof materialsCache | undefined
    if (materialsToFetch.length) {
      setMaterialsCache((s) => ({
        ...s,
        ...fromPairs(
          materialsToFetch.map(({ materialNumber }) => [
            materialNumber,
            {
              ...(s[materialNumber] || { data: null }),
              loading: true,
            },
          ])
        ),
      }))
      const materialsDetailResult = await client.query<
        MaterialsDetailQuery,
        MaterialsDetailQueryVariables
      >({
        query: MATERIALS_DETAIL,
        variables: {
          materialNumbers: materialsToFetch.map(
            (material) => material.materialNumber
          ),
          catalogType,
          filter,
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      updatedMaterialsCache = {
        ...latestMaterialsCache.current,
        ...fromPairs(
          materialsToFetch.map(({ materialNumber }) => [
            materialNumber,
            {
              loading: false,
              data: materialsDetailResult.data?.getMaterialsDetail.find(
                (m) => m?.number === materialNumber
              ),
            },
          ])
        ),
      }
      setMaterialsCache(updatedMaterialsCache)

      return { materialsDetailResult, updatedMaterialsCache }
    }

    return {}
  }
  return { loadMaterialsData, materialsCache, latestMaterialsCache }
}
