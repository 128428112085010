import React, { useState } from 'react'
import clsx from 'clsx'
import { FormattedNumber, FormattedMessage, useIntl } from 'react-intl'
import { Theme, Box, Button } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import messages from '@utils/messages'
import Modal from '../../components/Modal'
import { useCurrentUser } from '@src/utils/useCurrentUser'
import HidePriceMessage from '@src/components/HidePriceMessage'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
interface MaterialPricingDisplayProps {
  material: ValidMaterialPricingDetailFragment
  listPriceClass: string
  priceClass?: string
  hasStackedPrices?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pricesContainer: {
      display: 'flex',
      alignItems: 'baseline',
      '& > div:not(:first-child)': {
        marginLeft: theme.spacing(1),
      },
    },
    pricesStacked: {
      flexDirection: 'column',
      alignItems: 'stretch',
      '& > div:not(:first-child)': {
        marginLeft: theme.spacing(0),
      },
    },
    baseClass: {
      minWidth: 0,
      paddingLeft: 0,
    },
    topAligned: {
      display: 'flex',
      padding: 0,
      margin: 0,
    },
  })
)

const MaterialPricingDisplay: React.FC<MaterialPricingDisplayProps> = ({
  material,
  listPriceClass,
  priceClass,
  hasStackedPrices = true,
}) => {
  const classes = useStyles()
  const [openInquireModal, setOpenInquireModal] = useState(false)
  const { formatMessage } = useIntl()
  const { guestShoppingType, isDTAQZuCustomer } = useCurrentUser()

  let displayInquireLink
  let contactInfo
  if (material.availabilities?.length) {
    const availabilities = material.availabilities[0]
    displayInquireLink = availabilities.displayInquireLink
    contactInfo = availabilities.contactInfo
  }

  const inquirePriceContactInfo = [
    contactInfo?.contactPhone,
    contactInfo?.contactEmail,
  ]
    .filter((contactValue) => !!contactValue)
    .join(` ${formatMessage(messages.OR)} `)

  if (material.hidePriceMessageKey) {
    return <HidePriceMessage msgKey={material.hidePriceMessageKey} />
  }
  return displayInquireLink ? (
    <>
      <Button
        onClick={() => {
          setOpenInquireModal(true)
          sendPricingAvailabilityInteractionEvent({
            action: 'inquire product',
            section: 'p&a',
            component: 'list',
            elementType: 'link',
            elementText: 'inquire',
            material: {
              materialDescription: material.materialDescription,
              brand: material.brand,
              product: material.product,
              materialNumber: material.materialNumber,
            },
          })
        }}
        className={clsx(classes.baseClass, {
          [classes.topAligned]: displayInquireLink,
        })}
      >
        <FormattedMessage {...messages.INQUIRE} />
      </Button>
      {openInquireModal && (
        <Modal
          open={openInquireModal}
          setModalOpen={setOpenInquireModal}
          headerContent={material.materialNumber}
          bodyContent={
            <Box mr={12} mb={8}>
              <FormattedMessage
                id="INQUIRE_PRICING_MODAL"
                defaultMessage="It is difficult to publish a price for the item selected due to market conditions and/or volume. Please contact us at the following phone number or email address for further discussion. {inquirePriceContactInfo}"
                values={{
                  inquirePriceContactInfo,
                }}
              />
            </Box>
          }
        />
      )}
    </>
  ) : (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <div
        className={clsx(classes.pricesContainer, {
          [classes.pricesStacked]: hasStackedPrices,
        })}
      >
        {/* List Price */}
        {!!material.price &&
          !!material.listPrice &&
          (material.listPrice > material.price ||
            (guestShoppingType === 'dtaq' && isDTAQZuCustomer)) && (
            <div className={listPriceClass}>
              <FormattedNumber
                style="currency"
                currencyDisplay="symbol"
                currency={
                  material.listPriceCurrency
                    ? material.listPriceCurrency
                    : material.currency
                }
                value={material.listPrice}
              />
            </div>
          )}
        {/* Price */}
        {!!material.price ? (
          <div className={priceClass}>
            <FormattedNumber
              style="currency"
              currencyDisplay="symbol"
              currency={material.currency}
              value={material.price}
            />
          </div>
        ) : (
          <FormattedMessage {...messages.UNAVAILABLE} />
        )}
      </div>
    </Box>
  )
}

export default MaterialPricingDisplay
