import { ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { useCookies } from 'react-cookie'
import { Divider, Tab, Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import vrStyles from '@src/styles/utils/vrStyles'

const { vr6 } = vrStyles

const useStyles = makeStyles((theme) => ({
  tablist: {
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.text.primary,
      height: '4px',
    },
  },
  tab: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightMedium,
    padding: theme.spacing(2.5, 4),
    minWidth: 'auto',
    opacity: 1,
    textTransform: 'capitalize',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
    '&.Mui-selected': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.pxToRem(18),
      fontWeight: theme.typography.fontWeightBold,
      padding: theme.spacing(4),
    },
  },
  vr6,
}))

interface QuickOrderTabsProps {
  tabValue: number
  setTabValue: React.Dispatch<number>
  manualEntryForm: JSX.Element
  bulkUploadForm: JSX.Element
  bulkUploadTabLabel?: JSX.Element
  onTabClick?: (tab: string, gaCookie: string | undefined) => void
}

const QuickOrderTabs = ({
  tabValue,
  setTabValue,
  manualEntryForm,
  bulkUploadForm,
  bulkUploadTabLabel,
  onTabClick,
}: QuickOrderTabsProps) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [cookies] = useCookies(['_ga'])

  const handleTabChange = (e: ChangeEvent<{}>, value: number) => {
    setTabValue(value)
  }

  return (
    <>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={classes.tablist}
      >
        <Tab
          className={classes.tab}
          label={formatMessage({
            id: 'MANUAL_ENTRY',
            defaultMessage: 'Manual Entry',
          })}
          onClick={() => {
            if (onTabClick) onTabClick('manual entry', cookies._ga)
          }}
        />
        <Tab
          className={classes.tab}
          textColor="primary"
          label={
            bulkUploadTabLabel
              ? bulkUploadTabLabel
              : formatMessage({
                  id: 'BULK_UPLOAD',
                  defaultMessage: 'Bulk Upload',
                })
          }
          onClick={() => {
            if (onTabClick) onTabClick('bulk upload', cookies._ga)
          }}
        />
      </Tabs>

      <Divider className={classes.vr6} />

      {tabValue === 0 && manualEntryForm}

      {tabValue === 1 && bulkUploadForm}
    </>
  )
}

export default QuickOrderTabs
